import ModalWindow from "../common/ModalWindow";
import {useRef, useState} from "react";
import SpinLoadingIndicator from "../common/SpinLoadingIndicator";
import {UiUtils} from "../common/Utils";
import {backend} from "../model/Backend";
import BasicTextInput from "../common/TextInput";
import {BasicButton} from "../common/Button";
import {UIColor} from "../Config";

class NewRebuildState {
    static NoFiles = 0;
    static FilesSelected = 1;
    static Uploading = 2;
}

export default function NewRebuildModal({onClose}) {
    const [name, setName] = useState("");
    const [state, setState] = useState(NewRebuildState.NoFiles);
    const [errorMessage, setErrorMessage] = useState(null);
    const filesInput = useRef(null);

    async function handleClose() {
        if (state === NewRebuildState.FilesSelected &&
            !await UiUtils.confirmAsync("Rebuild was not started, do you want to discard it?")) {
            return;
        }
        if (state === NewRebuildState.Uploading &&
            !await UiUtils.confirmAsync("Photos were not yet uploaded, do you want to exit?")) {
            return;
        }
        onClose(false);
    }

    function onFilesChange(event) {
        setErrorMessage(null);
        if (state === NewRebuildState.Uploading)
            return;
        if (event.target.files.length === 0) {
            setState(NewRebuildState.NoFiles);
        } else {
            setState(NewRebuildState.FilesSelected);
        }
    }

    async function handleStartRebuild() {
        if (state === NewRebuildState.Uploading)
            return;
        if (name.trim() === "") {
            setErrorMessage("Please enter a name for the rebuild.");
            return;
        }
        const files = filesInput.current.files;
        setState(NewRebuildState.Uploading);

        setErrorMessage(null);
        const error = await backend.uploadNewRebuild(name, files);

        if (error && "error" in error) {
            setState(NewRebuildState.FilesSelected);
            setErrorMessage(error["error"]);
        } else {
            setErrorMessage(null);
            setState(NewRebuildState.NoFiles);
            onClose(true);
        }
    }

    return (
        <ModalWindow handleClose={handleClose}>
            <h1 className="text-4xl">New Rebuild</h1>
            <div className="mb-8"></div>
            <BasicTextInput
                name="name"
                disabled={state === NewRebuildState.Uploading}
                value={name}
                onChange={event => {
                    setName(event.target.value);
                    setErrorMessage(null);
                }}
                label={"Rebuild name"}
            />
            <div className="mb-5"></div>

            {/*const classes = `${className} w-full py-2 px-4 bg-gray-700 rounded text-gray-200 border border-gray-600 text-lg`;*/}
            <input
                type="file"
                multiple
                ref={filesInput}
                onChange={onFilesChange}
                disabled={state === NewRebuildState.Uploading}
                className="rounded bg-blue-600 py-1 px-3 cursor-pointer
                 file:text-white file:py-1.5 file:px-4 file:rounded file:bg-gray-600
                 hover:file:bg-gray-700 file:border-0 file:cursor-pointer"
            />

            <div className="mb-5"></div>
            {errorMessage !== null &&
                <div className="text-red-400">{errorMessage}</div>
            }
            <div className="mb-5"></div>

            <div className="flex justify-end space-x-4">
                <BasicButton onClick={handleClose}>Close</BasicButton>
                <BasicButton
                    onClick={handleStartRebuild}
                    disabled={state !== NewRebuildState.FilesSelected || name.trim() === ""}
                    buttonColor={UIColor.Blue}>
                    {state === NewRebuildState.Uploading ? <SpinLoadingIndicator/> : "Start Rebuild"}
                </BasicButton>
            </div>
        </ModalWindow>
    );
}