import {forwardRef} from "react";
import BasicInputLabel from "./InputLabel";
import {ClickableUIStyle, UIColor} from "../Config";

const BasicTextInput = forwardRef(function BasicTextInput(
    {
        className = "",
        name,
        value,
        onChange,
        onKeyDown,
        label,
        textArea = false,
        disabled,
        defaultValue,
        onBlur,
        type,
        autoComplete,
        required = false,
        placeholder,
    },
    ref
) {
    const classes = `${className} w-full py-1.5 px-4 rounded ${ClickableUIStyle[UIColor.LightInput]} text-lg`;

    // default placeholders
    if (type === "password")
        placeholder = "••••••••";

    if (type === "email")
        placeholder="your@email.com";

    let inputElement;
    if (textArea) {
        inputElement =
            <textarea
                name={name}
                value={value}
                onChange={onChange}
                className={classes}
                rows={4}
                disabled={disabled}
                defaultValue={defaultValue}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                autoComplete={autoComplete}
                required={required}
                ref={ref}
                placeholder={placeholder}
            />;
    } else {
        inputElement =
            <input
                type={type ?? "text"}
                name={name}
                value={value}
                onChange={onChange}
                className={classes}
                disabled={disabled}
                defaultValue={defaultValue}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                autoComplete={autoComplete}
                required={required}
                ref={ref}
                placeholder={placeholder}
            />;
    }
    if (label) {
        return (<>
                <BasicInputLabel label={label}/>
                <div>
                    {inputElement}
                </div>
            </>
        )
    }
    return inputElement;
});

export default BasicTextInput;