import {Wall} from "./Wall.js";
import {User} from "./User";
import RebuildProposal from "./RebuildProposal";
import {CookieUtils, FilesUtils, ImageUtils, NumberUtils, SpecialStates, StringUtils} from "../common/Utils";

import * as THREE from "three";
import {GLTFLoader} from "three/addons/loaders/GLTFLoader.js";
import {DRACOLoader} from "three/addons";
import JSZip from "jszip";
import {Leaderboard} from "./Leaderboard";

class BackendResult {
    constructor(result, errors) {
        this.data = result;
        this.errors = errors;
    }

    static FromError(error) {
        return BackendResult.FromErrors([error]);
    }

    static FromErrors(errors) {
        return new BackendResult(null, errors);
    }

    static FromSuccess(result) {
        return new BackendResult(result, null);
    }

    static EmptySuccess() {
        return new BackendResult({}, null);
    }

    isSuccess() {
        return this.errors === null || this.errors.length === 0;
    }
}

class BackendErrorType {
    static ConnectionError = "connection_error";
    static GeneralError = "general_error";
}

class Backend {
    constructor(useLocalFiles = false) {
        const devEnv = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

        this.baseUrl = window.location.origin + "/api";

        if (devEnv) {
            // default development to local
            this.baseUrl = "http://localhost:5130";

            if (process.env.REACT_APP_USE_PROD_SERVER) {
                console.warn("Developing with production backend!")
                this.baseUrl = "https://climbuddy.com/api";
            } else if (process.env.REACT_APP_USE_TEST_SERVER) {
                this.baseUrl = "https://test.climbuddy.com/api";
            }
        }

        this.useLocalFiles = useLocalFiles;
        this.valid = true;
        if (!useLocalFiles) {
            const urlParams = new URLSearchParams(window.location.search);
            let wallId;
            if (!urlParams.has("id")) {
                // TODO
                // this.valid = false;
                // console.error("Wall ID not found.")
                // return;

                wallId = "98943d2e-325c-43d3-b3a6-e905519e9be0";
            } else {
                wallId = urlParams.get("id");
            }
            // if (wallId === "cf5b1184-a649-4f8c-96ce-a18147a0272f") {
            //     wallId = "75121f56-67ce-4290-aa37-37aefd1bcc90";
            // }
            if (!StringUtils.isValidUuid(wallId)) {
                this.valid = false;
                console.error("Invalid wall ID.")
                return;
            }
            this.wallId = wallId;
            console.log("Wall ID: " + this.wallId);
        }
    }

    async loadWallMetadata() {
        let wallMetadataPromise;
        if (this.useLocalFiles) {
            wallMetadataPromise = this._loadJson("data/wall.json");
        } else {
            wallMetadataPromise = this._loadJson(`${this.baseUrl}/wall/get_wall_metadata/${this.wallId}`);
        }
        return await wallMetadataPromise;
    }

    async loadWall(onProgress) {
        const progressWeights = [70, 5, 10, 10, 5];
        const partialProgress = [0, 0, 0, 0, 0];
        const progressMottos = [
            "Warming up...",
            "Spraying beta...",
            "Looking for kneebars...",
            "Campusing the V2...",
        ];

        onProgress(0.0);

        function onPartialProgress(objectIdx, progress) {
            partialProgress[objectIdx] = progress;
            const totalProgress = NumberUtils.weightedAverage(partialProgress, progressWeights);

            const mottoIdx = Math.trunc(totalProgress * progressMottos.length);

            onProgress(totalProgress, progressMottos[mottoIdx]);
        }

        const holdsPromise = this._loadHoldsMeshGLTF(
            `${this.baseUrl}/wall/get_holds_mesh/${this.wallId}`,
            p => onPartialProgress(0, p),
        );
        const wallPromise = this._loadWallMeshGLTF(
            `${this.baseUrl}/wall/get_wall_mesh/${this.wallId}`,
            p => onPartialProgress(1, p),
        );
        const additionalMeshPromise = this._loadAdditionalMeshGLTF(
            `${this.baseUrl}/wall/get_additional_mesh/${this.wallId}`,
            p => onPartialProgress(2, p),
        );
        const routesPromise = this._loadJson(`${this.baseUrl}/wall/get_routes/${this.wallId}`);
        const metadataPromise = this._loadJson(`${this.baseUrl}/wall/get_wall_metadata/${this.wallId}`);
        const holdsMetadataPromise = this._loadJson(`${this.baseUrl}/wall/get_holds/${this.wallId}`);

        const [holdsMesh, wallMesh, additionalMesh, routes, metadata, holdsMetadata] =
            await Promise.all([holdsPromise, wallPromise, additionalMeshPromise, routesPromise, metadataPromise, holdsMetadataPromise]);
        onPartialProgress(3, 1.0);
        onPartialProgress(4, 1.0);

        const wall = Wall.fromServerData(wallMesh, additionalMesh, holdsMesh, routes, metadata, holdsMetadata);
        onProgress(1.0);
        return wall;
    }

    // TODO: de-duplicate
    async _loadWallMeshGLTF(url, onProgress = null) {
        const wallObject = await this._loadGLTF(url, onProgress);
        console.assert(wallObject.scenes.length === 1);
        const wallGroup = wallObject.scene;
        console.assert(wallGroup.children.length === 1);
        let wall = wallGroup.children[0];
        wall.traverse(node => {
            if (node.material) {
                node.material.side = THREE.DoubleSide;
                node.material.metalness = 0;
            }
        });
        return wall;
    }

    async _loadAdditionalMeshGLTF(url, onProgress = null) {
        const wallObject = await this._loadGLTF(url, onProgress);
        console.assert(wallObject.scenes.length === 1);
        const wallGroup = wallObject.scene;
        console.assert(wallGroup.children.length === 1);
        let wall = wallGroup.children[0];
        wall.traverse(node => {
            if (node.material) {
                node.material.metalness = 0;
            }
        });
        return wall;
    }

    async _loadHoldsMeshGLTF(url, onProgress = null) {
        const holdsObject = await this._loadGLTF(url, onProgress);
        console.assert(holdsObject.scenes.length === 1);
        const holdsGroup = holdsObject.scene;
        holdsGroup.traverse(node => {
            if (node.material) {
                node.material.metalness = 0;
            }
        })
        return holdsGroup;
    }

    _loadGLTF(modelUrl, onProgress = null) {
        return new Promise(async (resolve, reject) => {
            const loader = new GLTFLoader();

            // TODO: check that this makes sense
            const draco = new DRACOLoader();
            draco.setDecoderConfig({type: 'js'});
            draco.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
            loader.setDRACOLoader(draco);

            console.log(`Loading mesh from "${modelUrl}".`);
            loader.load(
                modelUrl,
                mesh => {
                    resolve(mesh);
                },
                xhr => {
                    if (onProgress === null)
                        return;
                    if (xhr.lengthComputable) {
                        onProgress(xhr.loaded / xhr.total);
                    }
                },
                error => {
                    console.error("Error loading the model: " + error);
                    reject(error);
                });
        });
    }

    _loadJson(url) {
        return fetch(url)
            .then(async res => {
                if (!res.ok)
                    throw new Error(await res.text())
                return await res.json();
            })
            .catch(error => {
                throw error;
            });
    }

    async login(loginData) {
        let response;
        try {
            response = await fetch(`${this.baseUrl}/account/login/${this.wallId}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(loginData),
            });
        } catch (error) {
            console.error(error);
            return BackendResult.FromError(BackendErrorType.ConnectionError);
        }
        if (response.status === 401) {
            return BackendResult.FromError("Incorrect email or password, please try again.");
        }
        if (!response.ok) {
            const error = await response.text();
            console.error(error);
            return BackendResult.FromError("Couldn't log in, please try again later.");
        }

        return BackendResult.FromSuccess(await User.fromMetadata(await response.json()));
    }

    async leaderboard() {
        let leaderboardData = await this._loadJson(`${this.baseUrl}/wall/get_leaderboard/${this.wallId}`);

        const leaderboard = Leaderboard.fromList(leaderboardData.entries);

        // Process profile images
        const imagePromises = leaderboard.entries.map(entry => {
            if (entry.photo) {
                try {
                    return ImageUtils.base64ToBlob(entry.photo, 'image/webp');
                } catch (error) {
                    console.error(`Error processing image for ${entry.nickname}:`, error);
                    return ImageUtils.defaultImage();
                }
            } else {
                return ImageUtils.defaultImage();
            }
        });

        let photos;
        try {
            photos = await Promise.all(imagePromises);
        } catch (error) {
            console.error("Error processing profile images:", error);
            return BackendResult.FromError("Error processing profile images.");
        }

        // Add images to leaderboard (as blobs, not just base64 stuff)
        leaderboard.addPhotos(photos);

        return BackendResult.FromSuccess(leaderboard);
    }

    async updatePassword(passwordData) {
        let response;
        try {
            response = await fetch(`${this.baseUrl}/account/update_password`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passwordData),
            });
        } catch (error) {
            console.error(error);
            return BackendResult.FromError(BackendErrorType.ConnectionError);
        }

        if (!response.ok) {
            let errors;
            try {
                errors = await response.json();
            } catch (error) {
                console.log(error);
                return BackendResult.FromError("Couldn't update password, please try again later.");
            }
            console.warn(errors);
            return BackendResult.FromErrors(errors);
        }

        return BackendResult.EmptySuccess();
    }

    async deleteAccount(data) {
        let response;
        try {
            response = await fetch(`${this.baseUrl}/account/delete_account`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });
        } catch (error) {
            console.error(error);
            return BackendResult.FromError(BackendErrorType.ConnectionError);
        }

        if (!response.ok) {
            let errors;
            try {
                errors = await response.json();
            } catch (error) {
                console.log(error);
                return BackendResult.FromError("Couldn't delete account, please try again later.");
            }
            console.warn(errors);
            return BackendResult.FromErrors(errors);
        }

        CookieUtils.deleteCookie("session_signature");
        return BackendResult.EmptySuccess();
    }

    async register(registerData) {
        if (registerData.activities) {
            registerData.activities = registerData.activities.map(activity => ({
                routeId: activity.route.id,
                type: activity.type,
                timestampUtc: activity.timestamp.getTime(),
            }));
        }

        let response;
        try {
            if (registerData.photo instanceof Blob) {
                registerData.photo = await ImageUtils.blobToBase64(registerData.photo);
            }

            response = await fetch(`${this.baseUrl}/account/register/${this.wallId}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(registerData),
            });
        } catch (error) {
            console.error(error);
            return BackendResult.FromError(BackendErrorType.ConnectionError);
        }

        if (!response.ok) {
            let errors;
            try {
                errors = await response.json();
            } catch (error) {
                console.log(error);
                return BackendResult.FromError("Couldn't register, please try again later.");
            }
            console.warn(errors);
            return BackendResult.FromErrors(errors);
        }

        return BackendResult.FromSuccess(await User.fromMetadata(await response.json()));
    }

    async checkIfLoggedIn() {
        let response;
        try {
            response = await fetch(`${this.baseUrl}/account/get_climber_info/${this.wallId}`);
        } catch {
            // Do nothing.
            return BackendResult.FromSuccess(null);
        }
        if (response.ok) {
            return BackendResult.FromSuccess(await User.fromMetadata(await response.json()));
        }
        return BackendResult.FromSuccess(null);
    }

    logout() {
        fetch(`${this.baseUrl}/account/logout`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        }).then(response => {
            if (!response.ok) {
                response.text().then(e => console.error(e));
            }
        }).catch(e => {
            console.error(e);
        })
        CookieUtils.deleteCookie("session_signature");
    }

    async addUserActivities(activities) {
        const activityData = activities.map(activity => ({
            routeId: activity.route.id,
            type: activity.type,
            timestampUtc: activity.timestamp.getTime(),
        }));

        return await this._postJsonRequest("climber/add_activity", activityData);
    }

    async modifyUserActivities(activities) {
        const activityData = activities.map(activity => ({
            id: activity.id,
            routeId: activity.route.id,
            type: activity.type,
            timestampUtc: activity.timestamp.getTime(),
        }));

        return await this._postJsonRequest("climber/modify_activity", activityData);
    }

    async removeUserActivities(activities) {
        const activityData = activities.map(activity => ({id: activity.id}));

        return await this._postJsonRequest("climber/remove_activity", activityData);
    }

    async saveUserMetadata(newMetadata) {
        return await this._postJsonRequest("climber/set_metadata", {
            nickname: newMetadata.nickname,
            photo: await ImageUtils.blobToBase64(newMetadata.photo),
        })
    }

    async submitFeedback(feedbackText) {
        return await this._postJsonRequest("feedback/send", {text: feedbackText});
    }

    async forgotPassword(email) {
        return await this._postJsonRequest("account/forgot_password", {email: email});
    }

    async resendVerificationEmail() {
        return await this._postJsonRequest("account/resend_verification_email");
    }

    async verifyEmail(token, userId) {
        return await this._postJsonRequest("account/verify_email", {token: token, userId: userId});
    }

    async resetPassword(token, userId, password) {
        return await this._postJsonRequest("account/reset_password", {token: token, userId: userId, password: password});
    }

    async _postJsonRequest(relativeUrl, data = null) {
        let response;
        try {
            const url = `${this.baseUrl}/${relativeUrl}`;
            if (data == null) {
                response = await fetch(url, {method: "POST"});
            } else {
                response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                });
            }
        } catch (error) {
            console.error(error);
            return BackendResult.FromError(BackendErrorType.ConnectionError);
        }
        if (!response.ok) {
            console.error(await response.text());
            return BackendResult.FromError(BackendErrorType.GeneralError);
        }

        // TODO: this is a bit shit -- some requests return JSONs and some don't
        try {
            return BackendResult.FromSuccess(await response.json());
        } catch (error) {
            return BackendResult.EmptySuccess();
        }
    }

    async saveWallMetadata(newMetadata) {
        const body = JSON.stringify(newMetadata);
        let response;
        try {
            response = await fetch(
                `${this.baseUrl}/wall_owner/set_wall_metadata/${this.wallId}`,
                {
                    method: "POST",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                    },
                    body: body,
                }
            );
        } catch (error) {
            console.error(error);
            return BackendResult.FromError(BackendErrorType.ConnectionError)
        }
        if (!response.ok) {
            const error = await response.text();
            console.error(error);
            return BackendResult.FromError("Changes couldn't be saved, please try again later.")
        }
        return BackendResult.EmptySuccess();
    }

    async getPendingProposal() {
        let proposalMetadata, wallPromise, additionalMeshPromise, holdsPromise, routesPromise;
        if (this.useLocalFiles) {
            wallPromise = this._loadHoldsMeshGLTF("data/proposal/wall.glb");
            additionalMeshPromise = this._loadHoldsMeshGLTF("data/proposal/additional.glb");
            holdsPromise = this._loadHoldsMeshGLTF("data/proposal/holds.glb");
            routesPromise = this._loadJson("data/proposal/routes.json");
            proposalMetadata = {
                id: 42,
                name: "Test Proposal",
                readyForRevision: true,
            };
        } else {
            let proposalRequest;
            try {
                proposalRequest = await fetch(`${this.baseUrl}/wall_owner/proposal/get/${this.wallId}`);
            } catch (error) {
                console.error(error);
                return BackendResult.FromError(BackendErrorType.ConnectionError);
            }
            if (!proposalRequest.ok) {
                console.error(await proposalRequest.text());
                return BackendResult.FromError("Couldn't load rebuild from server.");
            }
            proposalMetadata = await proposalRequest.json();
            if (!proposalMetadata.exists) {
                return BackendResult.FromSuccess(SpecialStates.Empty);
            }
            if (!proposalMetadata.readyForRevision)
                return BackendResult.FromSuccess(RebuildProposal.NotReady(proposalMetadata));
        }
        wallPromise = this._loadWallMeshGLTF(
            `${this.baseUrl}/wall_owner/proposal/get_wall_mesh/${this.wallId}/${proposalMetadata.id}`
        );
        additionalMeshPromise = this._loadAdditionalMeshGLTF(
            `${this.baseUrl}/wall_owner/proposal/get_additional_mesh/${this.wallId}/${proposalMetadata.id}`
        );
        holdsPromise = this._loadHoldsMeshGLTF(
            `${this.baseUrl}/wall_owner/proposal/get_holds_mesh/${this.wallId}/${proposalMetadata.id}`
        );
        routesPromise = this._loadJson(
            `${this.baseUrl}/wall_owner/proposal/get_routes/${this.wallId}/${proposalMetadata.id}`
        );
        // TODO: holds metadata?

        const proposalWall = Wall.fromServerData(await wallPromise, await additionalMeshPromise, await holdsPromise, await routesPromise, null);
        return BackendResult.FromSuccess(RebuildProposal.fromWall(proposalMetadata, proposalWall));
    }

    async uploadNewRebuild(rebuildName, files, onProgress = null) {
        const zip = new JSZip();
        for (let i = 0; i < files.length; i++) {
            const filename = `${i}` + FilesUtils.getExtension(files[i].name);
            zip.file(filename, files[i]);
        }

        let filesDone = 0;
        let lastFile = null;
        const blob = await zip.generateAsync({
            type: "blob",
            compression: "DEFLATE",
            compressionOptions: {
                level: 9,
            },
        }, (updateMetadata) => {
            if (updateMetadata.currentFile === null)
                return;
            if (lastFile !== null && updateMetadata.currentFile !== lastFile) {
                filesDone++;
            }
            const progress = (filesDone + updateMetadata.percent) / files.length;
            onProgress?.(progress);
            lastFile = updateMetadata.currentFile;
        });
        console.log("Uploading blob of size " + blob.size);
        const formData = new FormData();
        formData.append("rebuildName", rebuildName);
        formData.append("photosArchive", blob, "photos.zip");
        let response;
        try {
            response = await fetch(
                `${this.baseUrl}/wall_owner/new_rebuild/${this.wallId}`,
                {
                    method: "POST",
                    body: formData,
                });
        } catch (error) {
            console.error(error);
            return {error: "Couldn't connect to server."};
        }
        if (!response.ok) {
            const error = await response.text();
            console.error(error);
            if (response.status === 413) {
                return {error: "Current upload size limit is 250 MB. Please contact us if you need to increase it."};
            }
            return {error: "Changes couldn't be saved, please try again later."};
        }
        return null;
    }

    async setRebuildRoutes(proposalId, routes) {
        return await this._postJsonRequest(
            `wall_owner/proposal/set_routes/${this.wallId}/${proposalId}`,
            routes.map(route => route.getRouteMetadata()),
        );
    }

    async publishProposal(proposalId) {
        return await this._postJsonRequest(
            `wall_owner/proposal/publish/${this.wallId}/${proposalId}`,
        );
    }
}

const backend = new Backend(false);

export {Backend, backend, BackendResult, BackendErrorType};