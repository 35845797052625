import ModalWindow from "../../common/ModalWindow";
import {useContext, useRef, useState} from "react";
import BasicTextInput from "../../common/TextInput";
import {UserActions, UserContext, UserDispatchContext} from "../../contexts/UserContext";
import {BasicButton} from "../../common/Button";
import {UIColor} from "../../Config";
import SpinLoadingIndicator from "../../common/SpinLoadingIndicator";
import ErrorFromBackendResult from "../../common/ErrorFromBackendResult";

export default function ForgotPasswordModal({onClose, ...props}) {
    const user = useContext(UserContext);
    const userDispatch = useContext(UserDispatchContext);

    const userEmail = useRef(null);

    const [modified, setModified] = useState(false);
    const [sent, setSent] = useState(false);

    async function onSubmit(submitEvent) {
        submitEvent.preventDefault();

        userDispatch({
            type: UserActions.ForgotPassword,
            email: userEmail.current.value,
            postSuccessHooks: () => {
                setSent(true);
                setModified(false);
                userEmail.current.value = "";
            }
        });
    }

    return <ModalWindow
        title={sent ? "Sent!" : "Forgot Password"}
        handleClose={onClose}
        {...props}
    >
        <form onSubmit={onSubmit}>
            {
                !sent ? <>
                    <p>An email with a <strong>reset link</strong> will be sent to the provided email.
                        To reset the password, click on the link and follow the steps.</p>
                    <div className="mb-8"></div>
                    <BasicTextInput ref={userEmail} type="email" name="email" required={true} label="Email"
                                    autoComplete="username"
                                    onChange={() => setModified(true)}
                    />
                </> : <>
                    <p>
                        If an account with the email address exists,
                        <strong> we have sent a password reset link </strong>
                        to that email.
                    </p>
                    <div className="mb-4"></div>
                    <p>
                        Please check your inbox and follow the instructions to reset your password.
                    </p>
                </>
            }
            <div className="mb-8"></div>
            <BasicButton
                type="submit"
                buttonColor={UIColor.Blue}
                className="w-full"
                disabled={!modified}
            >
                {user.status.isLoading(UserActions.ForgotPassword) ?
                    <SpinLoadingIndicator/> : <>Submit</>}
            </BasicButton>
            <ErrorFromBackendResult data={user.status.getError(UserActions.ForgotPassword)}/>
        </form>
    </ModalWindow>;
}
