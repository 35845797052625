import ModalWindow from "../../common/ModalWindow";
import {useContext, useEffect, useRef, useState} from "react";
import BasicTextInput from "../../common/TextInput";
import {UserActions, UserContext, UserDispatchContext} from "../../contexts/UserContext";
import {backend, BackendResult} from "../../model/Backend";
import {BasicButton} from "../../common/Button";
import {UIColor} from "../../Config";
import SpinLoadingIndicator from "../../common/SpinLoadingIndicator";
import ErrorFromBackendResult from "../../common/ErrorFromBackendResult";
import {UiUtils} from "../../common/Utils";

export default function ChangePasswordModal({onClose, show}) {
    const oldUserPassword = useRef(null);
    const userPassword = useRef(null);
    const userPasswordRepeat = useRef(null);

    const userDispatch = useContext(UserDispatchContext);

    const [errorData, setErrorData] = useState(null);
    const [modified, setModified] = useState(false);

    const [updateText, setUpdateText] = useState("Change Password");

    const user = useContext(UserContext);

    function changePasswords() {
        setUpdateText("Change Password");

        if (oldUserPassword.current.value || userPassword.current.value || userPasswordRepeat.current.value)
            setModified(true);
        else
            setModified(false);
    }

    async function onCloseWrapper() {
        if (modified && !await UiUtils.confirmAsync("Discard unsaved changes?"))
            return;

        onClose();
    }

    useEffect(() => {
        setErrorData(user.status.getError(UserActions.UpdatePassword))
    }, [user.status]);

    async function onSubmit(submitEvent) {
        submitEvent.preventDefault();

        if (userPassword.current.value !== userPasswordRepeat.current.value) {
            setErrorData(BackendResult.FromError("Passwords don't match."));
            return;
        }

        const updatePasswordData = {
            oldPassword: oldUserPassword.current.value,
            newPassword: userPassword.current.value,
        };

        setErrorData(null);

        userDispatch({
            type: UserActions.UpdatePassword,
            data: updatePasswordData,
            postSuccessHooks: () => {
                oldUserPassword.current.value = "";
                userPassword.current.value = "";
                userPasswordRepeat.current.value = "";

                setUpdateText("Changed!");
                setErrorData(null);
                setModified(false);
            }
        });
    }

    return <ModalWindow
        title="Change Password"
        handleClose={onCloseWrapper}
        show={show}
    >
        <form onSubmit={onSubmit}>
            <BasicTextInput ref={oldUserPassword} type="password" name="password" required={true} label="Old Password"
                            autoComplete="new-password"
                            onChange={changePasswords}
            />
            <div className="mb-8"></div>
            <BasicTextInput ref={userPassword} type="password" name="password" required={true} label="New Password"
                            autoComplete="new-password"
                            onChange={changePasswords}
            />
            <div className="mb-4"></div>
            <BasicTextInput ref={userPasswordRepeat} type="password" name="password_repeat" required={true}
                            label="Confirm Password" autoComplete="new-password"
                            onChange={changePasswords}
            />
            <div className="mb-8"></div>
            <BasicButton
                type="submit"
                buttonColor={UIColor.Blue}
                className="w-full"
                disabled={!modified}
            >
                {user.status.isLoading(UserActions.UpdatePassword) ?
                    <SpinLoadingIndicator/> : <>{updateText}</>}
            </BasicButton>
            <ErrorFromBackendResult data={errorData}/>
        </form>
    </ModalWindow>;
}
