import {createContext, useState} from "react";
import {unimplementedFunction} from "../common/Utils";

const NotificationContext = createContext(null);
const NotificationDispatchContext = createContext(unimplementedFunction);

class NotificationData {
    constructor(message, duration, startTimestamp = null) {
        this.message = message;
        this.duration = duration;
        this.startTimestamp = startTimestamp ?? new Date(Date.now());
    }

    getRemainingSeconds() {
        const now = new Date(Date.now());
        const durationMillis = this.duration * 1000;
        const elapsed = now - this.startTimestamp;
        if (elapsed >= durationMillis)
            return 0;
        return (durationMillis - elapsed) / 1000;
    }
}

class NotificationActions {
    static SHOW_NEW = "show_new";
}

function useNotificationReducer() {
    const [notification, setNotification] = useState(null);

    function notificationDispatch(action) {
        setNotification(n => _notificationReducer(n, action));
    }

    return [notification, notificationDispatch];
}

function _notificationReducer(notification, action) {
    switch (action.type) {
        case NotificationActions.SHOW_NEW:
            return new NotificationData(action.message, action.duration);
        default:
            throw Error("Unknown action: " + action.type);
    }
}

export {NotificationContext, NotificationDispatchContext, NotificationData, NotificationActions, useNotificationReducer};