// At least we have the guts to call it what it is.
class MutableGlobalState {
    isCanvasDragging = false;
}

class AppContext {
    static Mutable = new MutableGlobalState();
}
AppContext.Mutable.writable = false;

export {AppContext};