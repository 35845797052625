import * as THREE from "three";
import {EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import {OutlinePass} from "three/examples/jsm/postprocessing/OutlinePass";
import {RenderPass} from "three/examples/jsm/postprocessing/RenderPass";
import {ShaderPass} from "three/examples/jsm/postprocessing/ShaderPass";
import {FXAAShader} from "three/examples/jsm/shaders/FXAAShader";
import {GammaCorrectionShader} from "three/examples/jsm/shaders/GammaCorrectionShader.js";
import CameraAnimation from "../controls/CameraAnimation";
import * as config from "../../Config"
import OutlineType from "./OutlineType";

export default class THREECanvas {
    constructor() {
        this.debug = true;
        this.clock = new THREE.Clock();
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(config.FOV, window.innerWidth / window.innerHeight, 0.01, 1000);
        this.cameraAnimation = new CameraAnimation(this);
        this.sinceLastRender = 0;

        this._wall = null;
        this._outlinePasses = new Map();

        this._setup();
    }

    _setup() {
        this._setupRendering();
        this._setupBackground();
        this._setupLights();
        window.addEventListener("resize", this._onWindowResize.bind(this));
        this.render();
    }

    setWall(wall) {
        if (this._wall === wall)
            return;
        if (this._wall !== null) {
            this.scene.remove(this._wall.wallMesh);
            this.scene.remove(this._wall.additionalMesh);
            this.scene.remove(this._wall.holdsMesh);
        }
        this._wall = wall;
        if (wall !== null) {
            this.scene.add(wall.wallMesh);
            this.scene.add(wall.additionalMesh);
            this.scene.add(wall.holdsMesh);
        }

        this.composer.render();
    }

    getCanvasElement() {
        return this.renderer.domElement;
    }

    _setupRendering(useFXAA = false) {
        const htmlCanvas = document.querySelector("#model-canvas")
        console.assert(htmlCanvas !== null);
        this.renderer = new THREE.WebGLRenderer({
            canvas: htmlCanvas,
            antialias: true,
        });

        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(window.innerWidth, window.innerHeight);

        this.composer = new EffectComposer(this.renderer);

        this.renderPass = new RenderPass(this.scene, this.camera);
        this.composer.addPass(this.renderPass);

        const gammaCorrection = new ShaderPass(GammaCorrectionShader);
        this.composer.addPass(gammaCorrection);

        this.shaderPass = null;
        if (useFXAA) {
            this.shaderPass = new ShaderPass(FXAAShader);
            this.shaderPass.uniforms["resolution"].value.set(1 / window.innerWidth, 1 / window.innerHeight);
            this.shaderPass.renderToScreen = true;
            this.composer.addPass(this.shaderPass);
        }
    }

    _setupBackground() {
        const uniforms = {
            "topColor": {value: config.TOP_COLOR},
            "bottomColor": {value: config.BOTTOM_COLOR},
            "offset": {value: config.SPHERE_OFFSET},
            "exponent": {value: config.SPHERE_EXPONENT}
        };

        const skyGeo = new THREE.SphereGeometry(config.SPHERE_RADIUS, config.SPHERE_RESOLUTION, config.SPHERE_RESOLUTION);
        const skyMat = new THREE.ShaderMaterial({
            uniforms: uniforms,
            vertexShader: `
                varying vec3 vWorldPosition;

                void main() {
                    vec4 worldPosition = modelMatrix * vec4(position, 1.0);
                    vWorldPosition = worldPosition.xyz;
                    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                }`,
            fragmentShader: `
                uniform vec3 topColor;
                uniform vec3 bottomColor;
                uniform float offset;
                uniform float exponent;

                varying vec3 vWorldPosition;

                void main() {
                    float h = normalize(vWorldPosition + offset).y;
                    gl_FragColor = vec4(mix(bottomColor, topColor, max(pow(max(h , 0.0), exponent), 0.0)), 1.0);
                }`,
            side: THREE.BackSide
        });

        const sky = new THREE.Mesh(skyGeo, skyMat);
        this.scene.add(sky);
    }

    _setupLights() {
        const ambientLight = new THREE.AmbientLight(config.LIGHT_COLOR, config.LIGHT_INTENSITY);
        this.scene.add(ambientLight);
    }


    _onWindowResize() {
        const width = window.innerWidth;
        const height = window.innerHeight;

        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();

        // Why was this commented? This solves the issue with the blur when changing resolution...
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(width, height);
        this.composer.setSize(width, height);

        const htmlCanvas = document.querySelector("#model-canvas")
        htmlCanvas.style.width = width + "px";
        htmlCanvas.style.height = height + "px";

        if (this.shaderPass) {
            this.shaderPass.uniforms["resolution"].value.set(
                1 / window.innerWidth,
                1 / window.innerHeight
            );
        }

        this.render();
    }

    setOutlinedObjects(typeId, objects) {
        console.assert(this.isOutlineEnabled(typeId));
        this._outlinePasses.get(typeId).selectedObjects = objects;
    }

    enableOutline(typeId, params) {
        if (this.isOutlineEnabled(typeId))
            return;

        const outlinePass = new OutlinePass(
            new THREE.Vector2(window.innerWidth, window.innerHeight),
            this.scene,
            this.camera,
        );

        if (params.blending) {
            outlinePass.overlayMaterial.blending = params.blending;
        }

        outlinePass.edgeStrength = params.edgeStrength;
        outlinePass.edgeThickness = params.edgeThickness;
        outlinePass.visibleEdgeColor = params.color;

        // if hidden edge not specified, don't blend
        outlinePass.hiddenEdgeColor = params.hiddenColor ?? new THREE.Color(0x000);

        outlinePass.edgeGlow = params.edgeGlow;

        let lastIndex = 1;
        if (typeId !== OutlineType.HIGHLIGHT) {
            for (let i = 1; i < this.composer.passes.length; i++) {
                if (this.composer.passes[i] instanceof OutlinePass) {
                    lastIndex = i + 1;
                }
            }
        }
        this.composer.insertPass(outlinePass, lastIndex);
        this._outlinePasses.set(typeId, outlinePass);
    }

    disableOutline(typeId) {
        if (!this.isOutlineEnabled(typeId))
            return;
        this.composer.removePass(this._outlinePasses.get(typeId))
        this._outlinePasses.delete(typeId);
    }

    isOutlineEnabled(typeId) {
        return this._outlinePasses.has(typeId);
    }

    render() {
        this.sinceLastRender += this.clock.getDelta();
        const interval = 1 / config.MAX_FPS;

        if (this.sinceLastRender > interval) {
            this.composer.render();
            this.sinceLastRender %= interval;
        }
    }
}