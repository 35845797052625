import ModalWindow from "../../common/ModalWindow";
import {useRef, useState} from "react";
import BasicTextInput from "../../common/TextInput";
import {UserActions, useUserReducer} from "../../contexts/UserContext";
import {BasicButton} from "../../common/Button";
import {UIColor} from "../../Config";
import SpinLoadingIndicator from "../../common/SpinLoadingIndicator";
import ErrorFromBackendResult from "../../common/ErrorFromBackendResult";
import {BackendResult} from "../../model/Backend";

export default function ResetPasswordModal() {
    const [user, userDispatch] = useUserReducer();

    const [modified, setModified] = useState(false);
    const [done, setDone] = useState(false);

    const userPassword = useRef(null);
    const userPasswordRepeat = useRef(null);

    const [errorData, setErrorData] = useState(null);

    const [updateText, setUpdateText] = useState("Reset Password");

    const urlParams = new URLSearchParams(window.location.search);

    let token = null;
    if (urlParams.has("token")) {
        token = urlParams.get("token");
    }

    let userId = null;
    if (urlParams.has("userId")) {
        userId = urlParams.get("userId");
    }

    function changePasswords() {
        setUpdateText("Change Password");

        if (userPassword.current.value || userPasswordRepeat.current.value)
            setModified(true);
        else
            setModified(false);
    }

    async function onSubmit(submitEvent) {
        submitEvent.preventDefault();

        if (userPassword.current.value !== userPasswordRepeat.current.value) {
            setErrorData(BackendResult.FromError("Passwords don't match."));
            return;
        }

        setErrorData(null);

        userDispatch({
            type: UserActions.ResetPassword,
            password: userPassword.current.value,
            userId: userId,
            token: token,
            postSuccessHooks: () => {
                setUpdateText("Password Reset!");
                setErrorData(null);
                setModified(false);
                setDone(true);

                userPassword.current.value = "";
                userPasswordRepeat.current.value = "";
            }
        });
    }

    let cow = `
 ______________
< Go climbing! >
 --------------
        \\   ^__^
         \\  (oo)\\_______
            (__)\\       )\\/\\
                ||----w |
                ||     ||
    `

    return <ModalWindow
        title={"Reset Password"}
    >
        <form onSubmit={onSubmit}>
            {(token === null || userId === null) ? <p>
                    <span className='font-bold text-xl'>No token / user ID provided!</span>
                    <div className="mb-4"></div>
                    <span>How did you even get here without one?</span>
                    <div className="flex items-center justify-center">
                <pre>
                    {cow}
                </pre>
                    </div>
                </p>
                : <>
                    <BasicTextInput ref={userPassword} type="password" name="password" required={true}
                                    label="New Password"
                                    autoComplete="new-password"
                                    onChange={changePasswords}
                                    disabled={done}
                    />
                    <div className="mb-4"></div>
                    <BasicTextInput ref={userPasswordRepeat} type="password" name="password_repeat" required={true}
                                    label="Confirm Password" autoComplete="new-password"
                                    onChange={changePasswords}
                                    disabled={done}
                    />
                    <div className="mb-8"></div>
                    <BasicButton
                        type="submit"
                        buttonColor={UIColor.Blue}
                        className="w-full"
                        disabled={!modified}
                    >
                        {user.status.isLoading(UserActions.ResetPassword) ?
                            <SpinLoadingIndicator/> : <>{updateText}</>}
                    </BasicButton>

                    <ErrorFromBackendResult data={errorData ?? user.status.getError(UserActions.ResetPassword) }/>
                </>
            }
        </form>
    </ModalWindow>;
}
