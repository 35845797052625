import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import Window from "./Window";
import {isValidElement} from "react";

export default function ModalWindow({children, topColor = "bg-neutral-600", className = "", handleClose = null, justify=false, title = "", show=true}) {
    /**
     * A wrapper for only closing when directly clicking on the element. Useful since clicking out of the modal closes it.
     */
    function onCloseWrapper(e) {
        if (e.target !== e.currentTarget)
            return;

        handleClose?.();
    }

    /**
     * The sticky top part of the modal window.
     */
    function topPart() {
        let button = handleClose !== null ? <button
            className="absolute top-5 right-3 cursor-pointer text-2xl rounded px-2 py-0 bg-transparent hover:bg-neutral-500 transition"
            onClick={handleClose}>
            <FontAwesomeIcon icon={faXmark}/>
        </button> : "";

        if (title !== "")
            return <div className={`sticky top-0 z-20 ${topColor} px-6 py-4`}>
                {isValidElement(title) ? title : <h1 className="text-3xl text-center">{title}</h1>}
                {button}
            </div>
        else
            return button

    }

    return (
        <div className={`transition-all duration-75 ${show ? "opacity-100 visible" : "opacity-0 invisible"} fixed top-0 left-0 w-full h-full z-50 flex justify-center items-center ${handleClose !== null ? 'bg-black/50' : 'bg-neutral-700/50'}`}
             onClick={onCloseWrapper}>
            <Window className={`${className} relative`} justify={justify}>
                {topPart()}
                <div className="p-6">
                    {children}
                </div>
            </Window>
        </div>
    );
}