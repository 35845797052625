import ModalWindow from "../../common/ModalWindow";
import BasicTextInput from "../../common/TextInput";
import {BasicButton} from "../../common/Button";
import {useContext, useRef, useState} from "react";
import {UserActions, UserContext, UserDispatchContext} from "../../contexts/UserContext";
import SpinLoadingIndicator from "../../common/SpinLoadingIndicator";
import {backend, BackendResult} from "../../model/Backend";
import ErrorFromBackendResult from "../../common/ErrorFromBackendResult";
import {ImageUtils, UiUtils} from "../../common/Utils";
import {LeaderboardActions, LeaderboardDispatchContext} from "../../contexts/LeaderboardContext";

export default function RegisterModal({onClose, text, ...props}) {
    const [errorData, setErrorData] = useState(null);
    const [registering, setRegistering] = useState(false);

    const user = useContext(UserContext);
    const userDispatch = useContext(UserDispatchContext);

    const leaderboardDispatch = useContext(LeaderboardDispatchContext);

    const [dirty, setDirty] = useState(false);

    const userEmail = useRef(null);
    const userPassword = useRef(null);
    const userPasswordRepeat = useRef(null);

    async function onCloseWrapper() {
        if (dirty && !await UiUtils.confirmAsync("Registering not finished, discard changes?"))
            return;

        onClose();
    }

    async function onSubmit(submitEvent) {
        submitEvent.preventDefault();

        if (registering)
            return; // this probably shouldn't happen

        if (userPassword.current.value !== userPasswordRepeat.current.value) {
            setErrorData(BackendResult.FromError("Passwords don't match."));
            return;
        }

        const registerData = {
            email: userEmail.current.value,
            password: userPassword.current.value,
            photo: await ImageUtils.getRandomHoldImage(),
        };

        // attach activities if we're creating from a temporary user
        if (user.value?.isTemporary)
            registerData.activities = user.value.activities;

        // TODO: this should be a dispatch
        setErrorData(null);
        setRegistering(true);
        const result = await backend.register(registerData);
        setRegistering(false);

        if (!result.isSuccess()) {
            setErrorData(result);
        } else {
            setErrorData(null);
            userDispatch({type: UserActions.Login, user: result.data});
            leaderboardDispatch({type: LeaderboardActions.Update});
            onClose();
        }
    }

    return (
        <ModalWindow handleClose={onCloseWrapper} title="Register" {...props}>
            <form onSubmit={onSubmit}>
                {
                    text ? <>
                        {text}
                        <div className="mb-6"></div>
                    </> : ""
                }

                <BasicTextInput ref={userEmail} type="email" name="email" required={true} label="Email"
                                autoComplete="username"
                                onChange={() => setDirty(true)}
                />
                <div className="mb-4"></div>
                <BasicTextInput ref={userPassword} type="password" name="password" required={true} label="Password"
                                autoComplete="new-password"
                                onChange={() => setDirty(true)}
                />
                <div className="mb-4"></div>
                <BasicTextInput ref={userPasswordRepeat} type="password" name="password_repeat" required={true}
                                label="Confirm Password" autoComplete="new-password"
                                onChange={() => setDirty(true)}
                />
                <div className="mb-8"></div>
                <BasicButton type="submit" disabled={registering} className="w-full">
                    {!registering ? "Register" : <SpinLoadingIndicator/>}
                </BasicButton>
                <ErrorFromBackendResult data={errorData}/>
            </form>
        </ModalWindow>
    )
}
