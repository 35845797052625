import ModalWindow from "../../common/ModalWindow";
import {BasicButton} from "../../common/Button";
import {useCallback, useContext, useRef, useState} from "react";
import BasicTextInput from "../../common/TextInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCamera,
    faDiceSix,
    faKey,
    faTimes,
    faTriangleExclamation,
    faUpload,
    faUserSlash
} from "@fortawesome/free-solid-svg-icons";
import {ClickableUIStyle, UIColor} from "../../Config";
import Webcam from "react-webcam";
import ErrorFromBackendResult, {ErrorText} from "../../common/ErrorFromBackendResult";
import ProfilePicture from "./ProfilePicture";
import {ImageUtils, UiUtils} from "../../common/Utils";
import {UserActions, UserDispatchContext} from "../../contexts/UserContext";
import SpinLoadingIndicator from "../../common/SpinLoadingIndicator";
import ChangePasswordModal from "./ChangePasswordModal";
import DeleteAccountModal from "./DeleteAccountModal";

export default function SettingsModal({user, onClose, show}) {
    const [selectedImage, setSelectedImage] = useState(user.value.photo);
    const [selectedImageURL, setSelectedImageURL] = useState(user.value.photoURL);
    const [webcam, setWebcam] = useState(false);
    const [webcamError, setWebcamError] = useState(null);
    const [error, setError] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [nickname, setNickname] = useState(user.value.nickname);

    const [changePassword, setChangePassword] = useState(false);
    const [deleteUser, setDeleteUser] = useState(false);

    const userDispatch = useContext(UserDispatchContext);

    const imageInputRef = useRef(null);
    const updateRef = useRef(null);

    const [updateText, setUpdateText] = useState("Update");
    const [modified, setModified] = useState(false);

    const [dangerZoneOpen, setDangerZoneOpen] = useState(false);

    async function onCloseWrapper() {
        if (modified && !await UiUtils.confirmAsync("Discard unsaved changes?"))
            return;

        onClose();
    }

    async function setProfileImage(file) {
        ImageUtils.resizeImage(file).then(
            (data) => {
                setError(null);
                setModified(true);
                setUpdateText("Update")
                setSelectedImage(data);
                setSelectedImageURL(URL.createObjectURL(data));
            }
        ).catch(error => {
            console.error(error);
            setError("An error occurred while loading the file, make sure it's an image!")
        });
    }

    const webcamRef = useRef(null);
    const capture = useCallback(
        () => setProfileImage(webcamRef.current.getScreenshot()),
        [webcamRef]
    );

    async function onSubmit(submitEvent) {
        submitEvent.preventDefault();

        setSubmitted(true)
        userDispatch({
            type: UserActions.SaveUserMetadata,
            metadata: {
                nickname: nickname,
                photo: selectedImage,
            },
            postSuccessHooks: () => {
                setSubmitted(false);
                setModified(false);
                setUpdateText("Updated!");
            }
        });
    }

    // TODO: webcam broken on FireFox mobile :)))
    return <>
        <ModalWindow
            title="Settings"
            handleClose={onCloseWrapper}
            show={show}
        >
            {webcam && <>
                <Webcam
                    className="rounded-xl"
                    audio={false}
                    mirrored={true}
                    ref={webcamRef}
                    onUserMediaError={error => {
                        setWebcam(false)
                        setWebcamError("Error reading webcam! Make sure it's not used by another process.");
                        console.error(error);
                    }}
                    screenshotFormat="image/jpeg"
                />
                <div className="mb-8"></div>
            </>}

            <div className="flex gap-4 items-center">
                <ProfilePicture imageURL={selectedImageURL} pictureSize="w-16 h-16"/>
                <div>
                    <div className="mb-1 font-bold text-lg">Profile Picture</div>
                    <div className={`flex divide-x-2 divide-white divide-opacity-25`}>
                        <label>
                            <div>
                                <button
                                    type="button"
                                    className={`w-fit py-1.5 px-4 rounded-l text-white ${ClickableUIStyle[UIColor.Default]} text-lg`}
                                    disabled={webcam}
                                    onClick={() => imageInputRef.current?.click()}
                                >
                                    <FontAwesomeIcon icon={faUpload}></FontAwesomeIcon>
                                </button>
                            </div>
                            <input
                                type="file"
                                ref={imageInputRef}
                                onChange={async (event) => {
                                    if (event.target.files.length === 0)
                                        return;

                                    await setProfileImage(event.target.files[0])
                                    setUpdateText("Update");
                                }}
                                className="hidden"
                            />
                        </label>
                        <button
                            type="button"
                            className={`w-fit py-1.5 px-4 text-white ${ClickableUIStyle[UIColor.Default]} text-lg`}
                            onClick={async () => {
                                await setProfileImage(await ImageUtils.getRandomHoldImage());

                                setError(null);
                                setWebcamError(null);
                                setModified(true);
                                setUpdateText("Update")
                            }}
                            disabled={webcam}
                        >
                            <FontAwesomeIcon icon={faDiceSix}></FontAwesomeIcon>
                        </button>
                        <button
                            type="button"
                            className={`w-fit py-1.5 px-4 ${webcam ? '' : 'rounded-r'} text-white ${ClickableUIStyle[webcam ? UIColor.Green : UIColor.Default]} text-lg`}
                            onClick={() => {
                                setError(null);
                                setWebcamError(null);

                                if (!webcam) {
                                    // first click opens camera
                                    setWebcam(true)
                                } else {
                                    // second click takes picture and closes camera
                                    capture()
                                    setWebcam(false)
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={faCamera}></FontAwesomeIcon>
                        </button>
                        {
                            webcam && <button
                                type="button"
                                className={`w-fit py-1.5 px-2 rounded-r text-white ${ClickableUIStyle[UIColor.Default]} text-lg`}
                                onClick={() => {
                                    setError(null);
                                    setWebcamError(null);
                                    setWebcam(false)
                                }}
                            >
                                <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                            </button>

                        }
                    </div>
                    <div className="mb-1"></div>
                    <p className={`${UIColor.MinorText} text-sm`}>Visible to others.</p>
                </div>
            </div>
            <ErrorText message={error}/>
            <ErrorText message={webcamError}/>
            <div className="mb-5"></div>

            <form onSubmit={onSubmit}>
                <BasicTextInput
                    label="Email"
                    disabled={true}
                    value={user.value.email}
                >
                </BasicTextInput>
                <div className="mb-1"></div>
                <p className={`${UIColor.MinorText} text-sm`}> Always kept private.</p>
                <div className="mb-4"></div>
                <BasicTextInput
                    label="Username"
                    autoComplete="username"
                    defaultValue={user.value.nickname ?? ""}
                    onChange={
                        event => {
                            setModified(true)
                            setUpdateText("Update")
                            setNickname(event.target.value);
                        }
                    }
                >
                </BasicTextInput>
                <div className="mb-1"></div>
                <p className={`${UIColor.MinorText} text-sm`}> How other people will see you.</p>

                <div className="mb-8"></div>
                <BasicButton
                    type="submit"
                    buttonColor={UIColor.Blue}
                    disabled={!modified || webcam}
                    className="w-full"
                    ref={updateRef}
                >
                    {submitted && user.status.isLoading(UserActions.SaveUserMetadata) ?
                        <SpinLoadingIndicator/> : <>{updateText}</>}
                </BasicButton>
            </form>

            {submitted && !user.status.isLoading(UserActions.SaveUserMetadata) &&
                <ErrorFromBackendResult data={user.status.getError(UserActions.SaveUserMetadata)}/>}

            <div className="mb-8"></div>
            <div className="text-center text-red-500 flex justify-between items-center gap-3">
                <div className="border grow"></div>
                <BasicButton
                    type="submit"
                    buttonColor={UIColor.Default}
                    onClick={() => setDangerZoneOpen(true)}
                    disabled={dangerZoneOpen}
                    padding="py-1 px-3"
                >
                    Danger Zone
                    <FontAwesomeIcon className="pl-2" icon={faTriangleExclamation}/>
                </BasicButton>
                <div className="border grow"></div>
            </div>
            {dangerZoneOpen ? <div>
                    <div className="mb-8"></div>
                    <BasicButton
                        type="button"
                        className="w-full"
                        padding="py-1 px-3"
                        onClick={() => setChangePassword(true)}
                    >
                        Change Password
                        <FontAwesomeIcon className="pl-2" icon={faKey}/>
                    </BasicButton>
                    <div className="mb-4"></div>
                    <BasicButton
                        type="button"
                        buttonColor={UIColor.Red}
                        className="w-full"
                        padding="py-1 px-3"
                        onClick={() => setDeleteUser(true)}
                    >
                        Delete Account
                        <FontAwesomeIcon className="pl-2" icon={faUserSlash}/>
                    </BasicButton>
                </div>
                : ''}
        </ModalWindow>
        <ChangePasswordModal onClose={() => setChangePassword(false)} show={changePassword}/>
        <DeleteAccountModal onClose={() => setDeleteUser(false)} show={deleteUser}/>
    </>;
}
