import {useEffect, useReducer, useState, useSyncExternalStore} from "react";

function onlineChangeSubscribe(callback) {
    window.addEventListener("online", callback);
    window.addEventListener("offline", callback);
    return () => {
        window.removeEventListener("online", callback);
        window.removeEventListener("offline", callback);
    };
}

function useOnlineStatus() {
    return useSyncExternalStore(onlineChangeSubscribe, () => navigator.onLine);
}

class ScreenDimensions {
    static MOBILE_WIDTH = 640;  // Tailwind's SM; please don't fuck around with this
}

function useScreenDimensions() {
    const [dimensions, setDimensions] = useState([window.innerWidth, window.innerHeight]);

    function onResize() {
        setDimensions([window.innerWidth, window.innerHeight]);
    }

    useEffect(() => {
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        }
    }, []);
    return dimensions;
}

function useForceUpdate() {
    return useReducer(x => x + 1, 0)[1];
}

export {useOnlineStatus, useScreenDimensions, ScreenDimensions, useForceUpdate};
