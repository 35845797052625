import {createContext} from "react";
import {SpecialStates, unimplementedFunction} from "../common/Utils";
import RebuildProposal from "../model/RebuildProposal";
import {backend, BackendErrorType, BackendResult} from "../model/Backend";

const ProposalContext = createContext(null);
const ProposalDispatchContext = createContext(unimplementedFunction);

class ProposalActions {
    static StartLoading = "start_loading";
    static ServerError = "server_error";
    static SetFromServer = "set_from_server";
    static EnteredNewRebuild = "entered_new_rebuild";
    static StartReviewing = "start_reviewing";
    static StopReviewing = "stop_reviewing";
    static RemoveHoldFromRoute = "remove_hold_from_route";
    static AddHoldToRoute = "add_hold_to_route";
    static RouteAttributeUpdate = "route_attribute_update";
    static RetrySave = "retry_save";
}

function buildStoreProposalChangeFunction(proposal, setProposal) {
    async function storeProposalChange(action) {
        const newProposal = proposalReducer(proposal, action, setProposal);
        setProposal(newProposal);
    }

    return storeProposalChange;
}

function proposalReducer(proposal, action, setProposal) {
    function storeUpdatedProposal() {
        // TODO: consolidate with the same logic in UserContext
        proposal.saving = true;
        proposal.savingError = null;
        backend.setRebuildRoutes(proposal.metadata.id, proposal.allRoutes())
            .then(result => {
                setProposal(proposal => {
                    proposal.saving = false;
                    if (!result.isSuccess()) {
                        proposal.savingError = result;
                    }
                    return proposal.shallowClone();
                })
            })
            .catch(error => {
                console.error(error);
                setProposal(proposal => {
                    proposal.saving = false;
                    proposal.savingError = BackendResult.FromError(BackendErrorType.GeneralError);
                    return proposal.shallowClone();
                })
            })
        return proposal.shallowClone(); // hack
    }
    switch (action.type) {
        case ProposalActions.StartLoading: {
            return SpecialStates.Loading;
        }
        case ProposalActions.ServerError: {
            return SpecialStates.CreateErrorState(action.error);
        }
        case ProposalActions.SetFromServer: {
            return action.proposal;
        }
        case ProposalActions.EnteredNewRebuild: {
            return RebuildProposal.NotReady({
                readyForRevision: false,
            });
        }
        case ProposalActions.StartReviewing: {
            proposal.beingReviewed = true;
            return proposal.shallowClone(); // hack
        }
        case ProposalActions.StopReviewing: {
            proposal.beingReviewed = false;
            return proposal.shallowClone(); // hack
        }
        case ProposalActions.RemoveHoldFromRoute: {
            const hold = action.hold;
            console.assert(hold.route !== null);
            hold.route.removeHold(hold);
            return storeUpdatedProposal();
        }
        case ProposalActions.AddHoldToRoute: {
            const hold = action.hold;
            const route = action.route;
            if (hold.route !== null) {
                hold.route.removeHold(hold);
            }
            route.addHold(hold);
            return storeUpdatedProposal();
        }
        case ProposalActions.RouteAttributeUpdate: {
            action.route.metadata[action.attribute] = action.newValue;
            console.log(action.route.metadata)
            return storeUpdatedProposal();
        }
        case ProposalActions.RetrySave: {
            return storeUpdatedProposal();
        }
        default: {
            throw Error("Unknown action: " + action.type);
        }
    }
}

export {ProposalContext, ProposalDispatchContext, ProposalActions, buildStoreProposalChangeFunction};
