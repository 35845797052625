import {BackendErrorType} from "../model/Backend";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTriangleExclamation} from '@fortawesome/free-solid-svg-icons';

export function ErrorText({message, key, spacing=4}) {
    if (message === null || message === undefined)
        return;

    return <div key={key} className={`flex justify-center space-x-4 m-${spacing}`}>
        <FontAwesomeIcon icon={faTriangleExclamation} className={`my-auto text-red-400 text-xl`}/>
        <p className="text-red-400">
            {message}
        </p>
    </div>;
}

export default function ErrorFromBackendResult({data, messages, spacing}) {
    if (!data || data.isSuccess())
        return null;

    messages = {
        [BackendErrorType.ConnectionError]: "Couldn't connect to server.",
        [BackendErrorType.GeneralError]: "An unknown error occurred, please contact the developers.",
        ...messages
    }

    return <>
        {data.errors.map((error, index) =>
            <ErrorText
                key={index}
                message={error in messages ? messages[error] : error}
                spacing={spacing}
            ></ErrorText>
        )}
    </>;
}
