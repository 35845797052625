import {Fragment, useContext, useEffect, useState} from "react";
import {BasicButton} from "../../common/Button";
import {UserActions, UserDispatchContext} from "../../contexts/UserContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBookmark,
    faCalendar,
    faGear,
    faList,
    faListCheck,
    faListOl,
    faRightFromBracket,
    faUser,
    faUserEdit,
    faWarning
} from '@fortawesome/free-solid-svg-icons'
import {RouteStateType, UserActivityType} from "../../model/User";
import {DateUtils} from "../../common/Utils";
import {SetCanvasStateContext} from "../../contexts/CanvasContext";
import {RouteFocusState} from "../../scene/canvas/CanvasState";
import {ActivityCard, BookmarkCard} from "./ActivityCards";
import {ScreenDimensions, useScreenDimensions} from "../../common/CustomHooks";
import {UiActions, UiDispatchContext} from "../../contexts/UiContext";
import {WallContext} from "../../contexts/WallContext";
import ModifyActivitiesModal from "./ModifyActivitiesModal";
import SettingsModal from "./SettingsModal";
import ProgressGrid from "./ProgressGrid";
import ProfilePicture from "./ProfilePicture";
import {LeaderboardActions, LeaderboardDispatchContext} from "../../contexts/LeaderboardContext";
import ClickableFontAwesomeIcon from "../../common/ClickableFontAwesomeIcon";
import {UIColor} from "../../Config";
import RegisterModal from "./RegisterModal";
import {WarningMessage} from "../../common/WarningMessage";

const DateComponent = ({dateString, className}) => {
    const [day, month, year] = dateString.split(', ');

    return (
        <div className={className}>
            <span className="font-bold">{day}</span>, {month}, {year}
        </div>
    );
};


export default function AccountInfo({user}) {
    const wall = useContext(WallContext);
    const userDispatch = useContext(UserDispatchContext);
    const setCanvasState = useContext(SetCanvasStateContext);
    const uiStateDispatch = useContext(UiDispatchContext);
    const [screenWidth, _] = useScreenDimensions();
    const [modifyActivities, setModifyActivities] = useState(null);
    const [showModifyActivities, setShowModifyActivities] = useState(false);

    const leaderboardDispatch = useContext(LeaderboardDispatchContext);

    const [registeringOpen, setRegisteringOpen] = useState(false);

    const [editProfile, setEditProfile] = useState(false);

    const [gridType, setGridType] = useState(0);

    const [countdown, setCountdown] = useState(0);

    useEffect(() => {
        let timer;
        if (countdown > 0) {
            timer = setInterval(() => {
                setCountdown(countdown - 1);
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [countdown]);

    function sendVerificationEmail() {
        if (user.status.isLoading(UserActions.ResendVerificationEmail))
            return;

        userDispatch({
            type: UserActions.ResendVerificationEmail,
            postSuccessHooks: () => {
                setCountdown(30);
            }
        });

    }

    function signOut() {
        userDispatch({type: UserActions.Logout});
        leaderboardDispatch({type: LeaderboardActions.Update});
    }

    function closeSidePanelIfMedium() {
        if (screenWidth <= ScreenDimensions.MOBILE_WIDTH) {
            uiStateDispatch({type: UiActions.CloseSidePanel});
        }
    }

    function handleBookmarkClick(activity) {
        if (activity.isOnWall) {
            setCanvasState(new RouteFocusState(activity.route));
            closeSidePanelIfMedium();
        }
    }

    function handleBookmarkRemove(activity) {
        console.assert(activity.type === UserActivityType.Bookmark);

        userDispatch({
            type: UserActions.RemoveActivities,
            activities: [activity],
        });
    }

    let activitiesList = [];
    let activitiesListTypes = [UserActivityType.Attempt, UserActivityType.Climb, UserActivityType.Flash, UserActivityType.Repeat];
    let activitiesByDayAndRoute = {};

    // Group activities by day and route
    for (let i = user.value.activities.length - 1; i >= 0; i--) {
        const activity = user.value.activities[i];
        if (!activitiesListTypes.includes(activity.type))
            continue;

        const dateKey = DateUtils.toPrettyDay(activity.timestamp);
        const routeKey = activity.route.id;

        if (!activitiesByDayAndRoute[dateKey]) {
            activitiesByDayAndRoute[dateKey] = {};
        }

        if (!activitiesByDayAndRoute[dateKey][routeKey]) {
            activitiesByDayAndRoute[dateKey][routeKey] = [];
        }

        activitiesByDayAndRoute[dateKey][routeKey].push(activity);
    }

    // Create activity list elements
    for (let dateKey in activitiesByDayAndRoute) {
        const routes = activitiesByDayAndRoute[dateKey];

        let thisDayActivities = user.value.activities.filter(otherActivity =>
            DateUtils.isSameDay(otherActivity.timestamp, new Date(dateKey)) &&
            otherActivity.isOnWall &&
            activitiesListTypes.includes(otherActivity.type)
        );

        activitiesList.push(
            <>
                <Fragment key={"date" + dateKey}>
                    <div className="mb-6"></div>
                    <div className="flex items-center justify-between">
                        <DateComponent dateString={dateKey} className="pl-1"></DateComponent>
                        <ClickableFontAwesomeIcon
                            icon={faGear}
                            onClick={() => {
                                setModifyActivities(thisDayActivities);
                                setShowModifyActivities(true);
                            }}
                            className={`${thisDayActivities.length === 0 ? "invisible" : "visible"} text-xl ml-2`}
                            inverseHover={true}
                        />
                    </div>
                    <div className="m-1"></div>
                </Fragment>
            </>
        );

        for (let routeKey in routes) {
            const activities = routes[routeKey];
            const key = `${dateKey}${routeKey}`;

            activitiesList.push(
                <Fragment key={"activity" + key}>
                    <ActivityCard
                        activities={activities} onActivityClick={handleBookmarkClick}
                        onActivityModify={() => {
                            if (activities[0].isOnWall) {
                                setModifyActivities(activities);
                                setShowModifyActivities(true);
                            }
                        }}
                        editable={activities[0].isOnWall}
                    />
                </Fragment>
            );
        }
    }


    let bookmarksList = [];

    for (let i = user.value.activities.length - 1; i >= 0; i--) {
        const bookmark = user.value.activities[i];

        if (bookmark.type !== UserActivityType.Bookmark)
            continue;

        // skip bookmarks to non-existent routes
        if (!bookmark.isOnWall)
            continue;

        const key = `${i}${bookmark.route.id}`;
        bookmarksList.push(
            <Fragment key={"bookmark" + key}>
                <BookmarkCard
                    bookmark={bookmark}
                    routeState={user.value.getRouteClimbingState(bookmark.route)}
                    onClick={handleBookmarkClick}
                    onRemove={handleBookmarkRemove}/>
            </Fragment>
        );
    }

    let climbed = 0;
    for (const state of user.value.getRoutesClimbingStates(wall.routes)) {
        if (state === RouteStateType.Climbed || state === RouteStateType.Flashed) {
            climbed += 1;
        }
    }

    let temporaryAccountMessage = <WarningMessage
        title="Temporary Account!"
        text={<>
            This account is <strong>temporary</strong> and will be <strong>removed after leaving the website</strong>.
            Make sure you register if you wish to preserve your data!
        </>}/>;

    let accountInformation = <div className={`${UIColor.DarkInput} rounded-xl p-3`}>
        <div className="flex gap-x-5 items-center">
            <ProfilePicture onClick={() => setEditProfile(true)} pictureSize="w-16 h-16"
                            imageURL={user.value.photoURL}/>
            <div className="min-w-0 grow">
                <p className="text-xl font-bold truncate">{user.value.email ?? "<email not set>"}</p>
                <div className="mb-2"></div>
                {
                    user.value.nickname ? <div className="flex items-center">
                        <FontAwesomeIcon icon={faUser} className="w-3 pr-2"></FontAwesomeIcon>
                        <p className="truncate">{user.value.nickname}</p>
                    </div> : <div className="flex items-center">
                        <FontAwesomeIcon icon={faUser} className="w-3 pr-2"></FontAwesomeIcon>
                        <p className={UIColor.MinorText}>&lt;username not set&gt;</p>
                    </div>
                }
            </div>
        </div>
    </div>;

    let emailMessage = <WarningMessage
        title="Email not verified!"
        text={<>
            An email with a <strong>verification link</strong> has been sent to your email address, to make
            sure it belongs to you.
        </>}>
        <BasicButton
            buttonColor={UIColor.Yellow}
            padding="py-1 px-3"
            className="w-full"
            onClick={sendVerificationEmail}
            disabled={countdown > 0}
        >
            {countdown > 0 ? `Sent! (${countdown})` : 'Resend Email'}
        </BasicButton>
    </WarningMessage>;

    let gymVerifiedMessage = <div className="bg-yellow-700 rounded-xl p-4">
        <p className="text-lg">
            <FontAwesomeIcon icon={faWarning} className="text-xl"></FontAwesomeIcon>
            <strong className="pl-2 underline underline-offset-2">Gym not verified!</strong>
        </p>
        <div className="m-1"></div>
        <p>
            To appear on leaderboards and interact with other users, scan the QR code
        </p>
    </div>;

    let separator = <div className="px-4">
        <div className="mt-6 mb-6 w-full border-b-2 border-neutral-700"></div>
    </div>;

    let gridTypeInformation;
    if (gridType === 0) {
        gridTypeInformation = <>{climbed}/{wall.routes.length}</>;
    } else if (gridType === 1) {
        const highestGrade = wall.routes
            .filter(route => user.value.isRouteClimbed(route))
            .map(obj => obj.difficulty)
            .filter(difficulty => difficulty != null).sort().pop();

        gridTypeInformation = <>{highestGrade ?? "-"}</>;
    } else {
        let points = user.value.getRoutesPoints(wall.routes);
        gridTypeInformation = <>{points.toFixed(0)}</>;
    }

    return (
        <>
            <div id="account-info">
                <h2 className="text-4xl text-center"> Profile </h2>
                <div className="mb-5"></div>

                {user.value.isTemporary ? temporaryAccountMessage : accountInformation}

                <div className="mb-3"></div>

                <div className="flex gap-x-3">
                    <BasicButton onClick={signOut} className="w-full">
                        <div className="flex flex-col">
                            <span>Sign Out<FontAwesomeIcon icon={faRightFromBracket} className="pl-2"/></span>
                            {user.value.isTemporary ?
                                <span className={`opacity-50 text-sm`}>Data will be lost!</span> : ""}
                        </div>
                    </BasicButton>
                    {
                        user.value.isTemporary ?
                            <BasicButton
                                className="w-full"
                                buttonColor={UIColor.DarkYellow}
                                onClick={() => setRegisteringOpen(true)}
                            >
                                <div className="flex flex-col">
                                    <span>Register <FontAwesomeIcon icon={faUserEdit} className="pl-2"/></span>
                                    {user.value.isTemporary ?
                                        <span className={`opacity-50 text-sm`}>Saves activities!</span> : ""}
                                </div>
                            </BasicButton>
                            : <BasicButton onClick={() => setEditProfile(true)} className="w-full"> Settings
                                <FontAwesomeIcon icon={faUserEdit} className="pl-2"/>
                            </BasicButton>
                    }
                </div>

                {
                    (!user.value.isVerified && !user.value.isTemporary) ? <>
                        <div className="mb-5"></div>
                        {emailMessage}
                    </> : ""
                }

                <div className="mb-8"></div>
                {separator}

                <h2 className="text-2xl flex">
                    <BasicButton onClick={() => setGridType((gridType + 1) % 3)} padding="py-0.5 px-2">
                        <FontAwesomeIcon icon={gridType === 0 ? faListCheck : (gridType === 1 ? faList : faListOl)}/>
                    </BasicButton>
                    <span className="pl-3 py-0.5">
                        {gridType === 0 ? "Progress" : gridType === 1 ? "Grades" : "Points"}
                    </span>
                    <span className="grow"></span>
                    <span className="py-0.5">
                        <code className="text-neutral-500">[</code>
                        <code>{gridTypeInformation}</code>
                        <code className="text-neutral-500">]</code>
                    </span>
                </h2>
                <div className="mb-4"></div>
                <ProgressGrid
                    onCellClick={closeSidePanelIfMedium}
                    useGrades={gridType === 1}
                    usePoints={gridType === 2}
                />
                <div className="mb-5"></div>

                {separator}

                <h3 className="text-2xl">
                    <span className="absolute"><FontAwesomeIcon icon={faBookmark}/></span>
                    <span className="pl-8">Bookmarks</span>
                </h3>
                <div className="mb-2"></div>
                {bookmarksList.length === 0
                    ? <p className={UIColor.MinorText}>You have no bookmarked routes!</p>
                    : bookmarksList}

                {separator}

                <h3 className="text-2xl">
                    <span className="absolute"><FontAwesomeIcon icon={faCalendar}/></span>
                    <span className="pl-8">Sessions</span>
                </h3>
                <div className="mb-2"></div>
                {activitiesList.length === 0
                    ? <p className={UIColor.MinorText}>You have no recorded activity!</p>
                    : activitiesList}

            </div>

            <SettingsModal user={user} onClose={() => setEditProfile(false)} show={editProfile}/>
            <RegisterModal onClose={() => setRegisteringOpen(false)}
                           topColor="bg-yellow-700"
                           text={<p><strong>All activities</strong> you have logged in the temporary
                               account <strong>will be saved!</strong></p>}
                           show={registeringOpen}
            />
            <ModifyActivitiesModal
                activities={modifyActivities}
                onClose={() => setShowModifyActivities(false)}
                show={showModifyActivities}
            />
        </>
    );
}