import ModalWindow from "../common/ModalWindow";
import {BasicButton} from "../common/Button";

export default function MessageModal({title, message, onClose}) {
    return (
        <ModalWindow handleClose={onClose}>
            <p className="text-2xl">{title}</p>
            <div className="mb-5"></div>
            <p className="text-lg">{message}</p>
            <div className="mb-5"></div>
            <div className="flex flex-col items-center">
                <BasicButton onClick={onClose}>Close</BasicButton>
            </div>
        </ModalWindow>
    )
}