import {Fragment, useContext, useRef, useState} from "react";
import SpinLoadingIndicator from "../../common/SpinLoadingIndicator";
import {UserActions, UserContext, UserDispatchContext} from "../../contexts/UserContext";
import BasicTextInput from "../../common/TextInput";
import {BasicButton} from "../../common/Button";
import RegisterModal from "./RegisterModal";
import ErrorFromBackendResult from "../../common/ErrorFromBackendResult";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRightToBracket} from "@fortawesome/free-solid-svg-icons";
import {UIColor} from "../../Config";
import {LeaderboardDispatchContext, LeaderboardActions} from "../../contexts/LeaderboardContext";
import BasicInputLabel from "../../common/InputLabel";
import ForgotPasswordModal from "./ForgotPasswordModal";

export default function SignIn() {
    const [registeringOpen, setRegisteringOpen] = useState(false);
    const [user, userDispatch] = [useContext(UserContext), useContext(UserDispatchContext)];

    const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);

    const loginEmail = useRef(null);
    const loginPassword = useRef(null);

    const leaderboardDispatch = useContext(LeaderboardDispatchContext);

    function onSubmit(submitEvent) {
        submitEvent.preventDefault();

        if (user.status.isLoading(UserActions.RequestLogin))
            return; // this probably shouldn't happen

        const loginData = {
            email: loginEmail.current.value,
            password: loginPassword.current.value,
            keepSignedIn: true,
        };

        userDispatch({
            type: UserActions.RequestLogin,
            loginData: loginData,
        });

        leaderboardDispatch({type: LeaderboardActions.Update});
    }

    return (
        <>
            <form onSubmit={onSubmit}>
                <h2 className="text-4xl text-center">Sign in</h2>
                <div className="mb-5"></div>
                <p className={UIColor.MinorText}>to back up your data, track your achievements, compete with others and
                    more...</p>
                <div className="mb-8"></div>
                <BasicTextInput ref={loginEmail} type="email" name="email" autoComplete="username" required={true}
                                label="Email"/>
                <div className="mb-4"></div>
                <div className="flex justify-between items-baseline">
                <BasicInputLabel label="Password"/>
                <div className={`text-center ${UIColor.MinorText} text-sm`}><p><a
                    className="text-blue-500 hover:text-blue-700 hover:underline font-bold cursor-pointer"
                    onClick={() =>setForgotPasswordOpen(true)}
                >Forgot password?</a></p></div>
                </div>
                <BasicTextInput ref={loginPassword} type="password" name="password"
                                autoComplete="current-password" required={true}/>
                <div className="mb-8"></div>
                <BasicButton type="submit" disabled={user.status.isLoading(UserActions.RequestLogin)}
                             buttonColor={UIColor.Blue}
                             className="w-full">
                    {!user.status.isLoading(UserActions.RequestLogin) ? <p>
                            Sign in <FontAwesomeIcon icon={faRightToBracket} className="pl-1"></FontAwesomeIcon>
                        </p> :
                        <SpinLoadingIndicator/>}
                </BasicButton>
                <ErrorFromBackendResult data={user.status.getError(UserActions.RequestLogin)}/>
            </form>
            <div className="mb-8"></div>
            <div className={`text-center ${UIColor.MinorText}`}><p>Don't have an account? <a
                className="text-blue-500 hover:text-blue-700 hover:underline font-bold cursor-pointer"
                onClick={() => setRegisteringOpen(true)}>Register.</a></p></div>
            <RegisterModal onClose={() => setRegisteringOpen(false)} show={registeringOpen}/>
            <ForgotPasswordModal onClose={() => setForgotPasswordOpen(false)} show={forgotPasswordOpen}/>
        </>
    );
}