import {useContext} from "react";
import {WallContext} from "../../contexts/WallContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock, faLink, faLocationDot} from "@fortawesome/free-solid-svg-icons";

export default function SideOverview() {
    const wall = useContext(WallContext);
    const metadata = wall.metadata;

    return (
        <>
            <h2 key="name" className="text-4xl text-center">{metadata["name"]}</h2>
            <div className="mb-4"></div>

            <p className="text-center italic">A modern & futuristic climbing gym.</p>
            <div className="mb-6"></div>

            <div className="rounded-xl bg-neutral-700 px-1.5 py-2">
                <div className="flex items-center space-x-2">
                    <div className="w-7 text-center"><FontAwesomeIcon icon={faLocationDot}
                                                                      className="pl-1 text-xl"></FontAwesomeIcon>
                    </div>
                    {
                        metadata['location'] &&
                        <div key="location" className="italic">
                            {metadata["location"].split('\n').map(line => (
                                <p>{line}</p>
                            ))}
                        </div>
                    }
                </div>
                <div className="mb-2"></div>

                <div className="flex items-center space-x-2">
                    <div className="w-7 text-center"><FontAwesomeIcon icon={faLink}
                                                                      className="text-lg"></FontAwesomeIcon></div>
                    <a key="website" href="https://www.crimp-heidelberg.com/"
                       className="text-blue-500 hover:text-blue-700 hover:underline cursor-pointer"
                    >
                        www.crimp-heidelberg.com
                    </a>
                </div>
                <div className="mb-2"></div>

                <div className="flex items-center space-x-2">
                    <div className="w-7 text-center"><FontAwesomeIcon icon={faClock}
                                                                      className="text-lg"></FontAwesomeIcon></div>
                    <p>Open <strong>24/7</strong> (self check-in).</p>
                </div>
            </div>
        </>
    );
}