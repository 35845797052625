export default function Window({children, className = "", id, justify=false}) {

    return (
        <div
            className={`${className} ${justify ? 'text-justify hyphens-auto' : ''} text-white max-h-[calc(100%-2rem)] w-[calc(100%-2rem)] sm:w-96 bg-neutral-800 rounded-2xl shadow-large overflow-scroll`}
            id={id}
        >
            {children}
        </div>
    );
}