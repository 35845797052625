import Canvas from "./scene/canvas/Canvas";
import {useWallReducer, WallActions, WallContext, WallDispatchContext} from "./contexts/WallContext";
import {backend} from "./model/Backend";

import {useEffect, useState} from "react";
import * as TWEEN from "@tweenjs/tween.js"
import SidePanel from "./side_panel/SidePanel";
import {UserActions, UserContext, UserDispatchContext, useUserReducer} from "./contexts/UserContext";
import {buildStoreProposalChangeFunction, ProposalContext, ProposalDispatchContext} from "./contexts/ProposalContext";
import {SpecialStates} from "./common/Utils";
import {CanvasContext, CanvasStateContext, SetCanvasContext, SetCanvasStateContext} from "./contexts/CanvasContext";
import {GlobalInfoOverlay, ProgressInfo} from "./GlobalInfoOverlay";
import NotificationBox from "./NotificationBox";
import {UiContext, UiDispatchContext, useUiReducer} from "./contexts/UiContext";
import {SetReadyContext} from "./contexts/ReadyContext";
import FeedbackModal from "./feedback/FeedbackModal";
import {
    LeaderboardActions,
    LeaderboardContext,
    LeaderboardDispatchContext,
    useLeaderboardReducer
} from "./contexts/LeaderboardContext";
import HelpBox from "./help/HelpBox";

// This is declared globally because we really don't want to load the wall twice under any circumstances.
let wallPromise = null;

function animate() {
    requestAnimationFrame(animate);
    TWEEN.update();
}

animate();

function App() {
    const [wall, wallDispatch] = useWallReducer();
    const [wallProgress, setWallProgress] = useState(0.0);
    const [wallMotto, setWallMotto] = useState(null);

    const [wallError, setWallError] = useState(null);
    const [ready, setReady] = useState(false);

    const [proposal, setProposal] = useState(SpecialStates.ToBeLoaded);
    const [canvasState, setCanvasState] = useState(null);
    const [canvas, setCanvas] = useState(null);
    const [leaderboard, leaderboardDispatch] = useLeaderboardReducer();
    const [user, userDispatch] = useUserReducer();
    const [ui, uiDispatch] = useUiReducer();

    useEffect(() => {
        if (!backend.valid) {
            throw new Response("This URL is not valid, please make sure you typed it correctly.", { status: 404 });
        }

        if (wallPromise === null) {
            wallPromise = backend.loadWall((progress, motto) => {
                setWallProgress(progress);
                setWallMotto(motto);
            });
        }

        wallPromise
            .then(wallResult => wallDispatch({type: WallActions.OnLoaded, wall: wallResult}))
            .catch(e => {
                console.error(e);
                setWallError("An error occurred, please check the URL and your internet connection. If the problem persists, please contact us.");
            });
    }, []);

    useEffect(() => {
        if (user.value === null) {
            userDispatch({type: UserActions.TryRestoreLogin});
            leaderboardDispatch({type: LeaderboardActions.Update});
        }

        leaderboardDispatch({type: LeaderboardActions.Update})
    }, []);

    if (wallError !== null) {
        throw new Response(wallError, { status: 404 });
    }

    if (wall === null) {
        return (
            <GlobalInfoOverlay>
                <ProgressInfo progress={wallProgress} motto={wallMotto}/>
            </GlobalInfoOverlay>
        );
    }

    return (
        <SetReadyContext.Provider value={setReady}>
            {!ready ?
                <GlobalInfoOverlay>
                <ProgressInfo progress={1.0} motto={"Finalizing..."}/>
                </GlobalInfoOverlay>
                    : ""}
            <WallContext.Provider value={wall}>
                <WallDispatchContext.Provider value={wallDispatch}>
                    <LeaderboardDispatchContext.Provider value={leaderboardDispatch}>
                        <LeaderboardContext.Provider value={leaderboard}>
                            <UserContext.Provider value={user}>
                                <UserDispatchContext.Provider value={userDispatch}>
                                    <ProposalContext.Provider value={proposal}>
                                        <ProposalDispatchContext.Provider
                                            value={buildStoreProposalChangeFunction(proposal, setProposal)}>
                                            <CanvasContext.Provider value={canvas}>
                                                <SetCanvasContext.Provider value={setCanvas}>
                                                    <CanvasStateContext.Provider value={canvasState}>
                                                        <SetCanvasStateContext.Provider value={setCanvasState}>
                                                            <UiContext.Provider value={ui}>
                                                                <UiDispatchContext.Provider value={uiDispatch}>
                                                                    <Canvas/>
                                                                    <NotificationBox/>
                                                                    <SidePanel/>
                                                                    <FeedbackModal/>
                                                                </UiDispatchContext.Provider>
                                                            </UiContext.Provider>
                                                        </SetCanvasStateContext.Provider>
                                                    </CanvasStateContext.Provider>
                                                </SetCanvasContext.Provider>
                                            </CanvasContext.Provider>
                                        </ProposalDispatchContext.Provider>
                                    </ProposalContext.Provider>
                                </UserDispatchContext.Provider>
                            </UserContext.Provider>
                        </LeaderboardContext.Provider>
                    </LeaderboardDispatchContext.Provider>
                </WallDispatchContext.Provider>
            </WallContext.Provider>
        </SetReadyContext.Provider>
    );
}

export default App;
