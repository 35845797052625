import {createContext, useState} from "react";
import {unimplementedFunction} from "../common/Utils";

const WallContext = createContext(null);
const WallDispatchContext = createContext(unimplementedFunction);

function buildStoreWallDataFunction(setWallData) {
    async function storeWallData(newWallData) {
        setWallData(newWallData);
        // TODO: store at backend ()
    }

    return storeWallData;
}

class WallActions {
    static OnLoaded = "on_loaded";
    static SetMetadata = "set_metadata";
    static SetMetadataAttribute = "set_metadata_attribute";
}

function useWallReducer() {
    const [wall, setWall] = useState(null);

    function wallDispatch(action) {
        setWall(w => _wallReducer(w, action));
    }

    return [wall, wallDispatch];
}

function _actionSideEffects(action) {
    // TODO: move the logic from SettingsModal here (and use it in ProposalWalkthroughController)
}

function _wallReducer(wall, action) {
    switch (action.type) {
        case WallActions.OnLoaded: {
            return action.wall;
        }
        case WallActions.SetMetadata: {
            const newWall = wall.shallowClone();
            newWall.metadata = action.metadata;
            newWall.updateCustomMetadata()
            return newWall;
        }
        case WallActions.SetMetadataAttribute: {
            const newWall = wall.shallowClone();
            newWall.metadata[action.attribute] = action.value;
            newWall.updateCustomMetadata()
            return newWall;
        }
        default:
            throw Error("Unknown action: " + action.type);
    }
}

export {WallContext, WallDispatchContext, useWallReducer, WallActions};