import * as TWEEN from "@tweenjs/tween.js";
import * as THREE from "three";
import * as config from "../../Config"

export default class CameraAnimation {
    constructor(canvas) {
        this.canvas = canvas;
        this.camera = canvas.camera;
        this.tween = null;
    }

    animate(targetPosition, targetQuaternion, duration = config.ANIMATION_DURATION, curve = TWEEN.Easing.Quadratic.InOut) {
        if (this.tween !== null && this.tween.isPlaying()) {
            this.tween.stop();
        }

        this.tween = new TWEEN.Tween([this.camera.position, this.camera.quaternion])
            .to([targetPosition, targetQuaternion], duration)
            .easing(curve)
            .onUpdate(() => {
                this.canvas.render();
            })
            .onComplete(() => {
                setTimeout(() => {
                    this.canvas.render();
                });
            })
            .startFromCurrentValues();
    }

    animateLookAt(targetPosition, targetLookAt, duration = config.ANIMATION_DURATION) {
        const rotationMatrix = new THREE.Matrix4();
        rotationMatrix.lookAt(targetPosition, targetLookAt, this.camera.up);
        const targetQuaternion = new THREE.Quaternion();
        targetQuaternion.setFromRotationMatrix(rotationMatrix);
        this.animate(targetPosition, targetQuaternion, duration);
    }

    isPlaying() {
        return this.tween !== null && this.tween.isPlaying();
    }
}
