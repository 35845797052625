import {useContext, useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faComment, faHeart, faPaperPlane} from '@fortawesome/free-solid-svg-icons'
import {UserActions, UserContext, UserDispatchContext} from "../contexts/UserContext";
import ModalWindow from "../common/ModalWindow";
import {BasicButton} from "../common/Button";
import {UIColor} from "../Config";
import ErrorFromBackendResult from "../common/ErrorFromBackendResult";
import SpinLoadingIndicator from "../common/SpinLoadingIndicator";

export default function FeedbackModal() {
    const [feedbackModal, setFeedbackModal] = useState(false);
    const [thankYouModal, setThankYouModal] = useState(false);
    const [feedbackValue, setFeedbackValue] = useState(null);

    const user = useContext(UserContext);
    const userDispatch = useContext(UserDispatchContext);

    async function onSubmit(submitEvent) {
        submitEvent.preventDefault();

        if (user.status.isLoading(UserActions.SubmitFeedback))
            return;

        userDispatch({
            type: UserActions.SubmitFeedback,
            text: feedbackValue,
            postSuccessHooks: () => {
                setFeedbackModal(false);
                setThankYouModal(true);
            }
        });
    }

    return (
        <>
            <BasicButton
                id="open-navigation-button"
                buttonColor={UIColor.Blue}
                className="text-white text-2xl absolute top-4 right-4 rounded-2xl shadow-medium z-0"
                padding="px-3 py-1.5"
                onClick={() => setFeedbackModal(true)}

            >
                <FontAwesomeIcon
                    icon={faComment}
                />
            </BasicButton>
                <ModalWindow
                    handleClose={() => setThankYouModal(false)}
                    title="Submitted!"
                    show={thankYouModal}
                >
                    <p className="text-xl">Thank you! <FontAwesomeIcon icon={faHeart} className="ml-1"></FontAwesomeIcon>
                    </p>
                    <div className="mb-4"></div>
                    <p>Reading user feedback is the best way for us to work on things that matter.
                        It is also something that only a fraction of users do, thank you for being one of them.</p>
                    <div className="mb-2"></div>
                    <p className={`text-right ${UIColor.MinorText}`}>– Team Climbuddy</p>
                </ModalWindow>
                <ModalWindow
                    handleClose={() => setFeedbackModal(false)}
                    title="Feedback"
                    topColor={UIColor.Blue}
                    show={feedbackModal}
                >
                    <form method="post" onSubmit={onSubmit}>
                        <p>
                            Did you find a <strong>bug</strong> while using the app?
                            Are you missing a <strong>feature</strong> that would make Climbuddy even more awesome?
                        </p>
                        <div className="mb-4"></div>
                        <textarea
                            placeholder="Let us know..."
                            className="w-full p-2 bg-neutral-700 rounded min-h-[5rem]"
                            onChange={(event) =>
                                setFeedbackValue(event.target.value)
                            }
                        />
                        <div className="mb-3"></div>
                        <p className={`${UIColor.MinorText} text-sm`}>The feedback provided will
                            be <strong>anonymous</strong>,
                            even when logged in. If you would like to get in contact, <a
                                className="text-blue-500 hover:text-blue-700 hover:underline cursor-pointer"
                                href="mailto:feedback@climbuddy.com">send us an email</a> instead.</p>

                        <div className="mb-5"></div>

                        <BasicButton
                            type="submit"
                            buttonColor={UIColor.Blue}
                            className="w-full"
                            disabled={feedbackValue === null || feedbackValue.length === 0}
                        >
                            {user.status.isLoading(UserActions.SubmitFeedback) ? <SpinLoadingIndicator/> :
                                <> Send <FontAwesomeIcon icon={faPaperPlane} className="ml-1"></FontAwesomeIcon> </>}
                        </BasicButton>
                    </form>
                    {<ErrorFromBackendResult data={user.status.getError(UserActions.SubmitFeedback)}/>}
                </ModalWindow>
        </>
    );
}