import {BufferGeometryUtils, CameraUtils, DateUtils, VectorUtils} from "../common/Utils.js";
import * as THREE from "three";

class HoldType {
    static Hold = 0;
    static Volume = 1;
    static Label = 2;
}

class Wall {
    constructor(wallMesh, additionalMesh, holdsMesh, routes, holds, wallMetadata) {
        this.wallMesh = wallMesh;
        this.additionalMesh = additionalMesh;
        this.holdsMesh = holdsMesh;
        this.metadata = wallMetadata;

        this.updateCustomMetadata()

        this.routes = routes;
        this.holds = holds;
    }

    static fromServerData(wallMesh, additionalMesh, holdsMesh, routesData, wallMetadata, holdsMetadata) {
        let routes = [];
        const holds = new Map();

        holdsMesh.traverse(child => {
            if (child.type === "Mesh") {
                holds.set(child.name, new Hold(child, child.name));
            }
        });

        holdsMetadata.forEach(
            (data) => {
                if (holds.has(data.meshId)) {
                    holds.get(data.meshId).metadata = data;
                } else {
                    console.error(`Got metadata for non-existent hold "${data.meshId}".`);
                }
            }
        );

        let holdsInRoute = new Set([]);
        for (let routeData of routesData) {
            const routeHolds = [];
            for (let holdName of routeData["staticData"].holds) {

                if (!holds.has(holdName))
                    throw new Error(`Route contains unknown hold "${holdName}".`)

                routeHolds.push(holds.get(holdName));

                holdsInRoute.add(holdName);
            }

            // Holds might get modified and this would become stale.
            delete routeData["staticData"].holds;
            routes.push(new Route(routeHolds, routeData));
        }

        for (const holdValue of holds.values()) {
            if (holdValue.metadata.elementType === HoldType.Hold && holdValue.route === null) {
                holdValue.holdMesh.material.opacity = 0.35;
                holdValue.holdMesh.material.transparent = true;
            }
        }

        let centers = routes.map(route => route.getCenter());

        routes = CameraUtils.nearestNeighborTSP(centers).tour.map(index => routes[index])

        return new Wall(wallMesh, additionalMesh, holdsMesh, routes, holds, wallMetadata);
    }

    shallowClone() {
        return new Wall(
            this.wallMesh,
            this.additionalMesh,
            this.holdsMesh,
            this.routes,
            this.holds,
            JSON.parse(JSON.stringify(this.metadata)),
        );
    }

    updateCustomMetadata() {
        try {
            this.customData = JSON.parse(this.metadata["customData"]);
        } catch {
            this.customData = {};
        }
    }
}

class Hold {
    constructor(holdMesh, name) {
        this.holdMesh = holdMesh;
        this.name = name;

        // Will be set by Route.
        this.route = null;

        // Will be set by iterating over metadata
        this.metadata = {};

        this._center = null;
    }

    getCenter() {
        if (this._center !== null)
            return this._center;

        try {
            const c = this.metadata.center;
            this._center = THREE.Vector3(c[0], c[1], c[2]);
        } catch (error) {
        }

        if (this._center === null)
            this._center = BufferGeometryUtils.meshCenter(this.holdMesh);

        return this._center;
    }
}

class Route {
    constructor(holds, data) {
        this.holds = holds;

        this.setStaticData(data["staticData"])
        this.setDynamicData(data["dynamicData"])

        this.center = this.metadata["center"] || null;
        this.viewingDirection = this.metadata["viewing_direction"] || null;
        this.viewingPoint = this.metadata["viewing_point"] || null;

        for (let hold of holds) {
            hold.route = this;
        }
    }

    setStaticData(metadata) {
        this.metadata = metadata;
        this.name = this.metadata["name"];
        this.id = this.metadata["id"];
        this.builder = this.metadata["builder"];
        this.sector = this.metadata["sector"];
        this.difficulty = this.metadata["difficulty"];
        this.color = this.metadata["color"];
        this.creationDateUtc = this.metadata["creationDateUtc"];
    }

    setDynamicData(dynamicData) {
        this.dynamicData = dynamicData;

        this.likes = this.dynamicData['likes']
        this.dislikes = this.dynamicData['dislikes']
        this.softRatings = this.dynamicData['softRatings']
        this.hardRatings = this.dynamicData['hardRatings']

        this.repeats = this.dynamicData['repeats'];
    }

    getRouteMetadata() {
        const holdsMetadata = this.holds.map(hold => hold.name);
        return {
            ...this.metadata,
            holds: holdsMetadata,
        };
    }

    static getCreationDateString(route) {
        if (route.creationDateUtc === null) {
            return null;
        } else {
            const date = new Date(route.creationDateUtc);
            return DateUtils.toFormalDay(date);
        }
    }

    static getColorHexString(route) {
        const color = route.color;
        if (color === null)
            return null;
        if (color.match(/^[0-9a-f]{6}$/)) {
            return "#" + color;
        }
        return null;
    }

    removeHold(hold) {
        console.assert(hold.route === this);
        const holdIdx = this.holds.indexOf(hold);
        console.assert(holdIdx >= 0);
        this.holds.splice(holdIdx, 1);
        hold.route = null;
    }

    addHold(hold) {
        console.assert(this.holds.indexOf(hold) === -1);
        this.holds.push(hold);
        hold.route = this;
    }

    getCenter() {
        if (this.center !== null)
            return this.center.clone();

        const holdPositions = this.holds.map(hold => hold.getCenter());
        this.center = VectorUtils.averageVectors3(holdPositions);
        return this.center.clone();
    }

    getViewingPoint(wallMesh, zoomDistance) {
        if (this.viewingPoint !== null)
            return this.viewingPoint.clone();

        const center = this.getCenter();
        const direction = this.getViewingDirection(wallMesh);

        // TODO: we should probably not just add a constant, but take into account the bounding box of the route
        this.viewingPoint = center.add(direction.multiplyScalar(-zoomDistance));
        return this.viewingPoint.clone();
    }

    getViewingDirection(wallMesh) {
        if (this.viewingDirection !== null)
            return this.viewingDirection.clone();

        const holdWallNormals = [];
        for (let hold of this.holds) {
            const normal = this.getHoldWallNormal(wallMesh, hold);
            if (normal !== null) {
                holdWallNormals.push(normal);
            }
        }

        if (holdWallNormals.length === 0)
            throw Error(`None of the holds of ${this.name} could be projected on the wall mesh.`)

        const normal = VectorUtils.averageVectors3(holdWallNormals);
        this.viewingDirection = normal.multiplyScalar(-1).normalize();
        return this.viewingDirection.clone();
    }

    getHoldWallNormal(wallMesh, hold) {
        try {
            const n = hold.metadata.normal;
            const v = new THREE.Vector3(n[0], n[1], n[2]);

            // TODO: this is a literal war crime
            //  and will break when worker is fixed
            var axis = new THREE.Vector3( 1, 0, 0 );
            var angle = -Math.PI / 2;

            v.applyAxisAngle( axis, angle );

            return v;
        } catch (error) {

        }

        const center = hold.getCenter();
        let bestDistance = null;
        let bestNormal = new THREE.Vector3();
        for (let triangle of BufferGeometryUtils.triangleIterator(wallMesh)) {
            if (!triangle.containsPoint(center))
                continue;
            const centerProjection = new THREE.Vector3();
            triangle.closestPointToPoint(center, centerProjection);
            const distance = centerProjection.distanceToSquared(center);
            if (bestDistance === null || distance < bestDistance) {
                bestDistance = distance;
                triangle.getNormal(bestNormal);
            }
        }

        return bestDistance === null ? null : bestNormal;
    }
}

export {Wall, Hold, Route};