import ModalWindow from "../../common/ModalWindow";
import {useContext, useEffect, useRef, useState} from "react";
import BasicTextInput from "../../common/TextInput";
import {UserActions, UserContext, UserDispatchContext} from "../../contexts/UserContext";
import {backend, BackendResult} from "../../model/Backend";
import {BasicButton} from "../../common/Button";
import {UIColor} from "../../Config";
import SpinLoadingIndicator from "../../common/SpinLoadingIndicator";
import ErrorFromBackendResult from "../../common/ErrorFromBackendResult";
import {UiUtils} from "../../common/Utils";

export default function DeleteAccountModal({onClose, show}) {
    const userPassword = useRef(null);
    const userPasswordRepeat = useRef(null);

    const userDispatch = useContext(UserDispatchContext);

    const [errorData, setErrorData] = useState(null);

    const [modified, setModified] = useState(false);

    const user = useContext(UserContext);

    function changePasswords() {
        if (userPassword.current.value || userPasswordRepeat.current.value) {
            setModified(true);
        } else {
            setModified(false);
        }
    }

    async function onCloseWrapper() {
        if (modified && !await UiUtils.confirmAsync("Discard unsaved changes?"))
            return;

        onClose();
    }

    useEffect(() => {
        setErrorData(user.status.getError(UserActions.DeleteAccount))
    }, [user.status]);

    async function onSubmit(submitEvent) {
        submitEvent.preventDefault();

        if (userPassword.current.value !== userPasswordRepeat.current.value) {
            setErrorData(BackendResult.FromError("Passwords don't match."));
            return;
        }

        const deleteAccountData = {
            password: userPassword.current.value,
        };

        setErrorData(null);

        userDispatch({
            type: UserActions.DeleteAccount,
            data: deleteAccountData,
        });
    }

    return <ModalWindow
        title="Delete Account"
        handleClose={onCloseWrapper}
        topColor={UIColor.Red}
        show={show}
    >
        <form onSubmit={onSubmit}>
            <BasicTextInput ref={userPassword} type="password" name="password" required={true} label="Password"
                            autoComplete="new-password"
                            onChange={changePasswords}
            />
            <div className="mb-4"></div>
            <BasicTextInput ref={userPasswordRepeat} type="password" name="password_repeat" required={true}
                            label="Confirm Password" autoComplete="new-password"
                            onChange={changePasswords}
            />
            <div className="mb-8"></div>
            <p className={`${UIColor.MinorText} text-sm`}>
                This operation is <strong>irreversible</strong>.
                All of your data will be removed from our servers for ever and ever.
            </p>
            <div className="mb-2"></div>
            <BasicButton
                type="submit"
                buttonColor={UIColor.Red}
                className="w-full"
                disabled={!modified}
            >
                {user.status.isLoading(UserActions.DeleteAccount) ?
                    <SpinLoadingIndicator/> : <p>Delete Account</p>}
            </BasicButton>
            <ErrorFromBackendResult data={errorData}/>
        </form>
    </ModalWindow>;
}
