import {createContext, useState} from "react";
import {unimplementedFunction} from "../common/Utils";
import {backend, BackendErrorType, BackendResult} from "../model/Backend";
import {ObjectWithTracker} from "./ContextUtils";

const LeaderboardContext = createContext(null);
const LeaderboardDispatchContext = createContext(unimplementedFunction);

class LeaderboardActions {
    static Update = "update";
}

function useLeaderboardReducer() {
    const [leaderboard, setLeaderboard] = useState(new ObjectWithTracker(null));

    function leaderboardDispatch(...actionData) {
        for (const a of actionData) {
            const action = _createAction(leaderboard, a);
            action.execute(setLeaderboard);
        }
    }

    return [leaderboard, leaderboardDispatch];
}

// TODO: similar to usercontext...
class ActionWithSideEffects {
    constructor(backendPromise, trackingId, onSuccess, postSuccessHooks = null) {
        this.backendPromise = backendPromise;
        this.trackingId = trackingId;
        this.onSuccess = onSuccess;
        this.postSuccessHooks = postSuccessHooks;
    }

    execute(setLeaderboard) {
        const trackingId = this.trackingId;
        const onSuccess = this.onSuccess;
        const postSuccessHooks = this.postSuccessHooks;

        function modifyLeaderboardWithResult(l, actionResult) {
            let nl = l.clone();
            nl.status.stopLoading(trackingId);
            if (actionResult.isSuccess()) {
                let result = onSuccess(nl, actionResult);

                postSuccessHooks?.();

                if (result)
                    nl = result;
            } else {
                nl.status.setError(actionResult, trackingId);
            }
            return nl;
        }

        function onResponse(result) {
            setLeaderboard(l => modifyLeaderboardWithResult(l, result))
        }

        this.backendPromise
            .then(onResponse)
            .catch(error => {
                console.error(error);
                onResponse(BackendResult.FromError(BackendErrorType.GeneralError));
            });

        setLeaderboard(l => {
            let nl = l.clone();
            nl.status.deleteAllErrors();
            nl.status.startLoading(this.trackingId);
            return nl;
        });
    }
}

function _createAction(leaderboard, actionData) {
    if (actionData.type === LeaderboardActions.Update) {
        return new ActionWithSideEffects(
            backend.leaderboard(),
            actionData.trackingId ?? LeaderboardActions.Update,
            (leaderboard, result) => {
                leaderboard.value = result.data
            },
            actionData.postSuccessHooks
        );
    }
}

export {LeaderboardContext, LeaderboardDispatchContext, LeaderboardActions, useLeaderboardReducer};