export default function ProfilePicture({image, imageURL, pictureSize, onClick = null}) {
    return (
        <div className="w-fit border-2 border-neutral-500 rounded-full"
             onClick={onClick}
        >
            <div
                className={`${pictureSize} relative overflow-hidden rounded-full ${onClick !== null ? 'hover:opacity-50' : ''}`}>
                <img src={imageURL ?? URL.createObjectURL(image)} className="w-full h-full" alt="User Profile Picture"/>
            </div>
        </div>
    );
}