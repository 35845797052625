import {useEffect} from "react";
import {Raycasting, TopDownControls} from "./Controls";
import {RouteFocusState} from "../canvas/CanvasState";
import * as THREE from "three";
import OutlineType from "../canvas/OutlineType";

export default function TopDownController({canvas, wall, setCanvasState, canvasState}) {
    useEffect(() => {
        if (wall === null || canvas === null)
            return;

        function onControlsUpdate() {
            canvas.render();
        }

        function onRouteHover(raycastEvent) {
            const result = raycastEvent.result;
            if (result.hit) {
                const hitRoute = result.target.object;
                if (hitRoute === null) {
                    canvas.setOutlinedObjects(OutlineType.HIGHLIGHT, []);
                } else {
                    canvas.setOutlinedObjects(OutlineType.HIGHLIGHT, hitRoute.holds.map(hold => hold.holdMesh));
                }
            } else {
                canvas.setOutlinedObjects(OutlineType.HIGHLIGHT, []);
            }
            canvas.render();
        }

        function onRouteClick(raycastEvent) {
            const result = raycastEvent.result;
            console.assert(result.hit);
            const route = result.target.object;
            if (route === null)
                return;
            setCanvasState(new RouteFocusState(route));
        }

        const controls = new TopDownControls(canvas.getCanvasElement(), canvas.camera, canvas.cameraAnimation);
        // const controls = new TopDownControlsV2(canvas.getCanvasElement(), canvas.camera, canvas.cameraAnimation);
        controls.addEventListener("change", onControlsUpdate);
        let initialTarget = canvasState.initialTarget;
        let animate = true;
        let fixedCamera = false;

        if (initialTarget === null) {
            if (wall.customData && wall.customData["starting_orbit_target"]) {
                const [x, y, z] = wall.customData["starting_orbit_target"];
                initialTarget = new THREE.Vector3(x, y, z);
            }
            if (wall.customData && wall.customData["starting_position"]) {
                const [x, y, z] = wall.customData["starting_position"];
                canvas.camera.position.set(x, y, z);
                fixedCamera = true;
            }
            animate = false;
        }

        controls.register(initialTarget, animate, wall, fixedCamera);

        const raycasting = new Raycasting(canvas.getCanvasElement(), canvas.camera, true);
        raycasting.addEventListener("object_hover", onRouteHover);
        raycasting.addEventListener("object_approach", onRouteHover);
        raycasting.addEventListener("object_click", onRouteClick);
        raycasting.addEventListener("object_approach_click", onRouteClick);

        for (let i = 0; i < wall.routes.length; i++) {
            const otherRoute = wall.routes[i];
            const holds = otherRoute.holds;
            raycasting.addTarget(otherRoute, holds.map(hold => hold.holdMesh));
            raycasting.addApproachTarget(otherRoute, holds.map(hold => hold.holdMesh), holds.map(hold => new THREE.Sphere(hold.getCenter(), 0.5)));
        }

        raycasting.register();

        canvas.setOutlinedObjects(OutlineType.HIGHLIGHT, []);
        return () => {
            raycasting.unregister();
            controls.unregister();
        }
    }, [wall, canvas, setCanvasState]);
    useEffect(() => {
        canvas.render();
    });
}
