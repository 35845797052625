import {useCallback, useContext, useEffect, useState} from "react";

import {Raycasting, OrbitControls, TopDownControls} from "./Controls";
import ProposalPanel from "../proposal_edit/ProposalPanel";
import {WallActions, WallDispatchContext} from "../../contexts/WallContext";
import {HoldDialog, HoldDialogType} from "../proposal_edit/HoldDialog";
import {ProposalActions, ProposalContext, ProposalDispatchContext} from "../../contexts/ProposalContext";
import OutlineType from "../canvas/OutlineType";

export default function ProposalWalkthroughController({canvas}) {
    const [route, setRoute] = useState(null);
    const wallDispatch = useContext(WallDispatchContext);
    const [holdDialog, setHoldDialog] = useState(null);
    const proposal = useContext(ProposalContext);
    const proposalDispatch = useContext(ProposalDispatchContext);

    const onHoldRemovalFromRoute = useCallback(hold =>
        proposalDispatch({
            type: ProposalActions.RemoveHoldFromRoute,
            hold: hold,
        }), [proposalDispatch]);

    const onHoldAdditionToRoute = useCallback(hold =>
        proposalDispatch({
            type: ProposalActions.AddHoldToRoute,
            hold: hold,
            route: route,
        }), [proposalDispatch, route]);

    const onHoldHover = useCallback(raycastEvent => {
        // TODO: some highlighting
    }, []);

    const onHoldClick = useCallback(raycastEvent => {
        const result = raycastEvent.result;
        console.assert(result.hit);
        const hold = result.target.object;
        let dialogType, onConfirm;
        if (hold.route === route) {
            dialogType = HoldDialogType.HoldRemoval;
            onConfirm = () => {
                onHoldRemovalFromRoute(hold);
                setHoldDialog(null);
            };
        } else {
            dialogType = HoldDialogType.HoldAddition;
            onConfirm = () => {
                onHoldAdditionToRoute(hold);
                setHoldDialog(null);
            };
        }
        setHoldDialog({
            position: raycastEvent.screen_pointer,
            type: dialogType,
            onConfirm: onConfirm,
        });
    }, [onHoldAdditionToRoute, onHoldRemovalFromRoute, route]);

    useEffect(() => {

        function onControlsUpdate() {
            canvas.render();
        }

        let controls;
        if (route !== null) {
            controls = new OrbitControls(canvas.getCanvasElement(), canvas.camera, canvas.cameraAnimation);
            controls.register(route);
        } else {
            controls = new TopDownControls(canvas.getCanvasElement(), canvas.camera, canvas.cameraAnimation);
            controls.register();
        }
        controls.addEventListener("change", onControlsUpdate);

        return () => {
            controls.removeEventListener("change", onControlsUpdate);
            controls.unregister();
        };
    }, [canvas, route]);

    useEffect(() => {
        if (proposal === null)
            return;

        const raycasting = new Raycasting(canvas.getCanvasElement(), canvas.camera);
        for (let hold of proposal.newWall.holds.values()) {
            raycasting.addTarget(hold, [hold.holdMesh]);
        }
        raycasting.addEventListener("object_hover", onHoldHover);
        raycasting.addEventListener("object_click", onHoldClick);
        raycasting.register();
        return () => {
            raycasting.removeEventListener("object_hover", onHoldHover);
            raycasting.removeEventListener("object_click", onHoldClick);
            raycasting.reset();
            raycasting.unregister();
        };
    }, [canvas, onHoldClick, onHoldHover, proposal]);

    useEffect(() => {
        canvas.render();
    });
    useEffect(() => {
        if (route !== null) {
            canvas.setOutlinedObjects(OutlineType.HIGHLIGHT, route.holds.map(hold => hold.holdMesh));
        }
    },[canvas, route]);

    function onWallDataUpdate(attribute, newValue) {
        wallDispatch({
            type: WallActions.SetMetadataAttribute,
            attribute: attribute,
            value: newValue,
        });
        // TODO: store on backend
    }

    return (<>
        <ProposalPanel
            proposal={proposal}
            route={route}
            setRoute={setRoute}
            onWallDataUpdate={onWallDataUpdate}
        />
        {holdDialog !== null &&
            <HoldDialog
                position={holdDialog.position}
                type={holdDialog.type}
                onConfirm={holdDialog.onConfirm}
                onCancel={() => setHoldDialog(null)}
            />
        }
    </>);
}