import {Route} from "../../model/Wall";
import {faBookmark, faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {faBookmark as farBookmark} from "@fortawesome/free-regular-svg-icons";
import {useContext, useState} from "react";
import {UserContext} from "../../contexts/UserContext";
import {RouteStateType, UserActivityType} from "../../model/User";
import {DateUtils} from "../../common/Utils";
import ModifyActivitiesModal from "../../side_panel/account/ModifyActivitiesModal";
import ClickableFontAwesomeIcon from "../../common/ClickableFontAwesomeIcon";

export default function RouteInfoList({route, isBookmarkDisabled, onBookmarkClick, bookmarked}) {
    const labelStyles = "py-2 pr-6 font-semibold text-lg text-right";
    const valueStyles = "py-2";
    const color = Route.getColorHexString(route);
    const [showModifyActivity, setShowModifyActivity] = useState(null);

    const user = useContext(UserContext);

    let activity = null;
    if (user.value !== null) {
        let state = user.value.getRouteClimbingState(route);

        if (state === RouteStateType.Climbed || state === RouteStateType.Flashed)
            activity = user.value.getActivity(state, route);
    }

    return (
        <>
            <div id="route-info-list" className="bg-gray-700 px-6 py-4 rounded-lg">
                <table>
                    <tbody>
                    <tr>
                        <td className={labelStyles}><p>Date</p></td>
                        <td className={valueStyles}><p>{Route.getCreationDateString(route)}</p></td>
                    </tr>
                    {
                        activity !== null ? <tr>
                            <td className={labelStyles}>
                                <p>{UserActivityType.Name(activity.type)}</p></td>
                            <td className={valueStyles}>
                                <p>{DateUtils.toFormalDay(activity.timestamp)}
                                    <ClickableFontAwesomeIcon
                                        icon={faPenToSquare}
                                        className="text-xl pl-3 cursor-pointer"
                                        onClick={() => setShowModifyActivity(true)}
                                    />
                                </p>
                            </td>
                        </tr> : ""
                    }
                    {
                        route.builder ? <tr>
                            <td className={labelStyles}><p>Setter</p></td>
                            <td className={valueStyles}><p>{route.builder}</p></td>
                        </tr> : ""
                    }
                    {
                        color ? <tr>
                            <td className={labelStyles}><p>Color</p></td>
                            <td className={`${valueStyles} flex items-center space-x-2`}>
                                <div
                                    className="h-6 w-6 rounded-full"
                                    style={{backgroundColor: color}}
                                ></div>
                            </td>
                        </tr> : ""
                    }
                    <tr>
                        <td className={labelStyles + (isBookmarkDisabled ? "" : " cursor-pointer")}
                            onClick={onBookmarkClick}>
                            <p>Bookmark</p>
                        </td>
                        <td className={valueStyles}>
                            <p>
                                <ClickableFontAwesomeIcon
                                    icon={bookmarked ? faBookmark : farBookmark}
                                    className={`${isBookmarkDisabled ? "" : " cursor-pointer"} text-xl`}
                                    onClick={onBookmarkClick}
                                />
                            </p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            {
                activity !== null ?
                    <ModifyActivitiesModal
                        activities={[activity]}
                        onClose={() => setShowModifyActivity(false)}
                        show={showModifyActivity}
                    /> : ""
            }
        </>
    );
}
