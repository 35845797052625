import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDiscord, faInstagram, faXTwitter} from "@fortawesome/free-brands-svg-icons"
import {useState} from "react";
import ModalWindow from "../../common/ModalWindow";
import {UIColor} from "../../Config";

export default function SideInfo() {
    const [privacyPolicy, setPrivacyPolicy] = useState(false);

    // TODO: privacy policy
    // <p className="text-neutral-400 cursor-pointer" onClick={() => setPrivacyPolicy(true)}>Privacy Policy</p>
    // {privacyPolicy && <ModalWindow
    //     title="Privacy Policy"
    //     handleClose={() => setPrivacyPolicy(false)}
    // >
    // </ModalWindow>}

    return (
        <>
            <h2 className="text-4xl text-center">About</h2>
            <div className="mb-6"></div>

            <div className="text-justify hyphens-auto">
                <p className="text-lg">Welcome to <strong>Climbuddy!</strong></p>
                <div className="mb-3"></div>
                <p>Our aim is to create the climbing app of the future by utilizing computer vision, machine learning and
                    our love for the sport.</p>
                <div className="mb-3"></div>
                <p>You are currently in the preview version of the app, which means that it is subject to
                    frequent (often breaking) changes as we get closer to a stable release.</p>
                <div className="mb-5"></div>

                <div className="flex flex-wrap items-center justify-center">
                    <div className="flex space-x-3 p-2">
                        <a href="https://discord.gg/azjDzZu7sV" className="text-3xl"><FontAwesomeIcon
                            icon={faDiscord}></FontAwesomeIcon></a>

                        <a href="https://www.instagram.com/Climbuddy/" className="text-3xl"><FontAwesomeIcon
                            icon={faInstagram}></FontAwesomeIcon></a>

                        <a href="https://twitter.com/Climbuddy" className="text-3xl"><FontAwesomeIcon
                            icon={faXTwitter}></FontAwesomeIcon></a>
                    </div>

                    <div className="flex flex-col justify-center p-2">
                        <a href="https://blog.climbuddy.com"
                           className="text-blue-500 hover:text-blue-700 hover:underline">blog.climbuddy.com</a>
                        <p className={`${UIColor.MinorText} text-sm`}>for updates & articles.</p>
                    </div>
                </div>
                <div className="mb-5"></div>
                <p className={`${UIColor.MinorText} text-center`}>Version <code className="ml-1">0.3</code></p>
            </div>
        </>
    )
}