import * as THREE from "three";
import { Canvg } from 'canvg';
import HoldIcon from "./HoldIcon";
import ReactDOMServer from 'react-dom/server';

class BufferGeometryUtils {
    static triangleIterator(mesh) {
        function* _generator() {
            mesh = BufferGeometryUtils.getLeaf(mesh);
            const geometry = mesh.geometry;
            const positions = geometry.getAttribute("position");
            console.assert(positions.itemSize === 3);
            if (geometry.index) {
                const indices = geometry.index;
                console.assert(indices.count % 3 === 0);
                for (let triangleIdx = 0; triangleIdx < indices.count; triangleIdx += 3) {
                    const triangle = new THREE.Triangle(
                        new THREE.Vector3().fromBufferAttribute(positions, indices.array[triangleIdx]),
                        new THREE.Vector3().fromBufferAttribute(positions, indices.array[triangleIdx + 1]),
                        new THREE.Vector3().fromBufferAttribute(positions, indices.array[triangleIdx + 2]),
                    );
                    mesh.localToWorld(triangle.a);
                    mesh.localToWorld(triangle.b);
                    mesh.localToWorld(triangle.c);
                    yield triangle;
                }
            } else {
                console.assert(positions.count % 3 === 0);
                for (let triangleIdx = 0; triangleIdx < positions.count; triangleIdx += 3) {
                    const triangle = new THREE.Triangle().setFromAttributeAndIndices(
                        positions, triangleIdx, triangleIdx + 1, triangleIdx + 2
                    )
                    mesh.localToWorld(triangle.a);
                    mesh.localToWorld(triangle.b);
                    mesh.localToWorld(triangle.c);
                    yield triangle;
                }
            }
        }

        return _generator();
    }

    static meshCenter(mesh) {
        const geometry = mesh.geometry;
        geometry.computeBoundingBox();
        const center = new THREE.Vector3();
        geometry.boundingBox.getCenter(center);
        // TODO: sometimes this needs to be here and sometimes it needs to not be here. What's going on?
        mesh.localToWorld(center);
        return center;
    }

    static getLeaf(mesh) {
        while (mesh.children && mesh.children.length === 1) {
            mesh = mesh.children[0];
        }
        return mesh;
    }

    static translateVertices(mesh, offset) {
        const positions = mesh.geometry.getAttribute("position");
        console.assert(positions.itemSize === 3);
        for (let i = 0; i < positions.count; i++) {
            positions.setXYZ(
                i,
                positions.getX(i) - offset.x,
                positions.getY(i) - offset.y,
                positions.getZ(i) - offset.z,
            )
        }
        positions.needsUpdate = true;
        mesh.geometry.computeBoundingBox();
        mesh.geometry.computeBoundingSphere();
        // TODO: add, not copy
        mesh.position.copy(offset);
        mesh.updateMatrixWorld();
    }
}

class VectorUtils {
    static averageVectors3(vectors) {
        const average = new THREE.Vector3();
        for (let vector of vectors) {
            average.add(vector);
        }
        average.divideScalar(vectors.length);
        return average;
    }
}

class NumberUtils {
    static weightedAverage(values, weights) {
        console.assert(values.length === weights.length);
        let sum = 0;
        let weightsSum = 0;
        for (let i = 0; i < values.length; i++) {
            sum += values[i] * weights[i];
            weightsSum += weights[i];
        }
        return sum / weightsSum;
    }
}

class CameraUtils {
    static getCameraFrustum(camera) {
        camera.updateMatrix();
        camera.updateMatrixWorld();

        const frustum = new THREE.Frustum();
        frustum.setFromProjectionMatrix(
            new THREE.Matrix4().multiplyMatrices(
                camera.projectionMatrix,
                camera.matrixWorld.clone().invert()
            )
        );
        return frustum;
    }

    // TODO: this does not belong here lmao
    static nearestNeighborTSP(points) {
        const n = points.length;
        if (n === 0) return {tour: [], distance: 0};

        let bestTour = [];
        let bestDistance = Infinity;

        for (let start = 0; start < n; start++) {
            const visited = new Array(n).fill(false);
            const tour = [];
            let totalDistance = 0;

            let currentIndex = start;
            visited[currentIndex] = true;
            tour.push(currentIndex);

            for (let i = 1; i < n; i++) {
                let nearestDistance = Infinity;
                let nearestIndex = -1;

                for (let j = 0; j < n; j++) {
                    if (!visited[j]) {
                        const distance = points[currentIndex].distanceTo(points[j]);
                        if (distance < nearestDistance) {
                            nearestDistance = distance;
                            nearestIndex = j;
                        }
                    }
                }

                visited[nearestIndex] = true;
                tour.push(nearestIndex);
                totalDistance += nearestDistance;
                currentIndex = nearestIndex;
            }

            // Returning to the start point to complete the tour
            totalDistance += points[currentIndex].distanceTo(points[start]);

            // Don't actually return the start, though
            // tour.push(start);

            if (totalDistance < bestDistance) {
                bestDistance = totalDistance;
                bestTour = tour.slice(); // Create a copy of the current tour
            }
        }

        return {tour: bestTour, distance: bestDistance};
    }
}

class UiUtils {
    static consumeMouseEvents(...elements) {
        for (let element of elements) {
            element.addEventListener("click", (event) => event.stopPropagation());
            element.addEventListener("dblclick", (event) => event.stopPropagation());
            element.addEventListener("mousemove", (event) => event.stopPropagation());
        }
    }

    static confirmAsync(msg) {
        return new Promise((resolve, reject) => {
            let confirmed = window.confirm(msg);
            return resolve(confirmed);
        });
    }
}

class StringUtils {
    // From https://stackoverflow.com/questions/105034/how-do-i-create-a-guid-uuid.
    static generateId() {
        return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    static isValidUuid(s) {
        return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(s);
    }
}

class FilesUtils {
    static getExtension(filename) {
        const parts = filename.split(".");
        if (parts.length === 1 || (parts[0] === "" && parts.length === 2)) {
            return "";
        }
        return "." + parts.pop();
    }
}

class CookieUtils {
    static setCookie(name, value) {
        document.cookie = name + "=" + value + "; Path=/;";
    }

    static deleteCookie(name) {
        document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }
}

class DateUtils {
    static isSameDay(date1, date2) {
        return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    }


    /**
     * 3. 2. 2024
     */
    static toFormalDay(date) {
        return `${date.getDate()}. ${date.getMonth() + 1}. ${date.getFullYear()}`
    }

    /**
     * Wed, May 29, 2024
     */
    static toPrettyDay(date) {
        return date.toLocaleDateString("en-US", {
            weekday: "short", year: "numeric", month: "long", day: "numeric"
        });
    }

    static stringToDate(string, date = null) {
        try {
            const [year, month, day] = string.split('-').map(Number);

            if (date)
                return new Date(
                    year, month - 1, day,
                    date.getHours(), date.getMinutes(), date.getSeconds()
                );
            else
                return new Date(year, month - 1, day);
        } catch (e) {
            return null;
        }
    }
}


class AssertUtils {
    static assertDefined(...args) {
        for (let i = 0; i < args.length; i++) {
            console.assert(args[i] !== undefined, `${i + 1}th argument was undefined.`);
        }
    }
}

class SpecialStates {
    static ToBeLoaded = new SpecialStates("to_be_loaded");
    static Loading = new SpecialStates("loading");
    static Empty = new SpecialStates("empty");

    static CreateErrorState(error) {
        return new SpecialStates.ErrorState(error);
    }

    constructor(tag) {
        this.tag = tag;
    }

    toString() {
        return `[${this.tag}]`;
    }

    static ErrorState = class ErrorState {
        constructor(error) {
            this.error = error;
        }
    }
}

class ImageUtils {
    static defaultImage(size) {
        size ??= 16;

        const canvas = document.createElement('canvas');
        canvas.width = size;
        canvas.height = size;

        const context = canvas.getContext('2d');
        context.fillStyle = 'white';
        context.fillRect(0, 0, size, size);

        const dataURL = canvas.toDataURL("image/webp");

        // Convert dataURL to Blob
        const byteString = atob(dataURL.split(',')[1]);
        const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];

        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], {type: mimeString});
    }

    static async getRandomHoldImage(size) {
        size ??= 256

        // Create an offscreen canvas
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        canvas.width = size;
        canvas.height = size;

        // Read the SVG blob as text
        const svgText = new HoldIcon().generateRandomHold();

        // Use Canvg to render the SVG on the canvas
        const v = await Canvg.fromString(context, svgText);
        await v.render();

        // Convert the canvas to a WebP blob
        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                resolve(blob);
            }, 'image/webp');
        });
    }

    static async base64ToBlob(base64, contentType) {
        return new Promise((resolve, reject) => {
            try {
                const byteCharacters = atob(base64);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: contentType });
                resolve(blob);
            } catch (error) {
                reject(error);
            }
        });
    }

    static async blobToBase64(blob) {
        return new Promise((resolve, _) => {
            var reader = new FileReader();
            reader.onload = function() {
                var dataUrl = reader.result;
                resolve(dataUrl.split(',')[1]);
            };
            reader.readAsDataURL(blob);
        });
    }

    static async resizeImage(file, size) {
        size ??= 256

        let bitmap;
        if (typeof file === 'string' && file.startsWith('data:image')) {
            // Input is a base-64 encoded image
            const base64Response = await fetch(file);
            const blob = await base64Response.blob();
            bitmap = await createImageBitmap(blob);
        } else if (file instanceof File || file instanceof Blob) {
            bitmap = await createImageBitmap(file);
        } else {
            throw new Error('Input must be a base-64 encoded image string or a File object');
        }

        const {width, height} = bitmap

        const ratio = Math.max(size / width, size / height)

        const x = (size - (width * ratio)) / 2
        const y = (size - (height * ratio)) / 2

        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        canvas.width = size
        canvas.height = size

        ctx.drawImage(bitmap, 0, 0, width, height, x, y, width * ratio, height * ratio)

        return new Promise(resolve => {
            canvas.toBlob(blob => {
                resolve(blob)
            }, 'image/webp', 1)
        })
    }
}

function unimplementedFunction(...args) {
    throw new Error("Unimplemented function - was probably left as a default value although it should have been overwritten.");
}

export {
    BufferGeometryUtils,
    VectorUtils,
    NumberUtils,
    CameraUtils,
    UiUtils,
    StringUtils,
    FilesUtils,
    CookieUtils,
    DateUtils,
    AssertUtils,
    SpecialStates,
    ImageUtils,
    unimplementedFunction,
};