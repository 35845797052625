import "./EditableDropdown.css"
import React, {useState, useRef} from "react";
import {faCheck} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BasicTextInput from "./TextInput";

class SpecialValues {
    static AddNew = "add-new-value";
    static Empty = "empty-value";
}

export default function EditableDropdown({items, setItems, item, setItem, label}) {
    const [isEditing, setIsEditing] = useState(false);
    const inputRef = useRef(null);

    function handleSelection(event) {
        const value = event.target.value;
        if (value === SpecialValues.AddNew) {
            setIsEditing(true);
            setTimeout(() => inputRef.current.focus(), 0);
        } else {
            setIsEditing(false);
            setItem(value);
        }
    }

    function handleNewItemEdit(event) {
        if (event.key === "Enter") {
            handleNewItem();
        }
    }

    function handleNewItem() {
        const value = inputRef.current.value.trim();
        if (value === SpecialValues.AddNew || value === SpecialValues.Empty)
            return;
        if (!value) {
            setItem(null);
            setIsEditing(false);
            return;
        }
        if (!items.includes(value)) {
            setItems([value, ...items])
        }
        setItem(value);
        setIsEditing(false);
    }

    const selectedItem = item === null ? SpecialValues.Empty : item;
    const styles = "w-full py-1.5 px-4 bg-gray-700 rounded text-gray-200 border border-gray-600 text-lg";
    return (
        <div>
            {label &&
                <p className="mb-0.5 font-bold text-lg">{label}</p>
            }
            <div className="dropdown-container">
                <select
                    value={isEditing ? "Add new ..." : selectedItem}
                    onChange={handleSelection}
                    className={isEditing ? `editing ${styles}` : styles}
                >
                    <option value={SpecialValues.Empty}>&lt;empty&gt;</option>
                    {items.map(item => (
                        <option key={item} value={item}>
                            {item}
                        </option>
                    ))}
                    <option value={SpecialValues.AddNew}>Add new...</option>
                </select>
                {isEditing && (
                    <BasicTextInput
                        ref={inputRef}
                        type="text"
                        className="overlay-input"
                        onBlur={(event) => {
                            if (!event.target.value) {
                                setIsEditing(false);
                            }
                        }}
                        onKeyDown={handleNewItemEdit}
                    />
                )}
                {isEditing && (
                    <div className="checkmark" onClick={handleNewItem}>
                        <FontAwesomeIcon icon={faCheck}/>
                    </div>
                )}
            </div>
        </div>
    );
};