import {useContext, useEffect, useState} from "react";
import ModalWindow from "../../common/ModalWindow";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowUpWideShort,
    faBolt,
    faCrown,
    faEllipsisV,
    faMountain,
    faRotateRight,
    faUserSecret
} from "@fortawesome/free-solid-svg-icons";
import {LeaderboardActions, LeaderboardContext, LeaderboardDispatchContext} from "../../contexts/LeaderboardContext";
import SpinLoadingIndicator from "../../common/SpinLoadingIndicator";
import ErrorFromBackendResult from "../../common/ErrorFromBackendResult";
import ProgressGrid from "./ProgressGrid";
import {UiActions, UiDispatchContext} from "../../contexts/UiContext";
import {RouteStateType} from "../../model/User";
import ProfilePicture from "./ProfilePicture";
import {UserContext} from "../../contexts/UserContext";
import {WallContext} from "../../contexts/WallContext";
import ClickableFontAwesomeIcon from "../../common/ClickableFontAwesomeIcon";
import {UIColor} from "../../Config";

function LeaderboardUser(
    {
        rank,
        score,
        photoURL,
        name,
        current,
        textSize = "",
        pictureSize = "",
        setCurrentUserClicked
    }
) {
    let nameStyle = current ? 'font-bold' : '';

    let rankStyle = (rank <= 3 ? "" : UIColor.MinorText) + " w-6";

    let color = current ? "border-4 border-slate-300 bg-slate-500 hover:bg-slate-600 hover:border-slate-500" : "bg-slate-700";

    const user = useContext(UserContext);

    // when we have a user and current, replace photo and name (in case we change it locally)
    if (user.value && current) {
        photoURL = user.value.photoURL;
        name = user.value.nickname;
    }

    return (
        <div className={`${textSize} flex items-center ${current ? 'cursor-pointer' : ''}`}
             onClick={
                 () => {
                     if (current)
                         setCurrentUserClicked();
                 }
             }
        >
            <div className="text-right">
                {(rank !== 1) ? <p className={rankStyle}>{rank}.</p> : <FontAwesomeIcon icon={faCrown}/>}
            </div>
            <div className={`flex grow min-w-0 items-center gap-1.5 rounded-xl ml-2 px-2 py-1 ${color}`}>
                <ProfilePicture pictureSize={pictureSize} imageURL={photoURL}/>
                <div className="min-w-0 flex-1">
                    <p className={`${nameStyle} truncate`}>
                        {name ?? <FontAwesomeIcon icon={faUserSecret}/>}
                    </p>
                </div>
                <p className={nameStyle}><code>{score.toFixed(0)}</code></p>
            </div>
        </div>
    );
}

function Leaderboard({leaderboard, onCurrentClick}) {
    const getPictureSize = (rank) => {
        if (rank === 1) return "w-7 h-7";
        if (rank === 2 || rank === 3) return "w-6 h-6";
        return "w-5 h-5";
    };

    const getTextSize = (rank) => {
        if (rank === 1) return 'text-[135%]';
        if (rank === 2) return 'text-[125%]';
        if (rank === 3) return 'text-[115%]';
        return 'text-[100%]';
    };

    let leaderboardList = [];
    if (leaderboard !== null) {
        let lastEntry = null;
        for (let entry of leaderboard.sortedEntries()) {
            // Spacer dots if there are spaces in the rankings
            if (lastEntry !== null && Math.abs(entry.rank - lastEntry.rank) > 1) {
                leaderboardList.push(
                    <div className={`m-1 text-center ${UIColor.MinorText}`}>
                        <FontAwesomeIcon icon={faEllipsisV} className="pl-6"/>
                    </div>
                )
            }

            leaderboardList.push(
                <>
                    <LeaderboardUser
                        rank={entry.rank}
                        score={entry.rating}
                        photoURL={entry.photoURL}
                        name={entry.nickname}
                        current={entry.current}
                        setCurrentUserClicked={onCurrentClick}
                        pictureSize={getPictureSize(entry.rank)}
                        textSize={getTextSize(entry.rank)}/>
                    <div className="pb-1.5"></div>
                </>
            )

            lastEntry = entry;
        }
    }

    return <>
        {leaderboardList}
    </>;
}

function ScoreBreakdownModal({onClose, ...props}) {
    const [sorted, setSorted] = useState(false);

    const uiStateDispatch = useContext(UiDispatchContext);

    const wall = useContext(WallContext);
    const user = useContext(UserContext);

    if (user.value === null)
        return <></>;

    let points = user.value.getRoutesPoints(wall.routes);

    return <ModalWindow title="Your Rating" handleClose={onClose} {...props}>
        <ClickableFontAwesomeIcon
            icon={faArrowUpWideShort}
            className="absolute right-6 text-xl"
            onClick={() => setSorted(!sorted)}
            isClicked={sorted}
        />
        <p> You have obtained <strong><code>{points.toFixed(0)}</code></strong> points.</p>
        <div className="mb-4"></div>
        <ProgressGrid usePoints={true} sortPoints={sorted} onCellClick={
            () => {
                // clicking on cells closes the modal, side panel and focuses on the route
                uiStateDispatch({type: UiActions.CloseSidePanel});
                onClose()
            }}
        />
    </ModalWindow>;
}


function ScoreExplanationModal({onClose, ...props}) {
    return <ModalWindow title="Point System" handleClose={onClose} justify={true} {...props}>
        <p>
            For each successfully climbed boulder, the user gains <strong><code>100</code> points,
            divided</strong> by the <strong>number of climbers who sent the boulder.</strong>
        </p>
        <div className="mb-4"></div>
        <p>
            As an example, if you <span
            className={RouteStateType.Color(RouteStateType.Climbed) + ' py-0.5 px-1 rounded font-bold'}><FontAwesomeIcon
            icon={faMountain} className="pr-1"/>climb</span> a boulder that has now
            been climbed by 6 people in total (you + 5 others), you
            gain <div className="mt-2 text-center"><code>100 / 6 = <strong>16.6</strong></code> points.
        </div>
            <div className="mb-4"></div>
        </p>
        <p>
            Additionally, the points of other people who sent this boulder <strong>are updated</strong> to
            the new value, meaning that <em>it only matters how many people send a particular boulder, not
            when.</em>
        </p>
        <div className="mb-4"></div>
        <p>
            If you manage to <span
            className={RouteStateType.Color(RouteStateType.Flashed) + ' py-0.5 px-1 rounded font-bold'}><FontAwesomeIcon
            icon={faBolt} className="pr-1"/>flash</span> a boulder, the amount of points you gain from it
            is <strong>multiplied by <code>1.5</code></strong> – for the example above, you will gain <div
            className="mt-2 text-center"><code>16.6 * 1.5 = <strong>25</strong></code> points.</div>
        </p>
    </ModalWindow>;
}

export default function SideLeaderboard() {
    const [scoreExplanationOpen, setScoreExplanationOpen] = useState(false);
    const [scoreBreakdownOpen, setScoreBreakdownOpen] = useState(false);

    const user = useContext(UserContext);

    const leaderboard = useContext(LeaderboardContext);
    const leaderboardDispatch = useContext(LeaderboardDispatchContext);

    useEffect(() => {
        leaderboardDispatch(
            {
                type: LeaderboardActions.Update,
                trackingId: "doNotTrack",
            }
        )
    }, [user]);

    let explanation = <>
        <div className="mb-6"></div>
        <div className={`text-center ${UIColor.MinorText}`}><p>How are these calculated? <a
            onClick={() => setScoreExplanationOpen(true)}
            className="text-blue-500 hover:text-blue-700 hover:underline font-bold cursor-pointer">Details.</a>
        </p></div>
    </>;

    return (
        <>
            <div className="absolute right-6 text-xl">
                {leaderboard.status.isLoading(LeaderboardActions.Update) ?
                    <SpinLoadingIndicator/>
                    : <ClickableFontAwesomeIcon
                        icon={faRotateRight}
                        className="p-1"
                        onClick={() => leaderboardDispatch({type: LeaderboardActions.Update})}
                    />
                }
            </div>
            <h2 className="text-4xl text-center">Rankings</h2>
            <ErrorFromBackendResult data={leaderboard.status.getError(LeaderboardActions.Update)}/>
            <div className="mb-6"></div>

            {(leaderboard.value !== null) ?
                <>
                    <Leaderboard leaderboard={leaderboard.value} onCurrentClick={() => setScoreBreakdownOpen(true)}/>
                    {explanation}
                </> : ''}

            <ScoreBreakdownModal onClose={() => setScoreBreakdownOpen(false)} show={scoreBreakdownOpen}/>
            <ScoreExplanationModal onClose={() => setScoreExplanationOpen(false)} show={scoreExplanationOpen}/>
        </>
    )
}