import {useContext, useEffect, useState} from "react";

import THREECanvas from "./ThreeCanvas";
import TopDownController from "../controls/TopDownController";
import RouteFocusController from "../controls/RouteFocusController";
import {ProposalReviewState, RouteFocusState, TopDownState} from "./CanvasState";
import ProposalWalkthroughController from "../controls/ProposalWalkthoughController";
import {WallContext} from "../../contexts/WallContext";
import {ProposalContext} from "../../contexts/ProposalContext";
import {CanvasContext, CanvasStateContext, SetCanvasContext, SetCanvasStateContext} from "../../contexts/CanvasContext";
import {AppContext} from "../../AppContext";
import OutlineType from "./OutlineType";
import {UserContext} from "../../contexts/UserContext";
import {RouteStateType} from "../../model/User";
import {SetReadyContext} from "../../contexts/ReadyContext";


export default function Canvas() {
    const wall = useContext(WallContext);
    const canvasState = useContext(CanvasStateContext);
    const setCanvasState = useContext(SetCanvasStateContext);

    const canvas = useContext(CanvasContext);
    const setCanvas = useContext(SetCanvasContext);

    const setReady = useContext(SetReadyContext);

    const proposal = useContext(ProposalContext);

    const user = useContext(UserContext);

    useEffect(() => {
        const newCanvas = new THREECanvas();
        newCanvas.enableOutline(OutlineType.HIGHLIGHT, OutlineType.HIGHLIGHT.params);
        setCanvas(newCanvas);

        function setCanvasDragging() {
            AppContext.Mutable.isCanvasDragging = true;
        }

        function unsetCanvasDragging() {
            AppContext.Mutable.isCanvasDragging = false;
        }

        function consumeIfDragging(e) {
            if (AppContext.Mutable.isCanvasDragging) {
                e.preventDefault();
            }
        }

        newCanvas.getCanvasElement().addEventListener("mousedown", setCanvasDragging);
        document.addEventListener("mouseup", unsetCanvasDragging);
        document.addEventListener("mousemove", consumeIfDragging);
        return () => {
            newCanvas.getCanvasElement().removeEventListener("mousedown", setCanvasDragging);
            document.removeEventListener("mouseup", unsetCanvasDragging);
            document.removeEventListener("mousemove", consumeIfDragging);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (canvas === null || wall === null)
            return;
        if (canvasState === null) {
            setCanvasState(new TopDownState());
        }
    }, [canvas, wall, canvasState, setCanvasState]);
    useEffect(() => {
        if (canvas === null || wall === null || canvasState === null)
            return;
        if (canvasState instanceof ProposalReviewState && proposal !== null) {
            canvas.setWall(proposal.newWall);
        } else {
            console.assert(canvasState instanceof TopDownState || canvasState instanceof RouteFocusState);
            // canvas.enableOutline(OutlineType.WALL_BORDER, OutlineType.WALL_BORDER.params);
            // canvas.setOutlinedObjects(OutlineType.WALL_BORDER, [wall.wallMesh, wall.additionalMesh]);
            canvas.setWall(wall);

            setReady(true);
        }
    }, [canvas, canvasState, proposal, wall]);
    useEffect(() => {
        if (canvas === null || wall === null || proposal === null)
            return;
        if (proposal.beingReviewed && !(canvasState instanceof ProposalReviewState)) {
            setCanvasState(new ProposalReviewState())
        }
        if (!proposal.beingReviewed && canvasState instanceof ProposalReviewState) {
            setCanvasState(new TopDownState());
        }
    }, [canvas, wall, proposal, canvasState, setCanvasState]);
    useEffect(() => {
        if (canvas === null || wall === null)
            return;
        if (user.value !== null) {
            if (!canvas.isOutlineEnabled(OutlineType.ATTEMPTED_ROUTE) || !canvas.isOutlineEnabled(OutlineType.CLIMBED_ROUTE)) {
                canvas.enableOutline(OutlineType.ATTEMPTED_ROUTE, OutlineType.ATTEMPTED_ROUTE.params);
                canvas.enableOutline(OutlineType.CLIMBED_ROUTE, OutlineType.CLIMBED_ROUTE.params);
            }

            // TODO: maybe do this asynchronously?
            const routes = wall.routes;
            const routeStates = user.value.getRoutesClimbingStates(routes);
            canvas.setOutlinedObjects(
                OutlineType.ATTEMPTED_ROUTE,
                routes
                    .filter((_, idx) => routeStates[idx] === RouteStateType.Attempted)
                    .flatMap(route => route.holds.map(hold => hold.holdMesh))
            );
            canvas.setOutlinedObjects(
                OutlineType.CLIMBED_ROUTE,
                routes
                    .filter((_, idx) => routeStates[idx] === RouteStateType.Climbed || routeStates[idx] === RouteStateType.Flashed)
                    .flatMap(route => route.holds.map(hold => hold.holdMesh))
            );
        } else {
            if (canvas.isOutlineEnabled(OutlineType.ATTEMPTED_ROUTE) || canvas.isOutlineEnabled(OutlineType.CLIMBED_ROUTE)) {
                canvas.disableOutline(OutlineType.ATTEMPTED_ROUTE);
                canvas.disableOutline(OutlineType.CLIMBED_ROUTE);
            }
        }
        canvas.render();
    }, [canvas, user, wall])

    const styles = (canvasState instanceof ProposalReviewState ? "border-4 border-red-700" : "") + "fixed top-0 left-0 touch-none";

    return (
        <>
            <canvas id="model-canvas" className={styles} onContextMenu={e => e.preventDefault()}></canvas>
            {
                canvas !== null && canvasState !== null &&
                <>
                    {canvasState instanceof TopDownState &&
                        <TopDownController
                            canvas={canvas} wall={wall} setCanvasState={setCanvasState} canvasState={canvasState}
                        />
                    }
                    {canvasState instanceof RouteFocusState &&
                        <RouteFocusController
                            canvas={canvas} setSceneState={setCanvasState} canvasState={canvasState}
                        />
                    }
                    {canvasState instanceof ProposalReviewState &&
                        <ProposalWalkthroughController
                            canvas={canvas}
                        />
                    }
                </>
            }
        </>
    );
}
