import ModalWindow from "../../common/ModalWindow";
import {BasicButton} from "../../common/Button";
import {useContext, useState} from "react";
import BasicDateInput from "../../common/DateInput";
import {DateUtils} from "../../common/Utils";
import {UserActions, UserContext, UserDispatchContext} from "../../contexts/UserContext";
import {UIColor} from "../../Config";
import SpinLoadingIndicator from "../../common/SpinLoadingIndicator";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear, faTrash} from "@fortawesome/free-solid-svg-icons";
import ErrorFromBackendResult from "../../common/ErrorFromBackendResult";

export default function ModifyActivitiesModal({activities, onClose, ...props}) {
    const userDispatch = useContext(UserDispatchContext);
    const user = useContext(UserContext);

    const [newDateString, setNewDateStringString] = useState(null);

    const [submittedType, setSubmittedType] = useState(null);

    if (activities === null)
        return <></>;

    const isSaving = user.status.isLoading(UserActions.ModifyActivities)
        || user.status.isLoading(UserActions.RemoveActivities)
        || user.status.isLoading();

    const error = user?.status.getError(UserActions.ModifyActivities)
        ?? user?.status.getError(UserActions.RemoveActivities)
        ?? user?.status.getError();

    function onSubmit(submitEvent) {
        if (user === null) {
            console.error("Modifying activity when user is null!")
            return;
        }

        submitEvent.preventDefault();

        let newDate = DateUtils.stringToDate(newDateString);

        if (newDate === null) {
            onClose();
            return;
        }

        let modifiedActivities = [];

        for (let i = 0; i < activities.length; i++) {
            let activity = activities[i];

            let modifiedActivity = activity.clone();
            modifiedActivity.timestamp = DateUtils.stringToDate(newDateString, activity.timestamp);

            modifiedActivities.push(modifiedActivity);
        }

        setSubmittedType(UserActions.ModifyActivities);

        userDispatch({
            type: UserActions.ModifyActivities,
            id: UserActions.ModifyActivities,
            activities: modifiedActivities,
            postSuccessHooks: () => {
                setSubmittedType(null);
                onClose();
            }
        });
    }

    function onRemove() {
        if (user === null) {
            console.error("Removing activity when user is null!")
            return;
        }

        setSubmittedType(UserActions.RemoveActivities);

        userDispatch({
            type: UserActions.RemoveActivities,
            id: UserActions.RemoveActivities,
            activities: activities,
            postSuccessHooks: () => {
                setSubmittedType(null);
                onClose();
            }
        });
    }

    const dateString = activities[0].timestamp.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric"
    });

    const modifyingText = <p className={UIColor.MinorText}>
        Modifying <strong>{activities.length}</strong> {activities.length === 1 ? 'activity' : 'activities'} from <strong>{dateString}</strong>.
    </p>;

    return (
        <ModalWindow handleClose={onClose} title="Modify Activities" {...props}>
            <form onSubmit={onSubmit}>
                {modifyingText}
                <div className="mb-6"></div>
                <BasicDateInput
                    defaultValue={activities[0].timestamp}
                    minValue={activities[0].route.creationDateUtc}
                    maxValue={new Date()}
                    onChange={(e) => setNewDateStringString(e.target.value)}
                    label="Date"
                />
                <div className="mb-8"></div>
                <div className="flex">
                    <BasicButton
                        buttonColor={UIColor.Red} className="w-full"
                        type="button"
                        onClick={onRemove}
                    >
                        {(isSaving && submittedType === UserActions.RemoveActivities) ? <SpinLoadingIndicator/> :
                            <> Remove {activities.length === 1 ? "" : "All"} <FontAwesomeIcon icon={faTrash}
                                                                                              className="pl-1.5"/> </>}
                    </BasicButton>
                    <div className="m-1"></div>
                    <BasicButton type="submit" buttonColor={UIColor.Blue}
                                 className="w-full"
                                 disabled={newDateString === null}
                    >
                        {(isSaving && submittedType === UserActions.ModifyActivities) ? <SpinLoadingIndicator/> :
                            <> Update {activities.length === 1 ? "" : "All"} <FontAwesomeIcon icon={faGear}
                                                                                              className="pl-1.5"/> </>}
                    </BasicButton>
                </div>
            </form>
            {<ErrorFromBackendResult data={error}/>}
        </ModalWindow>
    )
}
