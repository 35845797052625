import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import {ErrorPage, GlobalInfoOverlay} from "./GlobalInfoOverlay";
import ResetPasswordModal from "./side_panel/account/ResetPasswordModal";
import VerifyEmailModal from "./side_panel/account/VerifyEmailModal";

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        errorElement: <ErrorPage/>,
    },
    {
        path: "account/reset_password",
        element:
        <GlobalInfoOverlay>
            <ResetPasswordModal/>
        </GlobalInfoOverlay>,
    },
    {
        path: "account/verify_email",
        element:
            <GlobalInfoOverlay>
                <VerifyEmailModal/>
            </GlobalInfoOverlay>,
    },
]);

root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);
