import tinycolor from 'tinycolor2';

class SVGUtilities {
    static offset(svgString, x, y) {
        return `<g transform="translate(${x},${y})">${svgString}</g>`;
    }

    static rotate(svgString, angle, cx = 0, cy = 0) {
        const transformString = `rotate(${angle},${cx},${cy})`;
        return `<g transform="${transformString}">${svgString}</g>`;
    }

    static createBackgroundRectangle({w, h, fillColor}) {
        return `
            <rect
                width="${w}"
                height="${h}"
                fill="${fillColor}"
                stroke="${fillColor}"
                stroke-width="10"
            />
        `;
    }

    static createCircle({w, h, primaryColor, secondaryColor, strokeWidth, r}) {
        return `
            <circle
                cx="${w / 2}"
                cy="${h / 2}"
                r="${r}"
                stroke="${secondaryColor}"
                stroke-width="${strokeWidth}"
                fill="${primaryColor}"
            />
        `;
    }

    static createRoundedSquare({w, h, width, height, cornerRadius, primaryColor, secondaryColor, strokeWidth}) {
        return `
            <rect
                x="${w / 2 - width / 2}"
                y="${h / 2 - height / 2}"
                width="${width}"
                height="${height}"
                rx="${cornerRadius}"
                ry="${cornerRadius}"
                stroke="${primaryColor}"
                stroke-width="${strokeWidth}"
                fill="${secondaryColor}"
            />
        `;
    }

    static createHalfCircle({w, h, radius, primaryColor, secondaryColor, strokeWidth, yOffset}) {
        const x = (w - radius) / 2;
        const y = (h - radius / 2) / 2 + yOffset;
        const pathData = `M${x},${y} a1,1.5 0 0,0 ${radius},0 L${x},${y}`;

        return `
            <path
                d="${pathData}"
                stroke="${primaryColor}"
                stroke-width="${strokeWidth}"
                fill="${secondaryColor}"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        `;
    }

    static createPath({w, h, path, primaryColor, secondaryColor, strokeWidth}) {
        return `
            <path
                d="${path}"
                stroke="${primaryColor}"
                stroke-width="${strokeWidth}"
                fill="${secondaryColor}"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        `;
    }

    static createPolygon({w, h, primaryColor, secondaryColor, strokeWidth, r, n}) {
        function calculateVertices(cx, cy, radius, n, noise = 0) {
            const angle = (2 * Math.PI) / n;
            const vertices = [];

            const offset = (n + 1) % 2;

            for (let i = 0; i < n; i++) {
                let x = cx + radius * Math.cos(i * angle + Math.PI / n * offset + 3 * Math.PI / 2);
                let y = cy + radius * Math.sin(i * angle + Math.PI / n * offset + 3 * Math.PI / 2);

                x *= noise === 0 ? 1 : 1 - Math.random() * noise;
                y *= noise === 0 ? 1 : 1 - Math.random() * noise;

                vertices.push(`${x},${y}`);
            }

            return vertices.join(' ');
        }

        const points = calculateVertices(w / 2, h / 2, r, n);

        return `
            <polygon
                points="${points}"
                stroke="${secondaryColor}"
                stroke-width="${strokeWidth}"
                stroke-linecap="round"
                stroke-linejoin="round"
                fill="${primaryColor}"
            />
        `;
    }
}

class HoldIcon {
    constructor() {
        this.width = 256;
        this.height = 256;
        this.strokeWidth = 30;

        let randomHue = Math.floor(Math.random() * 360);
        const secondaryColor = tinycolor({h: randomHue, s: 90, v: 70});
        const primaryColor = secondaryColor.clone().lighten(20);

        randomHue = Math.floor(Math.random() * 360);
        const backgroundColor = tinycolor({h: randomHue, s: 30, v: 100});

        this.colorScheme = {
            primary: primaryColor.toHexString(),
            secondary: secondaryColor.toHexString(),
            background: backgroundColor.toHexString(),
        };
    }

    createBoltHole(s = 8, r = 14) {
        return SVGUtilities.createCircle({
            w: this.width,
            h: this.height,
            primaryColor: "black",
            secondaryColor: "white",
            strokeWidth: s,
            r: r,
        });
    }

    generatePocketHold() {
        return `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${this.width} ${this.height}">
                ${SVGUtilities.createBackgroundRectangle({
            w: this.width,
            h: this.height,
            fillColor: this.colorScheme.background,
        })}
                ${SVGUtilities.offset(
            SVGUtilities.createCircle({
                w: this.width,
                h: this.height,
                primaryColor: this.colorScheme.primary,
                secondaryColor: this.colorScheme.secondary,
                strokeWidth: this.strokeWidth,
                r: 80,
            }),
            0,
            5
        )}
                ${SVGUtilities.offset(
            SVGUtilities.createCircle({
                w: this.width,
                h: this.height,
                primaryColor: this.colorScheme.primary,
                secondaryColor: this.colorScheme.secondary,
                strokeWidth: this.strokeWidth,
                r: 80,
            }),
            0,
            -5
        )}
                ${SVGUtilities.offset(
            SVGUtilities.createCircle({
                w: this.width,
                h: this.height,
                primaryColor: this.colorScheme.secondary,
                secondaryColor: this.colorScheme.primary,
                strokeWidth: this.strokeWidth,
                r: 40,
            }),
            0,
            20
        )}
                ${SVGUtilities.offset(this.createBoltHole(), 0, -35)}
            </svg>
        `;
    }

    generateFoothold() {
        return `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${this.width} ${this.height}">
                ${SVGUtilities.createBackgroundRectangle({
            w: this.width,
            h: this.height,
            fillColor: this.colorScheme.background,
        })}
                ${SVGUtilities.createCircle({
            w: this.width,
            h: this.height,
            primaryColor: this.colorScheme.primary,
            secondaryColor: this.colorScheme.secondary,
            strokeWidth: this.strokeWidth,
            r: 50,
        })}
                ${this.createBoltHole()}
            </svg>
        `;
    }

    generatePinchHold() {
        return `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${this.width} ${this.height}">
                ${SVGUtilities.createBackgroundRectangle({
            w: this.width,
            h: this.height,
            fillColor: this.colorScheme.background,
        })}
                ${SVGUtilities.createPath({
            w: this.width,
            h: this.height,
            path: "M 168 68 C 148 108 148 148 168 188 C 188 228 68 228 88 188 C 108 148 108 108 88 68 C 68 28 188 28 168 68",
            primaryColor: this.colorScheme.secondary,
            secondaryColor: this.colorScheme.primary,
            strokeWidth: this.strokeWidth,
        })}
                ${SVGUtilities.offset(this.createBoltHole(6, 12), 0, 50)}
                ${SVGUtilities.offset(this.createBoltHole(6, 12), 0, -50)}
            </svg>
        `;
    }

    generatePolygonHold() {
        const minN = 5;
        const maxN = 7;
        const n = Math.floor(Math.random() * (maxN - minN + 1)) + minN;

        return `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${this.width} ${this.height}">
                ${SVGUtilities.createBackgroundRectangle({
            w: this.width,
            h: this.height,
            fillColor: this.colorScheme.background,
        })}
                ${SVGUtilities.createPolygon({
            w: this.width,
            h: this.height,
            primaryColor: this.colorScheme.primary,
            secondaryColor: this.colorScheme.secondary,
            strokeWidth: this.strokeWidth,
            r: 85,
            n: n,
        })}
                ${this.createBoltHole()}
            </svg>
        `;
    }

    generateCrimpHold() {
        return `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${this.width} ${this.height}">
                ${SVGUtilities.createBackgroundRectangle({
            w: this.width,
            h: this.height,
            fillColor: this.colorScheme.background,
        })}
                ${SVGUtilities.createRoundedSquare({
            w: this.width,
            h: this.height,
            width: 170,
            height: 70,
            cornerRadius: 30,
            primaryColor: this.colorScheme.secondary,
            secondaryColor: this.colorScheme.primary,
            strokeWidth: this.strokeWidth,
        })}
                ${SVGUtilities.offset(this.createBoltHole(6, 12), 40, 0)}
                ${SVGUtilities.offset(this.createBoltHole(6, 12), -40, 0)}
            </svg>
        `;
    }

    generateCrackHold() {
        return `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${this.width} ${this.height}">
                ${SVGUtilities.createBackgroundRectangle({
            w: this.width,
            h: this.height,
            fillColor: this.colorScheme.primary,
        })}
                ${SVGUtilities.rotate(
            SVGUtilities.createRoundedSquare({
                w: this.width,
                h: this.height,
                width: 300,
                height: 90,
                primaryColor: this.colorScheme.secondary,
                secondaryColor: this.colorScheme.background,
                strokeWidth: this.strokeWidth * 1.25,
            }),
            -55, 128, 128
        )}
            </svg>
        `;
    }

    generateJugHold() {
        return `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${this.width} ${this.height}">
                ${SVGUtilities.createBackgroundRectangle({
            w: this.width,
            h: this.height,
            fillColor: this.colorScheme.background,
        })}
                ${SVGUtilities.createHalfCircle({
            w: this.width,
            h: this.height,
            radius: 150,
            primaryColor: this.colorScheme.secondary,
            secondaryColor: this.colorScheme.primary,
            strokeWidth: this.strokeWidth,
            yOffset: 0,
        })}
                ${SVGUtilities.offset(this.createBoltHole(), 0, 10)}
            </svg>
        `;
    }

    generateRandomHold() {
        const functions = [
            this.generateJugHold,
            this.generatePocketHold,
            this.generatePolygonHold,
            this.generateCrimpHold,
            this.generatePinchHold,
            this.generateFoothold,
        ];

        const f = functions[Math.floor(functions.length * Math.random())];
        return f.call(this);
    }
}

export default HoldIcon;
