import {useState, useEffect, useContext, useRef} from "react";
import {NotificationContext} from "./contexts/NotificationContext";
import {useForceUpdate} from "./common/CustomHooks";

export default function NotificationBox() {
    const notification = useContext(NotificationContext);
    const forceUpdate = useForceUpdate();
    const box = useRef();
    const lineBackground = useRef();
    const lineIndicator = useRef();
    const remainingSeconds = useRef(0);

    remainingSeconds.current = notification == null ? 0 : notification.getRemainingSeconds();

    useEffect(() => {
        if (remainingSeconds.current <= 0)
            return;

        const borderRadius = window.getComputedStyle(box.current).borderRadius;
        lineIndicator.current.style.borderBottomLeftRadius = borderRadius;
        lineBackground.current.style.borderBottomLeftRadius = borderRadius;
        lineBackground.current.style.borderBottomRightRadius = borderRadius;

        lineIndicator.current.style.transition = null;
        lineIndicator.current.style.width = `${(remainingSeconds.current / notification.duration) * 100}%`;

        setTimeout(() => {
            lineIndicator.current.style.transition = `width ${remainingSeconds.current}s linear`;
            lineIndicator.current.style.width = "0";
        });
        setTimeout(() => {
            forceUpdate();
        }, remainingSeconds.current * 1000);
    }, [notification]);

    if (remainingSeconds.current <= 0)
        return;
    return (
        <div
            className={
                `fixed top-0 left-0 right-0 flex justify-center items-center`
            }
        >
            <div
                ref={box}
                className={`py-3 mt-2 mx-4 rounded-md bg-blue-700 text-white flex justify-center items-center`}
                style={{width: 400}}
            >
                <span className="text-lg">{notification.message}</span>
                <div ref={lineBackground} className="absolute bottom-0 left-0 h-2 w-full bg-blue-300">
                    <div
                        ref={lineIndicator}
                        className={`h-2 bg-blue-800`}
                    ></div>
                </div>
            </div>
        </div>
    );
}