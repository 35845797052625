import * as THREE from "three";

export default class OutlineType {
    static HIGHLIGHT = new OutlineType("highlight", {
        edgeStrength: 4.0,
        edgeThickness: 1.0,
        edgeGlow: 1.0,
        color: new THREE.Color(0xffffff),
        hiddenColor: new THREE.Color(0xffffff),
        blending: THREE.AdditiveBlending,
    });
    static ATTEMPTED_ROUTE = new OutlineType("attempted_route", {
        edgeStrength: 3.0,
        edgeThickness: 0.5,
        edgeGlow: 1.0,
        color: new THREE.Color('red'),
        hiddenColor: new THREE.Color('red'),
    });
    static CLIMBED_ROUTE = new OutlineType("climbed_route", {
        edgeStrength: 6.0,
        edgeThickness: 0.5,
        edgeGlow: 1.0,
        color: new THREE.Color('green'),
        hiddenColor: new THREE.Color('green'),
    });
    // static WALL_BORDER = new OutlineType("wall_border", {
    //     edgeStrength: 5.0,
    //     edgeThickness: 0.5,
    //     edgeGlow: 0.0,
    //     color: new THREE.Color(0x000),
    //     hiddenColor: new THREE.Color(0x000),
    // });

    constructor(typeId, params) {
        this.typeId = typeId;
        this.params = params;
    }
}
