import React, { useState, useEffect, useRef } from "react";
import { ClickableUIStyle, UIColor } from "../Config";

function BasicButton({
                         key,
                         children,
                         onClick,
                         onMouseDown,
                         onHold = null,
                         onHoldDuration = 3000,
                         disabled = null,
                         className = "",
                         padding = "py-2 px-3",
                         buttonColor = UIColor.Default,
                         round = true,
                         type = null,
                         id = null
                     }) {

    const [holdDuration, setHoldDuration] = useState(0);
    const startTimeRef = useRef(null);
    const requestRef = useRef(null);
    const clickTimeoutRef = useRef(null);

    const handleMouseDown = () => {
        if (disabled) return;

        startTimeRef.current = Date.now();
        requestRef.current = requestAnimationFrame(updateHoldDuration);
    };

    const handleMouseUp = () => {
        resetHold();
    };

    const handleMouseLeave = () => {
        resetHold();
    };

    const updateHoldDuration = () => {
        const elapsed = Date.now() - startTimeRef.current;
        if (elapsed >= onHoldDuration) {
            setHoldDuration(onHoldDuration);
            if (onHold) onHold();
            resetHold();
        } else {
            setHoldDuration(elapsed);
            requestRef.current = requestAnimationFrame(updateHoldDuration);
        }
    };

    const resetHold = () => {
        cancelAnimationFrame(requestRef.current);
        setHoldDuration(0);
        startTimeRef.current = null;
    };

    useEffect(() => {
        return () => {
            resetHold();
        };
    }, []);

    const holdProgress = (holdDuration / onHoldDuration) * 100;

    return (
        <button
            key={key}
            className={`${ClickableUIStyle[buttonColor]} disabled:pointer-events-none text-white disabled:text-opacity-25 font-medium ${round ? "rounded" : ""} ${className}`}
            onClick={onClick}
            onMouseDown={() => {
                if(onHold !== null) handleMouseDown();
                onMouseDown?.();
            }}
            onMouseUp={onHold !== null ? handleMouseUp : null}
            onMouseLeave={onHold !== null ? handleMouseLeave : null}
            disabled={disabled}
            type={type}
            id={id}
        >
            <div className="relative w-full">
                <div
                    className="absolute h-full bg-white opacity-20"
                    style={{width: `${holdProgress}%`}}
                ></div>
                <div className={padding}>
                    {children}
                </div>
            </div>
        </button>
    );
}

export {BasicButton};

