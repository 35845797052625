import {RouteStateType, UserActivityType} from "../../model/User";
import {Fragment} from "react";
import {Route} from "../../model/Wall";
import {faCircle, faGear, faTimes} from "@fortawesome/free-solid-svg-icons";
import {ClickableUIStyle, UIColor} from "../../Config";
import ClickableFontAwesomeIcon from "../../common/ClickableFontAwesomeIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function ActivityLine({activities, overrideIconState}) {
    // all activities should be from the same route
    let activity = activities[0];

    const routeColor = Route.getColorHexString(activity.route);

    let colorFunction = overrideIconState ? RouteStateType.Color : UserActivityType.Color;
    let iconFunction = overrideIconState ? RouteStateType.Icon : UserActivityType.Icon;

    return <div
        className="flex items-center gap-3"
    >
        <div className="flex gap-2">
            {
                activities.reverse().map(activity => (
                    <div
                        className={`${colorFunction(overrideIconState ?? activity.type)} drop-shadow-lg w-fit px-1.5 py-0.5 rounded`}>
                        {<FontAwesomeIcon
                            icon={iconFunction(overrideIconState ?? activity.type)}
                            className="w-4"
                        ></FontAwesomeIcon>}
                    </div>
                ))
            }
        </div>
        {routeColor && (<>
            <div
                className="h-5 w-5 rounded-full"
                style={{backgroundColor: routeColor}}
            ></div>
        </>)}
        {activity.route.difficulty && (<>
            <FontAwesomeIcon icon={faCircle} className="text-[15%] opacity-25"/>
            <span className="font-bold">{activity.route.difficulty ?? ""}</span>
        </>)}
    </div>

}


export function ActivityCard({activities, onActivityClick, onActivityModify, className = "", editable = true}) {
    // TODO: check that all activities are of the same route

    return (
        <div className={`${className} flex justify-between items-center`}>
            <div
                className={`px-2 py-1.5 rounded-lg mb-1.5 ${ClickableUIStyle[UIColor.DarkInput]} ${editable ? 'cursor-pointer' : 'cursor-default opacity-40'} flex flex-grow justify-between items-center`}
                onClick={() => onActivityClick(activities[0])}
            >
                <ActivityLine activities={activities}/>
            </div>
            <ClickableFontAwesomeIcon
                icon={faGear}
                onClick={onActivityModify}
                className={`pl-2 pr-0.5 py-3 ${editable ? 'visible' : 'invisible'}`}
                inverseHover={true}
            />
        </div>);
}

export function BookmarkCard({bookmark, routeState, onClick, onRemove}) {
    return (
        <div className="flex justify-between items-center">
            <div
                className={`px-2 py-1.5 rounded-lg mb-1.5 ${ClickableUIStyle[UIColor.DarkInput]} cursor-pointer flex flex-grow justify-between items-center`}
                onClick={() => onClick(bookmark)}
            >
                <ActivityLine activities={[bookmark]} overrideIconState={routeState}/>
            </div>
            <ClickableFontAwesomeIcon
                icon={faTimes}
                onClick={() => onRemove(bookmark)}
                className="pl-2 pr-0.5 py-3 text-xl"
                inverseHover={true}
            />
        </div>);
}
