import "./HoldDialog.css";
import {faXmark} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BasicButton} from "../../common/Button";
import {UIColor} from "../../Config";

function HoldDialog({position, onCancel, onConfirm, type}) {
    const styles = {
        position: "absolute",
        top: position.y,
        left: position.x,
    }
    return (
        <div id="hold-change-dialog" style={styles} className="text-lg">
            <FontAwesomeIcon
                icon={faXmark}
                onClick={onCancel}
                size="lg"
                className="absolute top-0 left-0 cursor-pointer p-3"
            />
            <div className="mb-1.5"></div>
            {type === HoldDialogType.HoldAddition &&
                <p>Add hold to route?</p>
            }
            {type === HoldDialogType.HoldRemoval &&
                <p>Remove hold from route?</p>
            }
            <div className="mb-3"></div>
            <div className="flex justify-end space-x-4">
                <BasicButton onClick={onCancel}>Cancel</BasicButton>
                <BasicButton onClick={onConfirm} buttonColor={UIColor.Blue}>Confirm</BasicButton>
            </div>
        </div>
    );
}

class HoldDialogType {
    static HoldAddition = 0;
    static HoldRemoval = 1;
}

export {HoldDialog, HoldDialogType};