class CanvasState {
}

class TopDownState extends CanvasState {
    constructor(initialTarget = null) {
        super();
        this.initialTarget = initialTarget;
    }
}

class RouteFocusState extends CanvasState {
    constructor(route) {
        super();
        this.route = route;
    }
}

class ProposalReviewState extends CanvasState {
}

export {CanvasState, TopDownState, RouteFocusState, ProposalReviewState};