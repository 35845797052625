import {Fragment, useContext} from "react";
import EditableDropdown from "../../common/EditableDropdown";
import {WallContext} from "../../contexts/WallContext";
import BasicTextInput from "../../common/TextInput";
import {ProposalActions, ProposalDispatchContext} from "../../contexts/ProposalContext";
import BasicDateInput from "../../common/DateInput";

export default function RouteInfoEdit({route, onWallDataUpdate}) {
    const wall = useContext(WallContext);
    const proposalDispatch = useContext(ProposalDispatchContext);

    function onSetAttribute(attribute, newValue) {
        proposalDispatch({
            type: ProposalActions.RouteAttributeUpdate,
            route: route,
            attribute: attribute,
            newValue: newValue,
        });
    }

    function setCreationDate(newDate) {
        const dateInMillis = Date.parse(newDate);
        onSetAttribute("creationDateUtc", dateInMillis);
    }

    const selectableMetadata = [
        ["builder", "Builder", "builders"],
        ["difficulty", "Difficulty", "difficulties"],
        ["color", "Color", "colors"],
        ["sector", "Sector", "sectors"],
    ];

    const creationDateUtc = route.metadata["creationDateUtc"];
    return (
        <>
            <BasicTextInput
                defaultValue={route.metadata["name"]}
                onBlur={(e) => onSetAttribute("name", e.target.value)}
                label="Name"
            />
            <div className="mb-3"></div>

            <BasicDateInput
                defaultValue={creationDateUtc}
                onBlur={(e) => setCreationDate(e.target.value)}
                label="Creation Date"
            />

            {selectableMetadata.map(([id, humanName, collectionId]) =>
                <Fragment key={id}>
                    <div className="mb-3"></div>
                    <EditableDropdown
                        items={wall.metadata[collectionId]}
                        setItems={newItems => onWallDataUpdate(collectionId, newItems)}
                        item={route.metadata[id]}
                        setItem={item => onSetAttribute(id, item)}
                        label={humanName}
                    />
                </Fragment>
            )}
        </>
    );
}
