import "./ProposalPanel.css";
import {useContext, useState} from "react";
import RouteInfoEdit from "./RouteInfoEdit";
import {faArrowLeft, faArrowsRotate} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BasicButton} from "../../common/Button";
import {ProposalActions, ProposalDispatchContext} from "../../contexts/ProposalContext";
import SpinLoadingIndicator from "../../common/SpinLoadingIndicator";
import ErrorFromBackendResult from "../../common/ErrorFromBackendResult";
import {backend, BackendErrorType} from "../../model/Backend";
import {UIColor} from "../../Config";


export default function ProposalPanel({proposal, route, setRoute, onWallDataUpdate}) {
    const [state, setState] = useState(new OverviewState());
    const [publishing, setPublishing] = useState(false);
    const [publishError, setPublishError] = useState(null);
    const proposalDispatch = useContext(ProposalDispatchContext);

    function onReview(reviewType) {
        const newState = new RouteReviewState(reviewType, state.pageIndices);
        if (newState.routesByType(proposal).length === 0)
            return;
        setState(newState);
        setRoute(newState.routesByType(proposal)[newState.pageIndex]);
    }

    function onBackToOverview() {
        setState(new OverviewState(state.pageIndices));
        setRoute(null);
    }

    function onPreviousPage() {
        console.assert(state instanceof RouteReviewState);
        const newPageIndex = state.pageIndex - 1;
        if (newPageIndex < 0)
            return;
        setState(state.withPageIndex(newPageIndex));
        setRoute(state.routesByType(proposal)[newPageIndex]);
    }

    function onNextPage() {
        console.assert(state instanceof RouteReviewState);
        const newPageIndex = state.pageIndex + 1;
        if (newPageIndex >= state.routes.length)
            return;
        setState(state.withPageIndex(newPageIndex));
        setRoute(state.routesByType(proposal)[newPageIndex]);
    }

    function stopReview() {
        proposalDispatch({type: ProposalActions.StopReviewing});
    }

    function retryProposalSave() {
        proposalDispatch({type: ProposalActions.RetrySave});
    }

    // TODO: when reviewing, hide the side panel and indicate (maybe with a thin red border around the screen)
    // that reviewing is in progress (and give a clear button to quit)
    async function publish() {
        if (proposal.saving)
            return; // This shouldn't happen.

        setPublishing(true);
        setPublishError(null);
        const result = await backend.publishProposal(proposal.metadata["id"]);
        // TODO: when publishing or saving is in progress, ask when the user is about to close the site
        if (!result.isSuccess()) {
            setPublishing(false);
            setPublishError(result);
        } else {
            setPublishing(false);
            // TODO: show a message modal
            // TODO: set wall data from proposal data (or just reload the page)
            // TODO: close the panel (ie stop review)
            // TODO: warn if some routes have unfilled info
        }
    }

    return (
        <div id="proposal-panel">
            {/*Note that instanceof doesn't work here, since React mangles the type information.*/}
            {state.type === StateType.Overview &&
                <>
                    <h2 className="text-3xl">Rebuild Review</h2>
                    <div className="mb-3"></div>
                    <span className="text-lg">
                        Rebuild name:&nbsp;
                        <span className="font-bold">{proposal.metadata["name"]}</span>
                    </span>
                    <div className="mb-5"></div>
                    <div className="flex justify-end space-x-4">
                        <BasicButton
                            onClick={() => onReview(ReviewType.UnchangedRoute)}
                            disabled={proposal.unchangedRoutes.length === 0 || publishing}
                        >Review unchanged ({proposal.unchangedRoutes.length})
                        </BasicButton>
                        <BasicButton
                            onClick={() => onReview(ReviewType.NewRoute)}
                            disabled={proposal.newRoutes.length === 0 || publishing}
                        >Review new ({proposal.newRoutes.length})
                        </BasicButton>
                    </div>
                    <div className="mb-3"></div>
                    <div className="flex justify-end space-x-4">
                        <BasicButton
                            onClick={stopReview}
                            disabled={publishing}
                        >
                            Stop Review
                        </BasicButton>
                        <BasicButton
                            buttonColor={UIColor.Blue}
                            onClick={publish}
                            disabled={proposal.saving || publishing}
                        >
                            {publishing ? <SpinLoadingIndicator/> : "Publish"}
                        </BasicButton>
                    </div>
                </>
            }

            {state.type === StateType.RouteReview &&
                <>
                    <FontAwesomeIcon icon={faArrowLeft} id="back-to-overview-button" onClick={onBackToOverview}/>
                    <div className="mb-4"></div>
                    <h2 className="text-3xl">Edit Route</h2>
                    <div className="mb-2"></div>
                    <div>
                        <RouteInfoEdit
                            route={route}
                            onWallDataUpdate={onWallDataUpdate}
                        />
                    </div>
                    <div className="mb-4"></div>
                    <div className="flex justify-center space-x-4">
                        <BasicButton onClick={onPreviousPage} disabled={state.pageIndex === 0}>
                            Previous route
                        </BasicButton>
                        <p className="my-auto">{state.pageIndex + 1} / {state.routesByType(proposal).length}</p>
                        <BasicButton onClick={onNextPage}
                                     disabled={state.pageIndex === state.routesByType(proposal).length - 1}>
                            Next route
                        </BasicButton>
                    </div>
                </>
            }
            {proposal.saving &&
                <>
                    <div className="mb-3"></div>
                    <SpinLoadingIndicator/>
                </>
            }
            {proposal.savingError &&
                <>
                    <div className="mb-5"></div>
                    <ErrorFromBackendResult
                        data={proposal.savingError}
                        messages={{
                            [BackendErrorType.GeneralError]: "Could not save your changes, please try again."
                        }}
                    />
                    <div className="mb-2"></div>
                    <div className="flex justify-center">
                        <BasicButton onClick={retryProposalSave}>
                            <span className="mr-2">Try again</span>
                            <FontAwesomeIcon icon={faArrowsRotate}/>
                        </BasicButton>
                    </div>
                </>
            }
            {publishError &&
                <>
                    <div className="mb-5"></div>
                    <ErrorFromBackendResult
                        data={publishError}
                        messages={{
                            [BackendErrorType.GeneralError]: "Could not publish the rebuild, please try again."
                        }}
                    />
                </>
            }
        </div>
    )
}

class ReviewType {
    static UnchangedRoute = 0;
    static NewRoute = 1;
}

class StateType {
    static Overview = 0;
    static RouteReview = 1;
}

class ProposalPanelState {
    constructor(type, pageIndices = null) {
        this.type = type;
        this.pageIndices = pageIndices || new Map([
            [ReviewType.UnchangedRoute, 0],
            [ReviewType.NewRoute, 0],
        ]);
    }
}

class OverviewState extends ProposalPanelState {
    constructor(pageIndices = null) {
        super(StateType.Overview, pageIndices);
    }
}

class RouteReviewState extends ProposalPanelState {
    constructor(reviewType, pageIndices = null) {
        super(StateType.RouteReview, pageIndices);
        this.reviewType = reviewType;
        this.pageIndex = this.pageIndices.get(this.reviewType);
    }

    routesByType(proposal) {
        return this.reviewType === ReviewType.UnchangedRoute ? proposal.unchangedRoutes : proposal.newRoutes;
    }

    withPageIndex(newPageIndex) {
        return new RouteReviewState(this.reviewType, this.pageIndices.set(this.reviewType, newPageIndex));
    }
}
