import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faWarning} from '@fortawesome/free-solid-svg-icons';

export function WarningMessage({children, title, text}) {
    return <div className="bg-yellow-700 rounded-xl p-4">
        <p className="text-lg">
            <FontAwesomeIcon icon={faWarning} className="text-xl"></FontAwesomeIcon>
            <strong className="pl-2 underline underline-offset-2">{title}</strong>
        </p>
        <div className="m-2"></div>
        <p> {text} </p>
        {children ?
            <>
                <div className="m-4"></div>
                {children}
            </> : ""
        }
    </div>;
}
