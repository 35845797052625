/* Definition for buttons that modify the state of climbing a route. */

import {RouteStateType, UserActivityType} from "../../model/User";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarCheck, faCheck, faFlag, faRepeat} from "@fortawesome/free-solid-svg-icons";
import {BasicButton} from "../../common/Button";
import {DateUtils} from "../../common/Utils";
import {useState} from "react";


export default function RouteActivityButtons({
                                                 route,
                                                 user,
                                                 handleActivityAdd,
                                                 handleActivityRemove,
                                                 isDisabled,
                                                 className
                                             }) {
    const [confirmActivity, setConfirmActivity] = useState(false);
    const [confirmActivityText, setConfirmActivityText] = useState(null);

    const routeState = user.value == null ? RouteStateType.Untouched : user.value.getRouteClimbingState(route);

    let sendActivities = [];
    if (user.value !== null) {
        for (const type of [UserActivityType.Flash, UserActivityType.Climb, UserActivityType.Repeat]) {
            sendActivities = sendActivities.concat(user.value.getActivities(type, route));
        }
    }

    // sort so we can check whether we climbed it today
    sendActivities.sort((a, b) => {
        return a.timestamp - b.timestamp;
    }).reverse();

    let todayActivity = null;
    if (sendActivities.length && DateUtils.isSameDay(new Date(Date.now()), sendActivities[0].timestamp)) {
        todayActivity = sendActivities[0];
    }

    function confirmWrapper(f, activities) {
        // for only 1 activity we're chilling
        if (sendActivities.length <= 1) {
            f(activities);
            return;
        }

        // for more activities, we want to confirm before nuking repeats
        if (!confirmActivity) {
            setConfirmActivity(true);
            setConfirmActivityText("Remove repeats?")
        } else {
            f(activities, () => {
                setConfirmActivity(false);
                setConfirmActivityText(null)
            });
        }
    }

    return (
        <div className={`flex justify-center space-x-3 truncate ${className}`}>
            {(routeState === RouteStateType.Climbed || routeState === RouteStateType.Flashed) &&
                <BasicButton
                    padding="px-2 py-1.5"
                    onClick={() => {
                        // Only remove today's repeat -- if we sent that day, allow to repeat as well
                        // This is otherwise too confusing for the user
                        if (todayActivity !== null && todayActivity.type === UserActivityType.Repeat) {
                            handleActivityRemove([todayActivity]);

                            setConfirmActivity(false);
                            setConfirmActivityText(null)
                        } else {
                            handleActivityAdd(UserActivityType.Repeat);

                            setConfirmActivity(false);
                            setConfirmActivityText(null)
                        }
                    }}
                    buttonColor={UserActivityType.Color(UserActivityType.Repeat)}
                    className={(todayActivity !== null && todayActivity.type === UserActivityType.Repeat) ? `shadow-large shadow-neutral-600 transition-all duration-75` : ' transition-all duration-75'}
                >
                    <div className="flex items-center">
                        <span><FontAwesomeIcon icon={faRepeat} className="pr-1.5"/></span>
                        <strong
                            className="px-2 bg-neutral-800 bg-opacity-50 rounded-lg"><code>{sendActivities.length}</code></strong>
                        {todayActivity !== null && todayActivity.type === UserActivityType.Repeat ?
                            <span><FontAwesomeIcon icon={faCalendarCheck} className="pr-0.5 pl-2"/></span> : ""}
                    </div>
                </BasicButton>
            }
            {
                (routeState !== RouteStateType.Climbed && routeState !== RouteStateType.Flashed) &&
                <BasicButton
                    buttonColor={RouteStateType.Color(RouteStateType.Attempted)}
                    onClick={
                        () => {
                            if (routeState === RouteStateType.Attempted)
                                handleActivityRemove(UserActivityType.Attempt);
                            else
                                handleActivityAdd(UserActivityType.Attempt);
                        }
                    }
                    disabled={isDisabled}
                    className={routeState === RouteStateType.Attempted ? `shadow-large shadow-rose-600 transition-all duration-75` : ' transition-all duration-75'}
                >
                    <p>
                        <FontAwesomeIcon icon={faFlag}/>
                        <span className="ml-2">{RouteStateType.Name(RouteStateType.Attempted)}</span>
                        {routeState === RouteStateType.Attempted && <FontAwesomeIcon icon={faCheck} className="ml-2"/>}
                    </p>
                </BasicButton>
            }
            {(routeState === RouteStateType.Climbed || routeState === RouteStateType.Attempted) &&
                <BasicButton
                    buttonColor={RouteStateType.Color(RouteStateType.Climbed)}
                    onClick={
                        () => {
                            if (routeState === RouteStateType.Climbed)
                                confirmWrapper(handleActivityRemove, sendActivities);
                            else
                                handleActivityAdd(UserActivityType.Climb);
                        }
                    }
                    disabled={isDisabled}
                    className={routeState === RouteStateType.Climbed ? `shadow-large shadow-emerald-600 transition-all duration-75` : ' transition-all duration-75 '}
                >
                    <p className="truncate">
                        <FontAwesomeIcon icon={RouteStateType.Icon(RouteStateType.Climbed)} className="mr-2"/>
                        <span>{confirmActivityText ?? RouteStateType.Name(RouteStateType.Climbed)}</span>
                        {routeState === RouteStateType.Climbed && <FontAwesomeIcon icon={faCheck} className="ml-2"/>}
                    </p>
                </BasicButton>
            }
            {routeState === RouteStateType.Flashed &&
                <BasicButton
                    buttonColor={RouteStateType.Color(RouteStateType.Flashed)}
                    onClick={() => confirmWrapper(handleActivityRemove, sendActivities)}
                    disabled={isDisabled}
                    className={routeState === RouteStateType.Flashed ? `shadow-large shadow-emerald-700 transition-all duration-75` : ' transition-all duration-75'}
                >
                    <p className="truncate">
                        <FontAwesomeIcon icon={RouteStateType.Icon(RouteStateType.Flashed)} className="mr-2"/>
                        <span>{confirmActivityText ?? RouteStateType.Name(RouteStateType.Flashed)}</span>
                        <FontAwesomeIcon icon={faCheck} className="ml-2"/>
                    </p>
                </BasicButton>
            }
            {routeState === RouteStateType.Untouched &&
                <div className={`inline-flex rounded divide-x-2 divide-opacity-40 divide-white`}>
                    <BasicButton
                        className="rounded-l"
                        round={false}
                        buttonColor={RouteStateType.Color(RouteStateType.Climbed)}
                        onClick={() => handleActivityAdd(UserActivityType.Climb)}
                        disabled={isDisabled}
                    >
                        <p className="truncate">
                            <FontAwesomeIcon icon={RouteStateType.Icon(RouteStateType.Climbed)} className="mr-2"/>
                            <span>{RouteStateType.Name(RouteStateType.Climbed)}</span>
                        </p>
                    </BasicButton>
                    <BasicButton
                        className="rounded-r"
                        round={false}
                        buttonColor={RouteStateType.Color(RouteStateType.Flashed)}
                        onClick={() => handleActivityAdd(UserActivityType.Flash)}
                        disabled={isDisabled}
                    >
                        <FontAwesomeIcon icon={RouteStateType.Icon(RouteStateType.Flashed)}/>
                    </BasicButton>
                </div>
            }
        </div>
    );
}
