import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";

import BasicTextInput from "./TextInput";
import {BasicButton} from "./Button";


export default function ModifiableList({values, setValues}) {
    const [newValue, setNewValue] = useState("");

    function handleRemove(index) {
        const newValues = [...values];
        newValues.splice(index, 1);
        setValues(newValues);
    }

    function handleEdit(index, newName) {
        const newValues = [...values];
        newValues[index] = newName;
        setValues(newValues);
    }

    function handleEditNew(event) {
        setNewValue(event.target.value);
    }

    function handleAdd() {
        if (!newValue)
            return;
        // TODO: check duplicates
        setValues([...values, newValue]);
        setNewValue("");
    }

    return (
        <div>
            {values.map((value, index) => (
                <div key={index} className="flex justify-end space-x-4 mb-2">
                    <BasicTextInput
                        type="text"
                        value={value}
                        onChange={(e) => handleEdit(index, e.target.value)}
                    />
                    <button onClick={() => handleRemove(index)}>
                        <FontAwesomeIcon icon={faXmark}/>
                    </button>
                </div>
            ))}
            <div className="flex justify-end space-x-2">
                <BasicTextInput
                    type="text"
                    value={newValue}
                    onChange={handleEditNew}
                />
                <BasicButton onClick={handleAdd}>Add</BasicButton>
            </div>
        </div>
    );
}