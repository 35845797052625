import React, {useContext, useRef} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faArrowLeft,
    faBars,
    faCircleQuestion,
    faGears,
    faHouse,
    faRankingStar,
    faUser, faUserClock
} from '@fortawesome/free-solid-svg-icons'

import SideOverview from "./overview/SideOverview";
import SidePersonal from "./account/SidePersonal";
import {UserContext} from "../contexts/UserContext";
import OwnerInfo from "./account/OwnerInfo";
import {ProposalContext} from "../contexts/ProposalContext";
import {UiActions, UiContext, UiDispatchContext} from "../contexts/UiContext";
import Window from "../common/Window";
import SideInfo from "./account/SideInfo";
import {BasicButton} from "../common/Button";
import SideLeaderboard from "./account/SideLeaderboard";
import {ScreenDimensions, useScreenDimensions} from "../common/CustomHooks";
import {UIColor} from "../Config";

export default function SidePanel() {
    const [uiState, uiStateDispatch] = [useContext(UiContext), useContext(UiDispatchContext)];
    const user = useContext(UserContext);
    const proposal = useContext(ProposalContext);

    const sidePanel = useRef();

    const pages = [
        {
            element: <SideOverview/>,
            icon: faHouse,
        },
        {
            element: <SidePersonal/>,
            icon: faUser,
        },
        {
            element: <SideLeaderboard/>,
            icon: faRankingStar,
        },
        {
            element: <SideInfo/>,
            icon: faCircleQuestion,
        },
    ];
    if (user.value !== null && user.value.isOwner) {
        pages.push({
            element: <OwnerInfo/>,
            icon: faGears,
        });
    }

    // Safeguard for when the user logs out while the side panel is mounted.
    if (uiState.sidePanelPageIdx >= pages.length) {
        uiStateDispatch({type: UiActions.SetSidePanelPageIdx, pageIdx: pages.length - 1});
    }

    function onCloseWrapper(e) {
        if (e.target !== e.currentTarget)
            return;

        uiStateDispatch({type: UiActions.CloseSidePanel})
    }

    let window = <Window
        id="side-navigation"
        className={`absolute z-20 ${uiState.sidePanelOpen ? "left-4" : "-left-full opacity-0 sm:-left-96 select-none"} top-4 overflow-x-hidden transition-all`}>
        <div id="side-navigation-bar" className="sticky top-0 z-20">
            <div
                className="bg-neutral-600 pt-4 px-5 pb-5 flex justify-between items-center space-x-4"
            >
                <img src="logo.png" alt="Climbuddy.com logo" className="h-12"/>
                <FontAwesomeIcon icon={faArrowLeft} size="2x" className="cursor-pointer pr-2"
                                 onClick={() => uiStateDispatch({type: UiActions.CloseSidePanel})}
                />
            </div>
            <div id="bottom-navigation"
                 className="box-border flex justify-between flex-row align-baseline w-full text-center divide-x-2 divide-neutral-500"
            >
                {pages.map(({icon}, idx) => {
                    return (
                        <button
                            key={idx}
                            className={
                            `${idx === pages.length - 1 ? 'px-4' : 'flex-1'} cursor-pointer nav-btn flex flex-col items-stretch py-3 ${idx === uiState.sidePanelPageIdx ? "bg-neutral-800" : "bg-neutral-700 hover:bg-neutral-500"}`
                        }
                            onClick={() => uiStateDispatch({
                                type: UiActions.SetSidePanelPageIdx,
                                pageIdx: idx
                            })}
                        >
                            <FontAwesomeIcon icon={icon} size="lg"/>
                        </button>
                    );
                })}
            </div>
        </div>
        <div id="side-content" className="p-6">
            {pages[uiState.sidePanelPageIdx].element}
        </div>
    </Window>;

    const [screenWidth, screenHeight] = useScreenDimensions();
    const onMobile = (screenWidth <= ScreenDimensions.MOBILE_WIDTH);

    const reviewingProposal = proposal !== null && proposal.beingReviewed;

    // should signify the state -- not logged in, temporary, logged in
    let buttonIcon = user.value === null ? faBars :
        user.value.isTemporary ? faUserClock : faUser;

    let buttonColor = UIColor.Default;
    if (user.value) {
        if (user.value.isTemporary) {
            buttonColor = UIColor.DarkYellow;
        } else {
            buttonColor = UIColor.Default;
        }
    }

    return (
        <>
            {!reviewingProposal && !uiState.sidePanelOpen &&
                <BasicButton
                    id="open-navigation-button"
                    padding="px-3 py-1.5"
                    // Default the page index to home page when not logged in, otherwise default to profile
                    onClick={() => uiStateDispatch({
                        type: UiActions.OpenSidePanel,
                        pageIdx: user.value === null ? 0 : 1
                    })}
                    className="text-2xl absolute top-4 left-4 rounded-2xl z-0 shadow-medium"
                    buttonColor={buttonColor}
                >
                    <FontAwesomeIcon
                        icon={buttonIcon}
                    />
                </BasicButton>
            }
            {!reviewingProposal &&
                (
                    <div
                        className={(uiState.sidePanelOpen && onMobile) ? "fixed top-0 left-0 w-full h-full z-40 flex justify-center items-center bg-black/50" : ""}
                        ref={sidePanel}
                        onClick={onCloseWrapper}
                    >
                        {window}
                    </div>
                )
            }

        </>);
}