export default function SpinLoadingIndicator() {
    return (
        <svg fill="none" viewBox="0 0 66 66" className="w-7 mx-auto animate-spin">
            <circle
                cx="33"
                cy="33"
                r="20"
                stroke="white"
                strokeDasharray="30, 150"
                strokeDashoffset="300"
                strokeLinecap="round"
                strokeWidth="10"
            />
        </svg>
    );
}