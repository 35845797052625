import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons';
import {useEffect, useState} from "react";
import {isRouteErrorResponse, useRouteError} from "react-router-dom";

export function GlobalInfoOverlay({children}) {
    return (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 flex justify-center items-center text-white z-50">
            {children}
        </div>
    )
}

export function ProgressInfo({progress, size = 100, motto = null}) {
    const circumference = 2 * Math.PI * (size / 2 - 10);
    const strokeWidth = 10;
    const radius = size / 2 - strokeWidth;
    const progressText = `${Math.floor(progress * 100)}%`

    const [offset, setOffset] = useState(0);
    useEffect(() => {
        const progressOffset = (1 - progress) * circumference;
        setOffset(progressOffset);
    }, [progress, circumference]);

    return (
        <div className="flex items-center flex-col">
            {motto ? <p className="mb-6 text-2xl">{motto}</p> : ""}
            <svg
                width={size}
                height={size}
                viewBox={`0 0 ${size} ${size}`}
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle
                    stroke="#18181b"
                    strokeWidth={strokeWidth}
                    fill="transparent"
                    r={size / 2 - 10}
                    cx={size / 2}
                    cy={size / 2}
                />
                <circle
                    stroke="#d4d4d4"
                    strokeWidth={strokeWidth + 0.5}
                    fill="transparent"
                    strokeDasharray={circumference}
                    strokeDashoffset={offset}
                    strokeLinecap="round"
                    r={radius}
                    cx={size / 2}
                    cy={size / 2}
                    transform={`rotate(-90 ${size / 2} ${size / 2})`}
                />
                <text
                    x="50%"
                    y="50%"
                    textAnchor="middle"
                    dy=".38em"
                    dx="0.05em"
                    fontSize={(size * 0.2) + "px"}
                    fill="white"
                >
                    {progressText}
                </text>
            </svg>
        </div>
    );
}
export function ErrorPage() {
    const error = useRouteError();
    const [errorStatus, setErrorStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if (error instanceof Response) {
            error.text().then((message) => {
                setErrorMessage(message);
                setErrorStatus(error.status);
            }).catch((err) => {
                console.error("Failed to read error message from response:", err);
                setErrorMessage("Unknown error occurred!");
            });
        } else if (isRouteErrorResponse(error)) {
            // override default message
            if (error.status === 404) {
                error.statusText = "This page doesn't exist!"
            }

            setErrorMessage(error.statusText);
            setErrorStatus(error.status);
        } else {
            setErrorMessage("Unknown error occurred!");
        }

        console.error(error);
        console.log(errorStatus)
    }, [error]);

    const styles = "text-red-400";

    return (
        <GlobalInfoOverlay>
            {
                errorMessage ? <div className="flex flex-col justify-center items-center">
                <FontAwesomeIcon icon={faTriangleExclamation} className={`my-auto ${styles}`} size="5x" />
                <div className="mb-6"></div>
                {error.status ? <p className={`text-4xl text-center ${styles} font-bold`}>{errorStatus}</p> : ''}
                <p className={`text-2xl text-center ${styles}`}>{errorMessage}</p>
            </div> : ''
            }
        </GlobalInfoOverlay>
    );
}
