import {useContext, useState} from "react";

import {backend} from "../model/Backend";
import SpinLoadingIndicator from "../common/SpinLoadingIndicator";
import {WallActions, WallContext, WallDispatchContext} from "../contexts/WallContext";
import {UiUtils} from "../common/Utils";
import ModalWindow from "../common/ModalWindow";
import BasicTextInput from "../common/TextInput";
import {BasicButton} from "../common/Button";
import ModifiableList from "../common/ModifiableList";
import ErrorFromBackendResult from "../common/ErrorFromBackendResult";
import BasicInputLabel from "../common/InputLabel";
import {CanvasContext} from "../contexts/CanvasContext";
import * as THREE from "three";
import {UIColor} from "../Config";

class SettingsState {
    static Saved = 1;
    static Dirty = 2;
    static Saving = 3;
}

export default function SettingsModal({onClose, show=false}) {
    const wall = useContext(WallContext);
    const wallDispatch = useContext(WallDispatchContext);

    const [formData, setFormDataRaw] = useState(wall.metadata);
    const [state, setState] = useState(SettingsState.Saved);
    const [errorData, setErrorData] = useState(null);
    const [message, setMessage] = useState(null);

    const [cameraState, setCameraState] = useState(false);

    const canvas = useContext(CanvasContext);

    function setFormData(newFormData) {
        // TODO: compare with wallData to see if there really is a change
        setState(SettingsState.Dirty);

        setErrorData(null);
        setMessage(null);
        setFormDataRaw(newFormData);
    }

    function handleInputChange(event) {
        const {name, value} = event.target;
        setFormData({
            ...formData, [name]: value,
        });
    }

    function onResetCameraPosition() {
        if (canvas === null) {
            // this should never happen
            return;
        }

        setCameraState(false);

        const newFormData = {...formData}

        delete newFormData.customData.starting_orbit_target;
        delete newFormData.customData.starting_position;

        setFormData(newFormData);
    }

    function onNewCameraPosition() {
        if (canvas === null) {
            // this should never happen
            return;
        }

        setCameraState(true);

        // TODO: if more custom data come, edit me
        const newCustomData = {
            starting_position: canvas.camera.position.toArray(),
            starting_orbit_target: canvas.camera.position.clone().add(canvas.camera.getWorldDirection(new THREE.Vector3())).toArray(),
        };

        setFormData({
            ...formData, customData: JSON.stringify(newCustomData),
        });
    }

    async function handleSave() {
        console.assert(state === SettingsState.Dirty);
        setState(SettingsState.Saving)
        setErrorData(null);
        setMessage(null);
        const result = await backend.saveWallMetadata(formData);
        if (!result.isSuccess()) {
            setState(SettingsState.Dirty);
            setErrorData(result);
        } else {
            setState(SettingsState.Saved);
            setCameraState(false);
            setMessage("Changes published.")
            wallDispatch({
                type: WallActions.SetMetadata, metadata: formData,
            });
        }
    }

    async function handleClose() {
        if (state === SettingsState.Dirty && !await UiUtils.confirmAsync("Your changes were not published, do you want to discard them?")) {
            return;
        }
        if (state === SettingsState.Saving && !await UiUtils.confirmAsync("Some changes might not yet been saved, do you want to exit?")) {
            return;
        }
        onClose();
    }

    // TODO: when doing the saving, disable the buttons in this

    return (<ModalWindow handleClose={handleClose} title="Edit Information" show={show}>
        <BasicTextInput
            label="Wall name"
            name="name"
            value={formData.name || ""}
            onChange={handleInputChange}
        />
        <div className="mb-5"></div>

        <BasicTextInput
            label="Location"
            name="location"
            value={formData.location || ""}
            onChange={handleInputChange}
            textArea={true}
        />
        <div className="mb-5"></div>

        <BasicTextInput
            label="Brief Description"
            name="description"
            value={formData.description || ""}
            onChange={handleInputChange}
            textArea={true}
        />

        <div className="mb-5"></div>

        <BasicInputLabel label="Setters"/>
        <ModifiableList
            values={formData.builders}
            setValues={newBuilders => setFormData({
                ...formData, builders: newBuilders,
            })}
        />

        <div className="mb-5"></div>
        <BasicInputLabel label="Difficulties"/>
        <ModifiableList
            values={formData.difficulties}
            setValues={newDifficulties => setFormData({
                ...formData, difficulties: newDifficulties,
            })}
        />
        <div className="mb-5"></div>

        <BasicInputLabel label="Camera"/>
        <div className="flex mb-1">
            <BasicButton buttonColor={cameraState ? UIColor.Green : UIColor.Default}
                         className="w-full"
                         disabled={cameraState}
                         onClick={onNewCameraPosition}
            >
                Set current camera as default
            </BasicButton>
            <div className="m-1"></div>
            <BasicButton className="w-fit"
                         disabled={!cameraState}
                         onClick={onResetCameraPosition}
            >
                Reset
            </BasicButton>
        </div>
        <div className="mb-8"></div>
        <BasicButton onClick={handleSave} disabled={state !== SettingsState.Dirty}
                     buttonColor={UIColor.Blue} className="w-full">
            {state === SettingsState.Saving ? <SpinLoadingIndicator/> : "Publish"}
        </BasicButton>
        <ErrorFromBackendResult data={errorData}/>
        {message !== null && <div>{message}</div>}
    </ModalWindow>);
};