import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faArrowLeft,
    faCircleDown,
    faCircleUp,
    faGrip,
    faThumbsDown,
    faThumbsUp,
} from '@fortawesome/free-solid-svg-icons';
import {
    faCircleDown as farCircleDown,
    faCircleUp as farCircleUp,
    faThumbsDown as farThumbsDown,
    faThumbsUp as farThumbsUp
} from "@fortawesome/free-regular-svg-icons";
import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {UserActions, UserContext, UserDispatchContext} from "../../contexts/UserContext";
import {UserActivity, UserActivityType} from "../../model/User";
import ErrorFromBackendResult from "../../common/ErrorFromBackendResult";
import RouteInfoList from "./RouteInfoList";
import RouteActivityButtons from "./RouteActivityButtons";
import Hammer from "hammerjs";
import {CanvasStateContext} from "../../contexts/CanvasContext";

export default function RouteInfoPanel({route, onClickBack}) {
    const user = useContext(UserContext);
    const userDispatch = useContext(UserDispatchContext);

    const canvasState = useContext(CanvasStateContext);

    const infoPanel = useRef();

    function snapToNearestPlacement() {
        if (infoPanel.current === null)
            return;

        const currentTop = parseInt(window.getComputedStyle(infoPanel.current).top);

        let placementIdx = PanelPlacements.valueIndexFromTop(currentTop);
        animatePlacement(placementIdx);
    }

    function handleActivityAdd(activityType) {
        const timestamp = new Date(Date.now());

        const newActivity = new UserActivity({
            type: activityType,
            route: route,
            timestamp: timestamp,
            isOnWall: true,
        });

        if (user.value === null) {
            userDispatch(
                {
                    type: UserActions.CreateTemporaryAccount,
                    activities: [newActivity],
                },
            );
        } else {
            userDispatch(
                {
                    type: UserActions.AddActivities,
                    activities: [newActivity],
                }
            )
        }
    }

    function handleActivityModify(fromActivityType, toActivityType) {
        if (user.value === null) {
            console.error("User is null when changing activities!")
            return;
        }

        const timestamp = new Date(Date.now());

        const activity = user.value.getActivity(fromActivityType, route);

        const newActivity = new UserActivity({
            id: activity.id,
            type: toActivityType,
            route: route,
            timestamp: timestamp,
            isOnWall: true,
        });

        userDispatch({
            type: UserActions.ModifyActivities,
            activities: [newActivity],
        });
    }

    function handleActivityRemove(input, onSuccess = null) {
        if (user.value === null) {
            console.error("User is null when changing activities!")
            return;
        }

        let activitiesToRemove = [];

        if (Array.isArray(input)) {
            // If input is an array, assume it's a list of activities
            activitiesToRemove = input;
        } else {
            // Otherwise, assume it's an activityType
            const activity = user.value.getActivity(input, route);
            if (activity) {
                activitiesToRemove.push(activity);
            }
        }

        if (activitiesToRemove.length === 0) {
            return; // No activities to remove
        }

        userDispatch({
            type: UserActions.RemoveActivities,
            activities: activitiesToRemove,
            postSuccessHooks: () => {
                onSuccess?.();
            }
        });
    }

    function handleBookmarkClick() {
        if (isSavingActivity) {
            return;
        }

        (bookmarked ? handleActivityRemove : handleActivityAdd)(UserActivityType.Bookmark);
    }

    const resetErrors = useCallback(() => {
        userDispatch({
            type: UserActions.DeleteErrors
        });
    }, [userDispatch]);

    useEffect(() => {
        return () => resetErrors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const bookmarked = user.value == null ? false : user.value.isBookmarked(route);

    // activity action is being tracked by its route ID
    const isSavingActivity = user.value == null ? false : user.status.isLoading(route.id);

    // A specific error for the given route / a more general one
    const error = user?.status.getError(route.id) ?? user?.status.getError();

    function getHeight() {
        return parseInt(window.getComputedStyle(infoPanel.current).height)
    }

    class PanelPlacements {
        // TODO: there should be a way to do this better lmao
        static FullyShown = new PanelPlacements(() => (window.innerHeight - (10 + getHeight())));
        static ButtonsShown = new PanelPlacements(() => window.innerHeight - 110);
        static Hidden = new PanelPlacements(() => window.innerHeight - 55);

        constructor(topValueSupplier) {
            this.topValueSupplier = topValueSupplier;
        }

        topValue() {
            return this.topValueSupplier();
        }

        static getValues() {
            return [this.FullyShown,
                this.ButtonsShown,
                this.Hidden];
        }

        static getIndex(value) {
            return this.getValues().indexOf(value);
        }

        static valueIndexFromTop(topValue) {
            const diffs = this.getValues().map(placement => Math.abs(placement.topValue() - topValue));
            return diffs.indexOf(Math.min(...diffs));
        }
    }

    const currentPlacementIdx = useRef(PanelPlacements.getIndex(PanelPlacements.ButtonsShown));

    function animatePlacement(placementIdx) {
        const top = PanelPlacements.getValues()[placementIdx].topValue();
        infoPanel.current.style.transition = "top 0.3s ease-in-out";
        infoPanel.current.style.top = top + "px";
        currentPlacementIdx.current = placementIdx;
    }

    function setPlacement(placementIdx) {
        const top = PanelPlacements.getValues()[placementIdx].topValue();
        infoPanel.current.style.transition = null;
        infoPanel.current.style.top = top + "px";
        currentPlacementIdx.current = placementIdx;
    }

    function setTop(targetValue) {
        infoPanel.current.style.transition = null;
        infoPanel.current.style.top = targetValue;
    }

    function onPanelClick() {
        // TODO: this collides with hammer
        //  const placementIdx = PanelPlacements.valueIndexFromTop(parseInt(window.getComputedStyle(infoPanel.current).top));
        //  if (placementIdx === 2) {
        //      animatePlacement(0);
        //  }
    }

    useEffect(() => {
        let topStart;
        let dragStartY;

        function onPanStart(event) {
            dragStartY = event.center.y;
            topStart = parseInt(window.getComputedStyle(infoPanel.current).top);
        }

        function onPan(event) {
            let newTop = topStart + event.center.y - dragStartY;

            const height = getHeight();
            let newBottom = window.innerHeight - (newTop + height);
            newBottom = Math.min(newBottom, 10);

            newTop = window.innerHeight - (newBottom + height);
            setTop(newTop + "px");
        }

        function onPanEnd(event) {
            const currentTop = parseInt(window.getComputedStyle(infoPanel.current).top);
            let newPlacementIdx = PanelPlacements.valueIndexFromTop(currentTop);
            if (newPlacementIdx === currentPlacementIdx.current) {
                if (event.deltaY > 40) {
                    newPlacementIdx = Math.min(newPlacementIdx + 1, PanelPlacements.getValues().length - 1);
                } else if (event.deltaY < -40) {
                    newPlacementIdx = Math.max(newPlacementIdx - 1, 0);
                }
            }
            animatePlacement(newPlacementIdx);
        }

        const hammer = new Hammer(infoPanel.current);
        hammer.get("pan").set({direction: Hammer.DIRECTION_VERTICAL});
        hammer.on("panstart", onPanStart);
        hammer.on("pan", onPan);
        hammer.on("panend", onPanEnd);

        window.addEventListener("resize", snapToNearestPlacement);

        setPlacement(currentPlacementIdx.current)

        return () => {
            hammer.off("pan panstart panend");
            hammer.destroy();
            window.removeEventListener("resize", snapToNearestPlacement);
        }
    }, []);

    useEffect(() => {
        // when an error occurs, show it (it's at the bottom)
        if (error !== null)
            animatePlacement(PanelPlacements.getIndex(PanelPlacements.FullyShown));
        else
            snapToNearestPlacement();

    }, [error]);

    useEffect(() => {
        // when the route or user changes, stuff might resize so snap
        snapToNearestPlacement();
    }, [route, user.value]);

    useEffect(() => {
        // when we change to a different route, go back to buttons only
        animatePlacement(PanelPlacements.getIndex(PanelPlacements.ButtonsShown));
    }, [canvasState]);

    const ratingBarsWidth = 13.5;

    /**
     * General function for clicking on like/dislike/soft/hard buttons (since the behavior is symmetrical).
     */
    function enableActivity(from, to, fromType, toType) {
        if (isSavingActivity) {
            return;
        }

        if (from) {
            handleActivityRemove(fromType)
        } else if (to) {
            handleActivityModify(toType, fromType)
        } else {
            handleActivityAdd(fromType)
        }
    }

    function likeDislikeBar() {
        const likeActivity = user.value?.getActivity(UserActivityType.Like, route);
        const dislikeActivity = user.value?.getActivity(UserActivityType.Dislike, route);

        let totalCount = route.likes + route.dislikes;
        let likePercentage = `${route.likes / (totalCount === 0 ? 1 : totalCount) * 100}%`;

        let setLike = () => enableActivity(
            likeActivity, dislikeActivity,
            UserActivityType.Like, UserActivityType.Dislike,
        );

        let setDislike = () => enableActivity(
            dislikeActivity, likeActivity,
            UserActivityType.Dislike, UserActivityType.Like,
        );

        return <div className="flex flex-col items-center">
            <div className="text-2xl flex divide-x-2 divide-white divide-opacity-20">
                <div className="flex items-center px-5 gap-2 cursor-pointer"
                     onClick={setLike}
                >
                    <FontAwesomeIcon icon={likeActivity ? faThumbsUp : farThumbsUp}></FontAwesomeIcon>
                    <span className="text-xl font-bold">{route.likes}</span>
                </div>
                <div className="flex items-center px-5 gap-2 cursor-pointer"
                     onClick={setDislike}
                >
                    <FontAwesomeIcon icon={dislikeActivity ? faThumbsDown : farThumbsDown}></FontAwesomeIcon>
                    <span className="text-xl font-bold">{route.dislikes}</span>
                </div>
            </div>
            <div className="mb-2"></div>
            <div className={`rounded-full h-1.5 bg-gray-500`}
                 style={{width: `${ratingBarsWidth}rem`}}>
                <div className="h-1.5 rounded-full bg-gray-200" style={{width: likePercentage}}></div>
            </div>
        </div>;
    }

    function difficultyBar() {
        const softActivity = user.value?.getActivity(UserActivityType.Soft, route);
        const hardActivity = user.value?.getActivity(UserActivityType.Hard, route);

        let setSoft = () => enableActivity(
            softActivity, hardActivity,
            UserActivityType.Soft, UserActivityType.Hard,
        );

        let setHard = () => enableActivity(
            hardActivity, softActivity,
            UserActivityType.Hard, UserActivityType.Soft,
        );

        // used for hard/soft balance to only slowly get to the left/right
        function sigmoid(z) {
            return 2 * (1 / (1 + Math.exp(-z))) - 1;
        }

        const value = route.softRatings - route.hardRatings;

        const multiplication = 0.25;
        const z = sigmoid(value * multiplication);

        const offset = ratingBarsWidth * (1 - z) / 2;
        const height = Math.abs(z * ratingBarsWidth / 2);

        let k = ratingBarsWidth / 2;
        if (z > 0)
            k -= z * ratingBarsWidth / 2;

        return <div className="flex flex-col items-center">
            <div className="flex items-end">
                <p className="flex items-center cursor-pointer"
                   onClick={setSoft}
                >
                    <FontAwesomeIcon icon={softActivity ? faCircleDown : farCircleDown}
                                     className="text-xl pr-1"></FontAwesomeIcon>
                    easy
                </p>
                <div className="px-1.5"></div>
                <div className="h-5 border-l-2 border-white opacity-20"></div>
                <div className="px-1.5"></div>
                <p className="text-2xl font-bold underline">{route.difficulty}</p>
                <div className="px-1.5"></div>
                <div className="h-5 border-l-2 border-white opacity-20"></div>
                <div className="px-1.5"></div>
                <p className="flex items-center cursor-pointer"
                   onClick={setHard}
                >
                    hard
                    <FontAwesomeIcon icon={hardActivity ? faCircleUp : farCircleUp}
                                     className="text-xl pl-1"></FontAwesomeIcon>
                </p>
            </div>
            <div className="mb-2"></div>
            <div className="bg-gray-500 rounded-full h-1.5"
                 style={{width: `${ratingBarsWidth}rem`}}>
                <div className={`${z < 0 ? 'bg-gradient-to-l' : 'bg-gradient-to-r'} from-white h-1.5 relative`}
                     style={{left: `${k}rem`, width: `${height}rem`}}>
                </div>
                <div className={`relative w-1 h-1 p-[0.25rem] rounded-full bg-neutral-200`}
                     style={{top: `-0.4375rem`, left: `${-0.25 + offset}rem`}}>
                </div>
            </div>
        </div>;
    }

    const windowStyle = "text-white bg-neutral-800 rounded-2xl shadow-large";
    return (
        <div id="info-panel" ref={infoPanel} onClick={onPanelClick}
             className={`z-10 p-4 pt-10 absolute right-4 w-[calc(100%-2rem)] max-h-[calc(100%-2rem)] overflow-scroll sm:w-96 ${windowStyle}`}>
            <div className="absolute top-4 left-4 cursor-pointer flex items-center gap-x-2"
                 onClick={onClickBack}
            >
                <FontAwesomeIcon
                    icon={faArrowLeft}
                    className="text-xl"
                />
                <p className="font-bold">Back</p>
            </div>
            <FontAwesomeIcon icon={faGrip}
                             className="absolute text-2xl top-4 left-1/2 -translate-x-1/2 cursor-pointer"/>
            <RouteActivityButtons route={route} user={user}
                                  handleActivityAdd={handleActivityAdd}
                                  disabled={isSavingActivity}
                                  handleActivityRemove={handleActivityRemove}
                                  className="p-4"
            />
            <div className="flex flex-col items-center">
                <div className="bg-neutral-700 py-4 px-4 rounded-xl w-fit">
                    {likeDislikeBar()}
                </div>
                <div className="mb-4"></div>
                {route.difficulty !== null ? <>
                    <div className="bg-neutral-700 pt-2 pb-4 px-4 rounded-xl w-fit">
                        {difficultyBar()}
                    </div>
                    <div className="mb-4"></div>
                </> : ""}
            </div>
            <RouteInfoList route={route}
                           onBookmarkClick={handleBookmarkClick}
                           bookmarked={bookmarked}
                           isBookmarkDisabled={isSavingActivity}
            />
            <div className="mb-3"></div>
            <ErrorFromBackendResult data={error}/>
        </div>
    );
}