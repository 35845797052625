import {RouteStateType, UserActivityType} from "../../model/User";
import {useContext} from "react";
import {UserContext} from "../../contexts/UserContext";
import {WallContext} from "../../contexts/WallContext";
import SpinLoadingIndicator from "../../common/SpinLoadingIndicator";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBookmark, faQuestion} from "@fortawesome/free-solid-svg-icons";
import {RouteFocusState} from "../../scene/canvas/CanvasState";
import {SetCanvasStateContext} from "../../contexts/CanvasContext";
import {ClickableUIStyle} from "../../Config";


function ProgressGridCell({state, points = null, grade = null, isBookmarked, onClick}) {
    let icon = isBookmarked ? <FontAwesomeIcon icon={faBookmark}/> :
        <FontAwesomeIcon icon={RouteStateType.Icon(state)}/>;

    if (points && grade) {
        console.error("Can't have both points and grade! Removing points.")
        points = null;
    }

    return (
        <div>
            <div
                className={`${ClickableUIStyle[UserActivityType.Color(state)]} p-4 rounded-lg cursor-pointer`}
                onClick={onClick}
            >
                {points !== null || grade !== null ? <div className="absolute -translate-x-1/2 -translate-y-1/2 text-lg">
                    <div className="blur-[2px] opacity-20 text-2xl">
                        {icon}
                    </div>
                </div> : ""}
                <div className="absolute -translate-x-1/2 -translate-y-1/2 text-lg">
                    {points === null && grade === null ? icon : <p className="text-sm">{points ?? grade}</p>}
                </div>
            </div>
        </div>
    );
}

export default function ProgressGrid({onCellClick, usePoints = false, useGrades = false, sortPoints = false}) {
    const user = useContext(UserContext);
    const wall = useContext(WallContext);
    const setCanvasState = useContext(SetCanvasStateContext);

    if (wall === null)
        return <SpinLoadingIndicator/>;

    let progressList = [];

    let sortedRoutes = wall.routes;

    // Sorting no, bad -- we want two people to look at the same things when comparing what they climbed
    // if (user.value !== null)
    //     sortedRoutes = wall.routes.slice().sort((a, b) => user.value.getRoutePoints(a) - user.value.getRoutePoints(b));

    // Collect routes and their points into an array
    let routePointsArray = [];

    for (const route of sortedRoutes) {
        let points = user.value?.getRoutePoints(route);
        routePointsArray.push({route, points});
    }

    // If usePoints is true, sort the array in decreasing order of points, ONLY if sortPoints is specified
    if (usePoints && sortPoints) {
        routePointsArray.sort((a, b) => b.points - a.points);
    }

    for (const {route, points} of routePointsArray) {
        let state = user.value?.getRouteClimbingState(route);

        let pointString = null;
        if (points != null && usePoints) {
            if (points > 1 || points === 0) {
                pointString = points.toFixed(0);
            } else {
                pointString = points.toFixed(1);
            }
        }

        if (points != null && usePoints) {
            if (points !== 0)
                pointString = <strong>{pointString}</strong>
            else
                pointString = <span className="text-white text-opacity-50">{pointString}</span>
        }

        let gradeString = null;
        if (useGrades) {
            if (route.difficulty)
                gradeString = route.difficulty;
            else
                gradeString = <FontAwesomeIcon icon={faQuestion}/>

            if (state !== RouteStateType.Flashed && state !== RouteStateType.Climbed) {
                gradeString = <span className="opacity-50">{gradeString}</span>;
            } else {
                gradeString = <strong>{gradeString}</strong>;
            }
        }

        progressList.push(
            <ProgressGridCell
                state={state}
                points={pointString}
                grade={gradeString}
                isBookmarked={user.value?.isBookmarked(route)}
                onClick={() => {
                    onCellClick();
                    setCanvasState(new RouteFocusState(route));
                }}
            />
        );
    }

    return (
        <div className="flex gap-1.5 flex-wrap">
            {progressList}
        </div>
    );
}