import {ImageUtils} from "../common/Utils"

class Leaderboard {
    constructor(entries) {
        this.entries = entries;
    }

    static fromList(data) {
        // Map each object in the array to a new object with the required properties
        const entries = data.map(entry => {
            let {nickname, photo, rating, rank, current} = entry;

            // server indexes from 0
            rank = rank + 1

            // server adds 1 point
            rating = rating * 100

            return {nickname, photo, rating, rank, current};
        });

        // Return a new instance of Leaderboard
        return new Leaderboard(entries);
    }

    addPhotos(photos) {
        if (photos.length !== this.entries.length) {
            throw new Error("The number of photos must match the number of entries");
        }

        this.entries = this.entries.map((entry, index) => {
            const photo = photos[index] === null ? ImageUtils.defaultImage() : photos[index];
            const photoURL = URL.createObjectURL(photo);

            return {
                ...entry,
                photo: photo,
                photoURL: photoURL
            };
        });
    }

    sortedEntries(limit) {
        limit ??= 10;

        const currentEntries = this.entries.filter(entry => entry.current);
        const nonCurrentEntries = this.entries.filter(entry => !entry.current).sort((a, b) => a.rank - b.rank);

        const limitedNonCurrentEntries = limit ? nonCurrentEntries.slice(0, Math.max(0, limit - currentEntries.length)) : nonCurrentEntries;

        const combinedEntries = [...currentEntries, ...limitedNonCurrentEntries];

        return combinedEntries.sort((a, b) => a.rank - b.rank);
    }

    clone() {
        return new Leaderboard(this.entries);
    }
}

export {Leaderboard};
