import {createContext, useState} from "react";

import {backend, BackendErrorType, BackendResult} from "../model/Backend";
import {unimplementedFunction} from "../common/Utils";
import {ObjectWithTracker} from "./ContextUtils";
import {User} from "../model/User";
import logdepthbuf_fragmentGlsl from "three/src/renderers/shaders/ShaderChunk/logdepthbuf_fragment.glsl";

const UserContext = createContext(null);
const UserDispatchContext = createContext(unimplementedFunction);

class UserActions {
    static RequestLogin = "request_login";
    static Login = "login";
    static TryRestoreLogin = "try_restore_login";
    static Logout = "logout";
    static AddActivities = "add_activities";
    static RemoveActivities = "remove_activities";
    static ModifyActivities = "modify_activities";
    static DeleteErrors = "delete_errors";
    static SubmitFeedback = "submit_feedback";
    static SaveUserMetadata = "save_user_metadata";
    static UpdatePassword = "update_password";
    static DeleteAccount = "delete_account";
    static CreateTemporaryAccount = "create_temporary_account";
    static ForgotPassword = "forgot_password";
    static ResendVerificationEmail = "send_verification_email";
    static VerifyEmail = "verify_email";
    static ResetPassword = "reset_password";
}

function useUserReducer() {
    const [user, setUser] = useState(new ObjectWithTracker(null));

    function userDispatch(...actionData) {
        for (const a of actionData) {
            const action = _createAction(user, a);
            action.execute(setUser);
        }
    }

    return [user, userDispatch];
}

class SimpleAction {
    constructor(newUserCreator, postSuccessHooks = null) {
        this.newUserCreator = newUserCreator;
        this.postSuccessHooks = postSuccessHooks;
    }

    execute(setUser) {
        setUser(this.newUserCreator);
        this.postSuccessHooks?.();
    }
}

class ActionWithSideEffects {
    constructor(backendPromise, trackingId, onSuccess, postSuccessHooks = null) {
        this.backendPromise = backendPromise;
        this.trackingId = trackingId;
        this.onSuccess = onSuccess;
        this.postSuccessHooks = postSuccessHooks;
    }

    execute(setUser) {
        const trackingId = this.trackingId;
        const onSuccess = this.onSuccess;
        const postSuccessHooks = this.postSuccessHooks;

        function modifyUserWithResult(oldUser, actionResult) {
            let newUser = oldUser.clone();
            newUser.status.stopLoading(trackingId);
            if (actionResult.isSuccess()) {
                let result = onSuccess(newUser, actionResult);

                postSuccessHooks?.();

                if (result)
                    newUser = result;
            } else {
                newUser.status.setError(actionResult, trackingId);
            }
            return newUser;
        }

        function onResponse(result) {
            setUser(u => modifyUserWithResult(u, result))
        }

        this.backendPromise
            .then(onResponse)
            .catch(error => {
                console.error(error);
                onResponse(BackendResult.FromError(BackendErrorType.GeneralError));
            });

        setUser(u => {
            const clonedUser = u.clone();
            clonedUser.status.deleteAllErrors();
            clonedUser.status.startLoading(this.trackingId);
            return clonedUser;
        });
    }
}

function getActivityIdentifier(data) {
    let identifier = null;

    if (data.id)
        return data.id;

    if (data.activities.length === 0) {
        return;
    } else if (data.activities.length === 1) {
        identifier = data.activities[0].route.id;
    } else {
        let firstRouteId = data.activities[0].route.id;
        let allSameRoute = data.activities.every(activity => activity.route.id === firstRouteId);

        if (allSameRoute) {
            identifier = firstRouteId;
        } else {
            identifier = data.type;
        }
    }

    return identifier;
}

function temporaryAccountWrapper(temporary, f, x) {
    // for temporary accounts, we make them not talk to the server
    // everything will be communicated later on if the user chooses to register
    if (temporary) {
        return new Promise((resolve, reject) => {
            resolve(BackendResult.EmptySuccess());
        })
    } else {
        return f(x);
    }
}

function _createAction(user, actionData) {
    if (actionData.type === UserActions.RequestLogin) {
        return new ActionWithSideEffects(
            backend.login(actionData.loginData),
            UserActions.RequestLogin,
            (user, result) => {
                user.value = result.data
            },
            actionData.postSuccessHooks
        );
    } else if (actionData.type === UserActions.Login) {
        return new SimpleAction(oldUser => {
                if (oldUser.value !== null) {
                    console.warn("Logged in event though was already logged in.");
                }
                return new ObjectWithTracker(actionData.user);
            },
            actionData.postSuccessHooks
        );
    } else if (actionData.type === UserActions.DeleteAccount) {
        return new ActionWithSideEffects(
            backend.deleteAccount(actionData.data),
            actionData.type,
            (user, result) => {
                return new ObjectWithTracker(null);
            },
        );
    } else if (actionData.type === UserActions.CreateTemporaryAccount) {
        return new SimpleAction(oldUser => {
                let user = new ObjectWithTracker(User.createTemporary());

                actionData.activities?.forEach((activity) => {
                    user.value.addActivity(activity);
                });

                return user;
            },
            actionData.postSuccessHooks
        );
    } else if (actionData.type === UserActions.TryRestoreLogin) {
        return new ActionWithSideEffects(
            backend.checkIfLoggedIn(),
            actionData.type,
            (user, result) => {
                user.value = result.data

            },
            actionData.postSuccessHooks
        );
    } else if (actionData.type === UserActions.Logout) {
        if (user.value) {
            if (user.value.isTemporary) {
                // here we have to manually remove the activities since these are saved locally
                // (i.e. removing them should decrease the counts for likes/dislikes, etc...)
                user.value.removeAllActivities()
            } else {
                backend.logout();
            }
        }

        return new SimpleAction(oldUser => {
                if (oldUser.value === null) {
                    console.warn("Logged out event though wasn't logged in.");
                }

                return new ObjectWithTracker(null);
            },
            actionData.postSuccessHooks
        );
    } else if (actionData.type === UserActions.DeleteErrors) {
        return new SimpleAction(oldUser => {
            const user = oldUser.clone();
            if (user.value === null)
                return user;
            user.status.deleteAllErrors();
            return user;
        });
    } else if (actionData.type === UserActions.AddActivities) {
        return new ActionWithSideEffects(
            temporaryAccountWrapper(
                user.value.isTemporary,
                backend.addUserActivities.bind(backend),
                actionData.activities,
            ),
            getActivityIdentifier(actionData),
            (user, result) => {
                actionData.activities.forEach((activity, index) => {
                    // for temporary account, no id is returned, in which case we generate random ones
                    if (Array.isArray(result.data)) {
                        activity.id = result.data[index].id;
                    }

                    user.value.addActivity(activity);
                });

            },
            actionData.postSuccessHooks
        );
    } else if (actionData.type === UserActions.RemoveActivities) {
        return new ActionWithSideEffects(
            temporaryAccountWrapper(
                user.value.isTemporary,
                backend.removeUserActivities.bind(backend),
                actionData.activities,
            ),
            getActivityIdentifier(actionData),
            (user, result) => {
                actionData.activities.forEach((activity) => {
                    user.value.removeActivity(activity);
                });
            },
            actionData.postSuccessHooks
        );
    } else if (actionData.type === UserActions.ModifyActivities) {
        return new ActionWithSideEffects(
            temporaryAccountWrapper(
                user.value.isTemporary,
                backend.modifyUserActivities.bind(backend),
                actionData.activities,
            ),
            getActivityIdentifier(actionData),
            (user, result) => {
                actionData.activities.forEach((activity) => {
                    user.value.modifyActivity(activity);
                });
            },
            actionData.postSuccessHooks
        );
    } else if (actionData.type === UserActions.SaveUserMetadata) {
        return new ActionWithSideEffects(
            backend.saveUserMetadata(actionData.metadata),
            actionData.type,
            (user, result) => {
                user.value.nickname = actionData.metadata.nickname;
                user.value.updatePhoto(actionData.metadata.photo)
            },
            actionData.postSuccessHooks
        );
    } else if (actionData.type === UserActions.UpdatePassword) {
        return new ActionWithSideEffects(
            backend.updatePassword(actionData.data),
            actionData.type,
            () => {
            },
            actionData.postSuccessHooks
        );
    } else if (actionData.type === UserActions.SubmitFeedback) {
        return new ActionWithSideEffects(
            backend.submitFeedback(actionData.text),
            actionData.type,
            () => {
            },
            actionData.postSuccessHooks
        );
    } else if (actionData.type === UserActions.ForgotPassword) {
        return new ActionWithSideEffects(
            backend.forgotPassword(actionData.email),
            actionData.type,
            () => {
            },
            actionData.postSuccessHooks
        );
    } else if (actionData.type === UserActions.ResendVerificationEmail) {
        return new ActionWithSideEffects(
            backend.resendVerificationEmail(),
            actionData.type,
            () => {
            },
            actionData.postSuccessHooks
        );
    } else if (actionData.type === UserActions.VerifyEmail) {
        return new ActionWithSideEffects(
            backend.verifyEmail(actionData.token, actionData.userId),
            actionData.type,
            () => {
            },
            actionData.postSuccessHooks
        );
    } else if (actionData.type === UserActions.ResetPassword) {
        return new ActionWithSideEffects(
            backend.resetPassword(actionData.token, actionData.userId, actionData.password),
            actionData.type,
            (user, result) => {
            },
            actionData.postSuccessHooks
        );
    } else {
        throw Error("Unknown action: " + actionData.type);
    }

}

export {UserContext, UserDispatchContext, UserActions, useUserReducer};
