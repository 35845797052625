export default class RebuildProposal {
    constructor(metadata, newWall, unchangedRoutes, newRoutes) {
        this.metadata = metadata;
        this.newWall = newWall;
        this.unchangedRoutes = unchangedRoutes;
        this.newRoutes = newRoutes;

        this.beingReviewed = false;
        this.saving = false;
        this.savingError = null;
    }

    shallowClone() {
        const clone = new RebuildProposal(
            this.metadata, this.newWall, this.unchangedRoutes, this.newRoutes
        );
        clone.beingReviewed = this.beingReviewed;
        clone.saving = this.saving;
        clone.savingError = this.savingError;
        return clone;
    }

    allRoutes() {
        return this.unchangedRoutes.concat(this.newRoutes);
    }

    static fromWall(metadata, newWall) {
        return new RebuildProposal(
            metadata,
            newWall,
            newWall.routes.filter(route => route.metadata["name"]),
            newWall.routes.filter(route => !route.metadata["name"]),
        )
    }

    static NotReady(metadata) {
        return new RebuildProposal(metadata, null, null, null);
    }
}