import SettingsModal from "../../settings/SettingsModal";
import {useCallback, useContext, useEffect, useState} from "react";
import {backend, BackendResult} from "../../model/Backend";
import {SpecialStates} from "../../common/Utils";
import NewRebuildModal from "../../settings/NewRebuildModal";
import {BasicButton} from "../../common/Button";
import MessageModal from "../../settings/MessageModal";
import {ProposalActions, ProposalContext, ProposalDispatchContext} from "../../contexts/ProposalContext";
import ErrorFromBackendResult from "../../common/ErrorFromBackendResult";
import SpinLoadingIndicator from "../../common/SpinLoadingIndicator";

export default function OwnerInfo() {
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [newRebuildOpen, setNewRebuildOpen] = useState(false);
    const [rebuildSuccessOpen, setRebuildSuccessOpen] = useState(false);
    const proposal = useContext(ProposalContext);
    const proposalDispatch = useContext(ProposalDispatchContext);

    const loadProposal = useCallback(async () => {
        proposalDispatch({type: ProposalActions.StartLoading});
        let result;
        try {
            result = await backend.getPendingProposal();
        } catch (e) {
            console.error("Error loading proposal: " + e);
            proposalDispatch({
                type: ProposalActions.ServerError,
                error: BackendResult.FromError("Couldn't load rebuild proposal, please try again later."),
            });
            return;
        }
        if (!result.isSuccess()) {
            proposalDispatch({
                type: ProposalActions.ServerError,
                error: result,
            });
        } else {
            proposalDispatch({
                type: ProposalActions.SetFromServer,
                proposal: result.data,
            });
        }
    }, [proposalDispatch]);

    useEffect(() => {
        if (proposal !== SpecialStates.ToBeLoaded)
            return;
        loadProposal();
    }, [loadProposal, proposal]);

    function onProposalReviewRequest() {
        proposalDispatch({type: ProposalActions.StartReviewing});
    }

    function onNewRebuildClose(wasSuccess) {
        setNewRebuildOpen(false);
        if (wasSuccess) {
            setRebuildSuccessOpen(true);
            proposalDispatch({type: ProposalActions.EnteredNewRebuild});
        }
    }

    return (
        <>
            <h2 className="text-4xl text-center">Management</h2>
            <BasicButton
                onClick={() => setSettingsOpen(true)}
                className="mt-5"
            >Edit information
            </BasicButton>
            <SettingsModal
                onClose={() => setSettingsOpen(false)}
                show={settingsOpen}
            />

            <h3 className="text-3xl mt-10 mb-2">Wall Rebuilds</h3>
            {/*TODO: when there is error, add a Retry button*/}
            {/*<LoadingFromServer value={proposal} error={proposalServerError} childrenBuilder={proposal => {*/}
            {
                (() => {
                    if (proposal instanceof SpecialStates.ErrorState) {
                        return (<>
                            <div className="mb-3"></div>
                            <ErrorFromBackendResult data={proposal.error}/>
                            <div className="flex justify-center">
                                <BasicButton onClick={loadProposal}>Retry</BasicButton>
                            </div>
                        </>);
                    }
                    if (proposal === SpecialStates.ToBeLoaded)
                        return;
                    console.assert(proposal !== null);
                    if (proposal === SpecialStates.Loading) {
                        return (<>
                            <div className="mb-3"></div>
                            <SpinLoadingIndicator/>
                        </>);
                    }
                    if (proposal === SpecialStates.Empty) {
                        return (<>
                            <p className="mt-3">No rebuilds are being processed.</p>
                            <BasicButton
                                onClick={() => setNewRebuildOpen(true)}
                                className="mt-5"
                            >Upload Rebuild Photos
                            </BasicButton>
                        </>);
                    }
                    if (!proposal.metadata["readyForRevision"]) {
                        return (<>
                            <div className="mb-5"></div>
                            <p>Rebuild is currently being processed. It will appear here as soon as it's ready.</p>
                            <div className="mb-3"></div>
                            <div className="flex justify-center items-center">
                                <BasicButton onClick={loadProposal}>Refresh</BasicButton>
                            </div>
                        </>);
                    }
                    if (proposal.beingReviewed) {
                        return (<>
                            <p>You are reviewing the rebuild.</p>
                        </>);
                    }
                    return (<>
                        <div className="mb-5"></div>
                        <p>A wall update is ready to be reviewed!</p>
                        <div className="mb-3"></div>
                        <BasicButton onClick={onProposalReviewRequest}>
                            Review Update
                        </BasicButton>
                    </>);
                })()
            }

            {newRebuildOpen &&
                <NewRebuildModal onClose={onNewRebuildClose}/>
            }
            {rebuildSuccessOpen &&
                <MessageModal
                    title="Rebuild uploaded!"
                    message="You can review the rebuild in your Management Center as soon as it's ready."
                    onClose={() => setRebuildSuccessOpen(false)}
                />
            }
        </>
    );
}